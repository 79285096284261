import React, { useEffect, useState } from 'react';

import { Box, Button, LinearProgress, Paper, TextField, Tooltip, Typography } from '@mui/material';

import LogoClaveUnica from 'assets/images/clave_unica.svg';
import { useEditInformationWithClaveUnicaMutation, useUpdateCMFWithClaveUnicaMutation } from 'services/api/user.api';
import { captureUserEvent } from 'utils/posthogUtils';

import styles from './ClaveUnicaModal.module.scss';

interface Props {
  rut: string;
  onSave: () => void;
  isCMFUpdate: boolean;
  showBorder?: boolean;
}

const initialMessages = ['Puede tomar unos segundos', 'Estamos validando tu rut', 'Estamos validando tu clave única'];

const rotatingMessages = [
  'Estamos buscando información de tus deudas de los bancos a través de la CMF (Comisión para el Mercado Financiero)',
  'Deudas de casas comerciales y cajas de compensación no aparecen aquí porque no reportan a la CMF',
  'Deudas no bancarias deberás agregarlas manualmente',
  'Solo podemos ir a buscar la información general de tus deudas, después puedes agregar los detalles de cada una',
];

const ClaveUnicaForm = ({ rut, onSave, isCMFUpdate, showBorder }: Props) => {
  const [messageIndex, setMessageIndex] = useState<number>(0);
  const [claveUnica, setClaveUnica] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [editInformationWithClaveUnica, { data, isLoading, error }] = useEditInformationWithClaveUnicaMutation();
  const [updateCMFWithClaveUnica, { data: dataCMF, isLoading: isLoadingCMF, error: errorCMF }] =
    useUpdateCMFWithClaveUnicaMutation();

  const handleSave = async () => {
    setMessageIndex(0);
    if (isCMFUpdate) {
      await updateCMFWithClaveUnica({ claveUnica });
    } else {
      await editInformationWithClaveUnica({ claveUnica });
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setMessageIndex(messageIndex + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, [messageIndex, setMessageIndex]);

  useEffect(() => {
    if (data || dataCMF) {
      setShowSuccessMessage(true);
      setClaveUnica('');
      onSave();
    }
  }, [data, dataCMF]);

  useEffect(() => {
    if (error) {
      const parsedError = error as { data: string };
      setErrorMessage(parsedError.data || 'Error al obtener datos de Clave Única. Intenta nuevamente');
    } else if (errorCMF) {
      const parsedError = errorCMF as { data: string };
      setErrorMessage(parsedError.data || 'Error al obtener datos de Clave Única. Intenta nuevamente');
    }
  }, [error, errorCMF]);

  const [whyTooltipOpen, setWhyTooltipOpen] = useState<boolean>(false);

  return (
    <Paper className={styles.ClaveUnicaContainer}>
      <div className={showBorder ? styles.ClaveUnicaBorder : ''}>
        <div className={styles.ClaveUnicaHeader}>
          <div className={styles.HeaderLine}>
            <span className={styles.HeaderBlue} />
            <span className={styles.HeaderRed} />
          </div>
          <img src={LogoClaveUnica} alt="ClaveÚnica" />
          <div className={styles.HeaderTitleContainer}>
            <h2 className={styles.HeaderTitle}>Informe de deudas CMF</h2>
            <p style={{ textAlign: 'justify', color: '#000' }}>
              Si no recuerdas tu clave única, prueba{' '}
              <a href="https://iam-backend.claveunica.gob.cl/auth/accounts/login" target="_blank" rel="noreferrer">
                aquí
              </a>{' '}
              para no bloquearla.
            </p>
          </div>
        </div>
        <div className={styles.InputContainer}>
          <div className={styles.ClaveUnicaInput}>
            <TextField value={rut} disabled fullWidth label="Rut" />
          </div>
          <div className={styles.ClaveUnicaInput}>
            <TextField
              type="password"
              value={claveUnica}
              autoFocus
              onChange={(e) => setClaveUnica(e.target.value)}
              fullWidth
              label="Ingresa tu Clave Única"
              helperText={errorMessage}
              error={!!errorMessage}
            />
          </div>
          <a
            className={styles.ClaveUnicaRecoveryLink}
            href="https://claveunica.gob.cl/recuperar"
            target="_blank"
            rel="noreferrer"
          >
            ¿Olvidaste tu ClaveÚnica?
          </a>
          <div className={styles.SaveButtonContainer}>
            <div>
              <button
                type="submit"
                className={styles.SaveButton}
                disabled={!claveUnica || isLoading || isLoadingCMF}
                onClick={() => {
                  captureUserEvent('FinancialScannerFlow: Clave Única');
                  handleSave().catch((err) => {
                    // eslint-disable-next-line no-console
                    console.error(err);
                  });
                }}
              >
                Continuar
              </button>
            </div>
          </div>
          <div style={{ display: isLoading || isLoadingCMF ? 'block' : 'none' }}>
            <LinearProgress color="info" />
            <p>
              {messageIndex >= initialMessages.length
                ? rotatingMessages[(messageIndex - initialMessages.length) % rotatingMessages.length]
                : initialMessages[messageIndex]}
            </p>
          </div>
          <div style={{ display: showSuccessMessage ? 'block' : 'none' }}>
            <p>¡Gracias por agregar tu información! Ahora podrás ver tus deudas bancarias</p>
          </div>
        </div>
        <Box display="flex" flexDirection="row" justifyContent="space-between" p={1}>
          <Tooltip
            onClose={() => setWhyTooltipOpen(false)}
            onOpen={() => setWhyTooltipOpen(true)}
            leaveTouchDelay={2500}
            open={whyTooltipOpen}
            title={
              <div>
                <p>
                  Con la clave única obtendremos la información de tus deudas para poder mostrártelas de una forma
                  simple y en un solo lugar
                </p>
                <p>Así podremos darte la mejor asesoría financiera y ayudarte a mejorar tus deudas.</p>
                <p>Si prefieres, puedes cambiar tu clave única después de usarla</p>
                <p>
                  ¿Todavía tienes dudas? Puedes agendar una reunión con alguien de Relif{' '}
                  <a href="/book-meeting" target="_blank">
                    aquí
                  </a>
                </p>
              </div>
            }
          >
            <Button onClick={() => setWhyTooltipOpen(true)}>
              <Typography sx={{ textDecoration: 'underline', textTransform: 'none' }}>
                ¿Por qué me piden clave única?
              </Typography>
            </Button>
          </Tooltip>
        </Box>
      </div>
    </Paper>
  );
};

ClaveUnicaForm.defaultProps = {
  showBorder: false,
};

export default ClaveUnicaForm;
