/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as RouterLink, useParams } from 'react-router-dom';

import Email from '@mui/icons-material/Email';
import Phone from '@mui/icons-material/Phone';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AlertDialog from 'components/UI/AlertDialog';
import PrimaryBtn from 'components/UI/Buttons';
import ChatRedirectButton from 'components/UI/ChatRedirectButton';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useQuery from 'hooks/useQuery';
import Error from 'pages/Error/ErrorAlert';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { format } from 'rut.js';
import { Origin } from 'services/types/origin';
import trpc from 'trpc';
import { identifyPosthogUser } from 'utils/posthogUtils';
import validateEmail from 'utils/validations/validateEmail';
import validatePhone from 'utils/validations/validatePhone';
import validateRut from 'utils/validations/validateRut';

import styles from '../UniqueDebtFlow.module.scss';
import ModalWithHeader from './ModalWithHeader';

interface Props {
  onSuccess: () => void;
  origin: Origin;
  rut: string;
  setRut: (value: string) => void;
  phone: string;
  setPhone: (value: string) => void;
  email: string;
  setEmail: (value: string) => void;
  externalProviderId?: string;
  redirectUrl?: string;
}

const SetRutPhoneAndEmail: FC<Props> = ({
  onSuccess,
  origin,
  rut,
  setRut,
  phone,
  setPhone,
  email,
  setEmail,
  externalProviderId,
  redirectUrl,
}) => {
  const findOrCreateUser = trpc.uniqueFlowUsers.findOrCreateUser.useMutation();
  const generatePoderSimple = trpc.uniqueFlowUsers.generatePoderSimple.useMutation();
  const [acceptedTerms, setAcceptedTerms] = useState(true);
  const { companyName } = useParams<{ companyName: string }>();
  const query = useQuery();
  const referrer = Number(query.get('referrer')) || null;
  const dispatch = useDispatch();
  const [msgError, setMsgError] = useState('');
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [rutError, setRutError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);

  const validate = () => {
    const isValidRut = rut && validateRut(rut);
    const isValidPhone = validatePhone(phone);
    const isValidEmail = validateEmail(email);
    return isValidRut && isValidPhone && isValidEmail;
  };

  const handleRutChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Allow digits and 'k' or 'K' at the end
    const value = e.target.value.toLowerCase();
    const digitsOnly = value.replace(/[^0-9k]/g, '');

    // Ensure only the last character can be 'k'
    const sanitizedValue = digitsOnly.replace(/k/g, (match, offset) => {
      return offset === digitsOnly.length - 1 ? match : '';
    });

    // Limit to 9 characters (8 digits + possible 'k')
    const limitedValue = sanitizedValue.slice(0, 9);
    const formattedRut = format(limitedValue);

    setRut(formattedRut);
    setRutError(formattedRut.length > 0 && !validateRut(formattedRut));
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value.trim();
    setEmail(newEmail);
    setEmailError(newEmail.length > 0 && !validateEmail(newEmail));
  };

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 8) {
      const newPhone = `+569${value}`;
      setPhone(newPhone);
      setPhoneError(newPhone.length > 4 && !validatePhone(newPhone));
    }
  };

  useEffect(() => {
    if (findOrCreateUser.isSuccess) {
      // After creating the user, generate poder simple
      generatePoderSimple.mutate({
        rut,
        phone,
        email,
        externalProviderId,
      });
    }
  }, [findOrCreateUser.isSuccess]);

  useEffect(() => {
    if (generatePoderSimple.isSuccess) {
      if (generatePoderSimple.data.signInResponse?.accessToken !== undefined) {
        identifyPosthogUser(generatePoderSimple.data.signInResponse);
        dispatch(setAccessToken({ accessToken: generatePoderSimple.data.signInResponse.accessToken }));
        onSuccess();
      } else {
        setMsgError(generatePoderSimple.data.message);
        setOpenErrorDialog(true);
      }
    }
    if (generatePoderSimple.isError) {
      setOpenErrorDialog(true);
    }
  }, [generatePoderSimple.isSuccess, generatePoderSimple.isError]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validate() && acceptedTerms && !findOrCreateUser.isLoading) {
      findOrCreateUser.mutate({
        rut,
        phone,
        email,
        origin: origin || 'UniqueFlow',
        companyName,
        referrer,
        externalProviderId,
      });
    }
  };

  const getText = () => {
    if (companyName?.toLowerCase() === 'buk') {
      return 'Necesitaremos que completes los siguientes datos para revisar si tienes una oferta.';
    }
    return 'Completa los siguientes datos para poder mostrar toda tu información financiera en un solo lugar. ¡Así de fácil y rápido podrás entender mejor tu situación!';
  };

  return (
    <ModalWithHeader origin={origin} title="¡Estás a un paso!">
      <LoadingOverlay visible={findOrCreateUser.isLoading || generatePoderSimple.isLoading} />
      <AlertDialog
        openDialog={openErrorDialog}
        setOpenDialog={setOpenErrorDialog}
        header="Hubo un error al generar el poder simple"
        msg={msgError || 'Perdón por las molestias'}
        confirmMsg="Cerrar"
        confirmAction={() => setOpenErrorDialog(false)}
        cancelMsg=""
      />
      <Error
        message="Hubo un error, si el problema persiste contáctanos a través del chat"
        isOpen={findOrCreateUser.isError}
      />
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        width="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        mt={2}
      >
        <Box className={styles.ContentBox}>
          <Typography variant="body1" width="100%" textAlign="left" pt={1} pb={2} fontSize="20px">
            {getText()}
          </Typography>
          <Stack spacing={1}>
            <TextField
              label="Rut"
              value={rut}
              onChange={handleRutChange}
              fullWidth
              variant="outlined"
              error={rutError}
              helperText={rutError ? 'RUT inválido' : ''}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
            />
            <TextField
              type="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              placeholder="juan@email.cl"
              fullWidth
              variant="outlined"
              error={emailError}
              helperText={emailError ? 'Email inválido' : ''}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Email />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              type="tel"
              label="Celular"
              value={phone.slice(4)} // Remove +569 prefix for display
              onChange={handlePhoneChange}
              fullWidth
              variant="outlined"
              error={phoneError}
              helperText={phoneError ? 'Número inválido' : ''}
              sx={{ '& .MuiOutlinedInput-root': { borderRadius: '25px' } }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+569</InputAdornment>,
                endAdornment: (
                  <InputAdornment position="end">
                    <Phone />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        </Box>
        <FormControlLabel
          control={<Checkbox checked={acceptedTerms} onChange={() => setAcceptedTerms(!acceptedTerms)} />}
          sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}
          label={
            <Typography variant="body1" fontSize="18px">
              Acepto{' '}
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://storage.googleapis.com/relif-public-bucket/Poder%20Especial.pdf"
              >
                Poder
              </Link>{' '}
              y{' '}
              <Link
                rel="noreferrer"
                target="_blank"
                href="https://storage.googleapis.com/relif-public-bucket/terms_and_conditions.pdf"
              >
                Términos & Condiciones
              </Link>
            </Typography>
          }
        />
        <Box display="grid" justifyContent="center" alignItems="center" py={5}>
          {companyName?.toLowerCase() !== 'buk' && (
            <PrimaryBtn type="submit" disabled={!validate() || !acceptedTerms}>
              Comenzar
            </PrimaryBtn>
          )}
          {companyName?.toLowerCase() === 'buk' && (
            <PrimaryBtn type="submit" disabled={!validate() || !acceptedTerms}>
              Revisar
            </PrimaryBtn>
          )}
          {!redirectUrl && companyName?.toLowerCase() !== 'buk' && (
            <Typography py={2} textAlign="center">
              ¿Ya tienes cuenta?{' '}
              <RouterLink to="/sign-in" style={{ textDecoration: 'underline' }}>
                Inicia Sesión
              </RouterLink>
            </Typography>
          )}
          {redirectUrl && companyName?.toLowerCase() !== 'buk' && (
            <Box mt={2}>
              <ChatRedirectButton redirectUrl={redirectUrl} />
            </Box>
          )}
        </Box>
      </Box>
    </ModalWithHeader>
  );
};

SetRutPhoneAndEmail.defaultProps = {
  externalProviderId: undefined,
  redirectUrl: undefined,
};

export default SetRutPhoneAndEmail;
