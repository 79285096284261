import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { PrimaryBtn } from 'components/UI/Buttons';
import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';
import { useListDebtsQuery } from 'services/api/debt.api';

interface ScannerBannerProps {
  alignment: 'center' | 'flex-end';
}

const ScannerBanner = ({ alignment = 'flex-end' }: ScannerBannerProps) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const debtFlow = useDebtFlow();
  const { data: debts } = useListDebtsQuery(null);

  const onDueDebts = debts?.filter((debt) => Number(debt.monthsBehind) === 0) || [];

  if (onDueDebts.length > 0 || debtFlow.hasCMFResponseInLast30Days) {
    return null;
  }

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        backgroundColor: 'backgroundGrey.main',
        zIndex: 1,
        py: isMobile ? 1 : 2,
        px: isMobile ? 2 : 3,
        borderBottom: '1px solid',
        borderColor: 'divider',
        display: 'flex',
        justifyContent: alignment,
        mt: isMobile ? 0 : 1,
      }}
    >
      <PrimaryBtn
        onClick={() => navigate(`/onboarding/relif?redirectUrl=/chat?refetchFlag=1&isFromScanner=true`)}
        sx={{
          borderRadius: '20px',
          px: isMobile ? 2 : 3,
          py: isMobile ? 0.5 : 1,
          fontSize: isMobile ? '0.875rem' : '1rem',
        }}
      >
        Escáner Financiero
      </PrimaryBtn>
    </Box>
  );
};

export default ScannerBanner;
