import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import SideDrawer from 'components/UI/SideDrawer';
import { Debt as DebtInterface } from 'services/types/debt';

import ManualDebtFormSteps from './ManualDebtFormSteps';

const steps = ['Tipo de institución', 'Nombre de institución', 'Tipo de producto', 'Detalles de deuda'];

interface Props {
  debtToEdit: DebtInterface | null;
  setDebtToEdit: (debt: DebtInterface | null) => void;
  isFormOpen: boolean;
  setFormOpen: (state: boolean) => void;
  adminMode?: boolean;
}

const DrawerDebtForm: FC<Props> = ({ debtToEdit, isFormOpen, setFormOpen, setDebtToEdit, adminMode }) => {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleClose = () => {
    setDebtToEdit(null);
    setFormOpen(false);
    setActiveStep(0);
  };

  return (
    <SideDrawer
      title={debtToEdit ? 'Editar Deuda' : 'Agregar Deuda'}
      open={isFormOpen}
      onClose={handleClose}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    >
      <Box sx={{ overflowY: 'auto', p: 1 }}>
        <Stepper activeStep={activeStep} orientation="horizontal">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <ManualDebtFormSteps
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          debtToEdit={debtToEdit}
          setFormOpen={setFormOpen}
          setDebtToEdit={setDebtToEdit}
          adminMode={adminMode || false}
          onSave={handleClose}
        />
      </Box>
    </SideDrawer>
  );
};

DrawerDebtForm.defaultProps = {
  adminMode: false,
};

export default DrawerDebtForm;
