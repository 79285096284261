import React, { FC } from 'react';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import useCurrentRoute from 'hooks/useCurrentRoute';

interface BlogBreadCrumbsInterface {
  title: string;
  isHome: boolean;
}

const BlogBreadCrumbs: FC<BlogBreadCrumbsInterface> = ({ title, isHome }) => {
  const { path } = useCurrentRoute();
  const url = path.includes('/blog') ? '/blog' : '/education';
  return (
    <Breadcrumbs>
      <Link href={url} color="inherit" underline="hover">
        Todos los artículos
      </Link>
      {!isHome && <Typography color="text.primary">{title}</Typography>}
    </Breadcrumbs>
  );
};

export default BlogBreadCrumbs;
