import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';
import CLALink from 'components/UI/CLALink';

import { MortgagesContent } from './Mortgages';
import BodyTypography from './components/BodyTypography';
import { DS19Definition, DS19WithoutSubsidy } from './components/DS19';
import Dropdown from './components/Dropdown';
import NewHomeBreadCrumbs from './components/NewHomeBreadCrumbs';
import NewHomePaper from './components/NewHomePaper';

const Fogaes: FC = () => (
  <Box mt={4}>
    <Dropdown title="Fondo de Garantía Estatal (Fogaes)" titleSize="24px">
      <BodyTypography>
        El Fondo de Garantía Estatal (Fogaes) tiene como uno de sus principales objetivos apoyar a las familias en la
        obtención de un <CLALink href="/new-home/mortgages">crédito hipotecario</CLALink> para la compra de la primera
        vivienda, reduciendo el pie necesario. Es un fondo de garantía estatal que permite al Estado actuar como aval
        para acceder a un crédito hipotecario con <b>hasta un 90%</b> de financiamiento. El programa establece que las
        instituciones financieras puedan provisionar entre el <b>80% y 90% del valor de la casa.</b>
        <Box my={2}>
          Para postular al Programa de Apoyo a la Vivienda de Fogaes el beneficio se puede gestionar hasta el{' '}
          <b>3 de abril del 2024</b> a través de las instituciones financieras que son parte de la medida.
        </Box>
        <Typography my={2} textAlign="start" fontSize="24px" fontWeight={500}>
          Requisitos:
        </Typography>
        Los requisitos para acceder al programa de garantía Fogaes que aporta hasta un 10% de la vivienda son:
        <ul>
          <li>Ser persona natural.</li>
          <li>
            Que el crédito sea para financiar una primera vivienda con la intención de habitarla (no ser propietario de
            una propiedad habitacional).
          </li>
          <li>Que el valor de la vivienda no supere las UF 4.500.</li>
          <li>No haber recibido ningún tipo de subsidio o beneficio estatal para el financiamiento de la vivienda.</li>
          <li>No estar con inscripción vigente en el Registro Nacional de Deudores de Pensión de Alimentos.</li>
          <li>Que no haya sido beneficiado anteriormente con la Garantía FOGAES Apoyo a la Vivienda.</li>
          <li>Cumplir con la evaluación crediticia propia de cada institución.</li>
        </ul>
        Si te interesa este programa tienes que postular directamente en la institución financiera que elijas. Las
        instituciones disponibles para gestionar el programa de Fogaes para adquirir una vivienda son:
        <Box>(Clickea el nombre de la institución para ser redirigido a su página web)</Box>
        <ul>
          <li>
            <CLALink
              target="_blank"
              rel="noreferrer"
              href="https://www.bancoestado.cl/content/bancoestado-public/cl/es/home/campanas-bancoestado---bancoestado-personas/2023/personas-bancoestado/programa-de-garantias-apoyo-a-la-vivienda---bancoestado-personas.html#/"
            >
              Banco Estado
            </CLALink>
          </li>
          <li>
            <CLALink
              target="_blank"
              rel="noreferrer"
              href="https://banco.santander.cl/personas/credito-hipotecario/detalles/credito-fogaes"
            >
              Banco Santander
            </CLALink>
          </li>
          <li>
            <CLALink
              target="_blank"
              rel="noreferrer"
              href="https://www.scotiabankchile.cl/Creditos/credito-hipotecario"
            >
              Scotiabank
            </CLALink>
          </li>
          <li>
            <CLALink target="_blank" rel="noreferrer" href="https://www.internacional.cl/productos/credito-hipotecario">
              Banco Internacional
            </CLALink>
          </li>
          <li>
            <CLALink target="_blank" rel="noreferrer" href="https://www.bci.cl/personas/credito-hipotecario/fogaes">
              BCI
            </CLALink>
          </li>
          <li>
            <CLALink
              target="_blank"
              rel="noreferrer"
              href="https://d1dzq2r60kxox4.cloudfront.net/uploads/ee9c3684-f450-489e-809b-ff7dd600633c/original/PDF-FOGAES.pdf"
            >
              Itaú
            </CLALink>
          </li>
          <li>
            <CLALink target="_blank" rel="noreferrer" href="https://banco.bice.cl/personas/campana/fogaes-personas">
              Banco BICE
            </CLALink>
          </li>
          <li>
            <CLALink target="_blank" rel="noreferrer" href="https://www.bancofalabella.cl/creditos/hipotecario">
              Banco Falabella
            </CLALink>
          </li>
          <li>
            <CLALink target="_blank" rel="noreferrer" href="https://www.coopeuch.cl/personas/fogaes-hipotecario">
              Coopeuch
            </CLALink>
          </li>
        </ul>
        Si te interesa este programa puedes revisar más detalles en la{' '}
        <CLALink
          href="https://www.gob.cl/noticias/como-postular-fogaes-apoyo-compra-primera-vivienda-credito-hipotecario/"
          target="_blank"
          rel="noreferrer"
        >
          página oficial (presiona acá para ir).
        </CLALink>
      </BodyTypography>
    </Dropdown>
  </Box>
);

const NoSubsidy: FC = () => {
  return (
    <NewHomePaper>
      <NewHomeBreadCrumbs title="Comprar tu casa sin Subsidio" isHome={false} />
      <Typography my={2} fontSize="36px" lineHeight="44px" fontWeight="500">
        Comprar tu casa sin Subsidio
      </Typography>
      <BodyTypography>
        ¿Por qué comprar una casa sin subsidio?
        <ul>
          <li>No cumples con los requisitos para postular a un subsidio.</li>
          <li>Quieres comprar una casa de mayor valor.</li>
          <li>Ya tengo una propiedad.</li>
        </ul>
        Si te interesa comprar tu casa con un crédito hipotecario, revisa la siguiente sección:
      </BodyTypography>
      <Dropdown title="Crédito Hipotecario" titleSize="24px">
        <MortgagesContent />
      </Dropdown>
      <Divider />
      <Fogaes />
      <Divider />
      <BodyTypography>
        Aunque no tengas un subsidio, puedes comprar una vivienda del programa de integración social y territorial:
      </BodyTypography>
      <Dropdown title="Programa de Integración Social y Territorial (DS19)" titleSize="24px">
        <DS19Definition />
        <DS19WithoutSubsidy />
      </Dropdown>
      <Typography mt={4} fontSize="36px" lineHeight="44px" fontWeight={500}>
        Ayuda
      </Typography>
      <BodyTypography>
        Si te quedó alguna duda, agenda una asesoría con nosotros y te ayudaremos a resolverla:
      </BodyTypography>
      <Box mt={2}>
        <CLALink href="/book-meeting" target="_blank" rel="noreferrer">
          <PrimaryBtn>Agendar asesoría</PrimaryBtn>
        </CLALink>
      </Box>
    </NewHomePaper>
  );
};

export default NoSubsidy;
