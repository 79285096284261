import React, { FC, useEffect, useState } from 'react';
import { NumericFormat, NumberFormatValues } from 'react-number-format';

import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import priceFormat from 'services/format/priceFormat';
import { Goal, GoalTemplate } from 'services/types/goals';

export interface BaseGoalFormInterface {
  isEditingGoal: boolean;
  prevGoal: Goal | GoalTemplate;
  isLoading: boolean;
  handleSubmit: (name: string, amount: number, term: number) => Promise<void>;
  netIncome: number;
}

const getPrevQuickTerm = (term: string | number): number => {
  if (term === '') {
    return 0;
  }
  if (term === 6 || term === 12 || term === 24 || term === 60) {
    return Number(term);
  }

  return -1;
};

const GoalAmountStep: FC<BaseGoalFormInterface> = ({ isEditingGoal, prevGoal, isLoading, handleSubmit, netIncome }) => {
  const [amount, setAmount] = useState<number | string>(prevGoal.amount);
  const [term, setTerm] = useState<number | string>(prevGoal.term);
  const [isMobile, setIsMobile] = useState(false);
  const [quickTerm, setQuickTerm] = useState<number>(getPrevQuickTerm(prevGoal.term));
  const [showTermInput, setShowTermINput] = useState(quickTerm === -1);

  const handleAmountChange = (newAmount: number | string) => {
    setAmount(newAmount);
  };

  useEffect(() => {
    if (window.innerWidth <= 900) {
      setIsMobile(true);
    }
  }, []);

  const handleQuickTermChange = (event: React.MouseEvent<HTMLElement>, newTerm: number) => {
    setQuickTerm(newTerm);
    if (newTerm === -1) {
      setTerm('');
      setShowTermINput(true);
    } else {
      setTerm(newTerm);
      setShowTermINput(false);
    }
  };
  const handleTermChange = (newTerm: number | string) => {
    const newTermNumber = Number(newTerm);
    if (newTermNumber > 60) {
      setTerm(720);
    }
    setTerm(newTerm);
  };

  const getMonthlyAmount = () => {
    if (amount === '' || term === '') {
      return '$0';
    }
    return priceFormat.format(Math.round(Number(amount) / Number(term)));
  };

  const monthlyAmountPercentage = () => {
    if (amount === '' || term === '' || netIncome === 0 || !term) {
      return 0;
    }
    return ((Number(amount) / Number(term) / netIncome) * 100).toFixed(2);
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      <Grid container columnSpacing={1}>
        <Grid item xs={12}>
          <NumericFormat
            thousandSeparator="."
            decimalSeparator=","
            customInput={TextField}
            value={String(amount)}
            label="Monto"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
              sx: {
                '& fieldset': {
                  borderRadius: '25px',
                },
              },
            }}
            isAllowed={(values: NumberFormatValues) => {
              const { value } = values;
              return value === '' || (Number(value) >= 1 && Number(value) <= 1000000000);
            }}
            onValueChange={(values: NumberFormatValues) => {
              const { value } = values;
              handleAmountChange(value);
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography color="primary" fontWeight={700} fontSize="20px" sx={{ my: 2, textAlign: 'start' }}>
            ¿En cuánto tiempo?
          </Typography>
          <ToggleButtonGroup
            color="info"
            value={quickTerm}
            exclusive
            onChange={handleQuickTermChange}
            aria-label="Terms-quick-select"
            size={isMobile ? 'small' : 'medium'}
          >
            <ToggleButton value={6}>6 meses</ToggleButton>
            <ToggleButton value={12}>12 meses</ToggleButton>
            <ToggleButton value={24}>2 años</ToggleButton>
            <ToggleButton value={60}>5 años</ToggleButton>
            <ToggleButton value={-1}>Otra Duración</ToggleButton>
          </ToggleButtonGroup>
          {showTermInput ? (
            <NumericFormat
              thousandSeparator="."
              decimalSeparator=","
              customInput={TextField}
              value={String(term)}
              label="Duración"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="start">Meses</InputAdornment>,
                sx: {
                  '& fieldset': {
                    borderRadius: '25px',
                  },
                },
              }}
              isAllowed={(values: NumberFormatValues) => {
                const { value } = values;
                return value === '' || (Number(value) >= 1 && Number(value) <= 720);
              }}
              onValueChange={(values: NumberFormatValues) => {
                const { value } = values;
                handleTermChange(value);
              }}
              sx={{ mt: 2 }}
            />
          ) : null}
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography color="primary" fontWeight={400} fontSize="20px" sx={{ my: 2, textAlign: 'start' }}>
            Deberás ahorrar:
            <b> {getMonthlyAmount()} </b>
            al mes
          </Typography>
        </Grid>
        {Number(monthlyAmountPercentage()) > 0 && (
          <Grid item xs={12} display="flex">
            <Typography
              color={Number(monthlyAmountPercentage()) > 20 ? '#FF0000' : 'primary'}
              fontWeight={400}
              fontSize="20px"
              sx={{ textAlign: 'start' }}
            >
              Esto es un
              <b> {monthlyAmountPercentage()}% </b>
              de tu ingreso mensual.{' '}
              {Number(monthlyAmountPercentage()) > 20 ? '¡Quizás es mucho! 😥' : 'Suena razonable 👌'}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }} my={2}>
          <PrimaryBtn
            variant="contained"
            fullWidth
            onClick={() => {
              handleSubmit(prevGoal.name, Number(amount), Number(term)).catch((e) => console.error(e)); // eslint-disable-line no-console
            }}
            disabled={amount === '' || term === '' || !term || Number(amount) < 0}
          >
            {isEditingGoal ? 'Actualizar Objetivo' : 'Agregar Objetivo'}
          </PrimaryBtn>
        </Grid>
      </Grid>
    </>
  );
};

export default GoalAmountStep;
