/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Add from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { CajaStatusButton } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import DeleteButton from 'components/common/DeleteButton';
import { useAdminDeleteDebtMutation, useDeleteDebtMutation } from 'services/api/debt.api';
import priceFormat, { UFFormat } from 'services/format/priceFormat';
import { Debt as DebtInterface } from 'services/types/debt';
import { getDebtStatus, getMonthlyPayment, isImplicitTermType } from 'utils/debtHelpers';
import showDebtIcon from 'utils/showDebtIcon';

interface DebtsTableInterface {
  currentDebts: DebtInterface[] | undefined;
  openEditDebtModal: (debt: DebtInterface) => void;
  getData: () => void;
  handleOpenDebtTracesDrawer: (debt: DebtInterface) => void;
  handleAddDebts?: () => void;
  adminMode?: boolean;
}

const DebtsTable: FC<DebtsTableInterface> = ({
  currentDebts,
  openEditDebtModal,
  getData,
  handleOpenDebtTracesDrawer,
  handleAddDebts,
  adminMode = false,
}): JSX.Element => {
  const [deleteDebt, { isLoading: isLoadingDelete }] = useDeleteDebtMutation();
  const [adminDeleteDebt, { isLoading: isLoadingAdminDelete }] = useAdminDeleteDebtMutation();
  const deleteDebtFunction = async (objectId: number) => {
    if (adminMode) {
      await adminDeleteDebt(objectId);
    } else {
      await deleteDebt(objectId);
    }
    getData();
  };

  const unpaidDebts = currentDebts?.filter((debt) => (debt.monthsBehind || 0) > 0) || [];
  const onDueDebts = currentDebts?.filter((debt) => (debt.monthsBehind || 0) === 0) || [];

  const getEditButton = (debt: DebtInterface, behaviour: 'fijo' | 'fluido') => {
    return (
      <Button
        onClick={() => openEditDebtModal(debt)}
        variant="text"
        sx={{ width: behaviour === 'fijo' ? '100%' : 'auto' }}
      >
        Editar
      </Button>
    );
  };

  const showDebtsRows = (onDue: boolean) => {
    const debtsToShow = onDue ? onDueDebts : unpaidDebts;
    if (debtsToShow?.length) {
      return debtsToShow
        ?.sort((a, b) => {
          return a.institutionName?.localeCompare(b.institutionName || '') || 0;
        })
        .map((debt) => {
          // find trace with last created date
          const lastTrace = debt.debtTraces?.slice().sort((a, b) => {
            if (a.createdAt > b.createdAt) {
              return -1;
            }
            if (a.createdAt < b.createdAt) {
              return 1;
            }
            return 0;
          })[0];
          const debtStatus = lastTrace?.status || debt.status;
          const status = getDebtStatus(debtStatus || '');
          const showMonthlyPayment = !isImplicitTermType(debt) || adminMode;
          return (
            <TableRow key={debt.id}>
              <TableCell>
                <Box display="flex" justifyContent="space-between" alignContent="center">
                  <Box>
                    {debt.institutionName}
                    <br />
                    {debt.debtType && debt.debtType.charAt(0).toUpperCase() + debt.debtType.slice(1)}
                  </Box>
                  <Box>
                    <Avatar
                      sx={{
                        bgcolor: '#EDF1FA',
                        color: 'primary.main',
                        width: '30px',
                        height: '30px',
                      }}
                    >
                      {showDebtIcon(debt)}
                    </Avatar>
                  </Box>
                </Box>
              </TableCell>
              <TableCell>
                <Avatar
                  sx={{
                    bgcolor: '#EDF1FA',
                    color: 'primary.main',
                    width: '30px',
                    height: '30px',
                  }}
                >
                  {showDebtIcon(debt)}
                </Avatar>
              </TableCell>
              <TableCell>{debt.debtType && debt.debtType.charAt(0).toUpperCase() + debt.debtType.slice(1)}</TableCell>
              {adminMode && <TableCell>{debt.source}</TableCell>}
              <TableCell align="right">
                {debt.currency === 'UF' ? UFFormat(Number(debt.amount)) : priceFormat.format(Number(debt.amount))}
                {onDue && (
                  <Typography variant="body1" fontSize={16}>
                    {showMonthlyPayment ? `Cuota: ${getMonthlyPayment(debt)}` : ''}
                  </Typography>
                )}
                {!onDue && (
                  <Typography variant="body1" fontSize={16}>
                    {`${Number(debt.monthsBehind)} ${
                      Number(debt.monthsBehind) > 1 ? 'meses de atraso' : 'mes de atraso'
                    }`}
                  </Typography>
                )}
              </TableCell>
              <TableCell>
                <CajaStatusButton
                  text={status.text}
                  value={status.value}
                  onClick={() => handleOpenDebtTracesDrawer(debt)}
                />
              </TableCell>
              <TableCell>
                <Stack spacing={1}>
                  {getEditButton(debt, 'fijo')}
                  {((onDue && debt.source === 'Manual') || adminMode) && (
                    <DeleteButton
                      isMenuIcon={false}
                      objectId={debt.id}
                      isLoading={isLoadingDelete || isLoadingAdminDelete}
                      deleteFunction={deleteDebtFunction}
                      confirmationText="¿Estás seguro de eliminar esta deuda?"
                      onErrorAction={() => {}}
                      onSuccessAction={() => {}}
                      compact
                    />
                  )}
                </Stack>
              </TableCell>
            </TableRow>
          );
        });
    }
    return null;
  };

  const onDueDebtsTotalRow = (debts: DebtInterface[]) => {
    const totalMonthlyPayment = debts.reduce((acc, debt) => {
      if (isImplicitTermType(debt)) {
        return acc;
      }
      return acc + Number(debt.monthlyPayment);
    }, 0);
    const totalAmount = debts.reduce((acc, debt) => acc + Number(debt.amount), 0);

    return (
      <TableRow>
        <TableCell colSpan={adminMode ? 6 : 5} />
        <TableCell>Monto Total</TableCell>
        <TableCell align="right">{priceFormat.format(totalAmount)}</TableCell>
        <TableCell>Cuota Total</TableCell>
        <TableCell align="right">{priceFormat.format(totalMonthlyPayment)}</TableCell>
      </TableRow>
    );
  };

  const onDebtDebtsTotalRow = (debts: DebtInterface[]) => {
    const totalAmount = debts.reduce((acc, debt) => acc + Number(debt.amount), 0);
    return (
      <TableRow>
        <TableCell colSpan={adminMode ? 6 : 5} />
        <TableCell>Monto Total</TableCell>
        <TableCell align="right">{priceFormat.format(totalAmount)}</TableCell>
      </TableRow>
    );
  };

  if (isLoadingDelete || isLoadingAdminDelete) {
    return <CircularProgress sx={{ m: 'auto' }} />;
  }
  return (
    <PaperContainer>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Deudas al día</Typography>
        {handleAddDebts && (
          <Button variant="contained" onClick={handleAddDebts} startIcon={<Add />}>
            Agregar Deuda
          </Button>
        )}
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Institución</TableCell>
              <TableCell>Icono</TableCell>
              <TableCell>Tipo</TableCell>
              {adminMode && <TableCell>Fuente</TableCell>}
              <TableCell align="right">Monto</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showDebtsRows(true)}
            {onDueDebtsTotalRow(onDueDebts)}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={4}>
        <Typography variant="h6">Deudas atrasadas</Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Institución</TableCell>
              <TableCell>Icono</TableCell>
              <TableCell>Tipo</TableCell>
              {adminMode && <TableCell>Fuente</TableCell>}
              <TableCell align="right">Monto</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {showDebtsRows(false)}
            {onDebtDebtsTotalRow(unpaidDebts)}
          </TableBody>
        </Table>
      </TableContainer>
    </PaperContainer>
  );
};

DebtsTable.defaultProps = {
  adminMode: false,
  handleAddDebts: undefined,
};

export default DebtsTable;
