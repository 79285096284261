import React, { useState } from 'react';

import AttachMoney from '@mui/icons-material/AttachMoney';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import useIsMobile from 'hooks/useIsMobile';

import styles from './CreditSimulator.module.scss';
import CreditSimulator from './components/CreditSimulator';
import RefinanceSimulator from './components/RefinanceSimulator';

interface Props {
  onPreApprovalClick?: () => void;
}

const CreditSimulatorPage: React.FC<Props> = ({ onPreApprovalClick }) => {
  const { isMobile } = useIsMobile();
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box sx={{ p: isMobile ? 2 : 3 }}>
      <Box
        className={styles.styledCard}
        sx={{
          borderRadius: isMobile ? '1rem' : '3rem',
          mt: isMobile ? 2 : 0,
          mb: isMobile ? 2 : 0,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={activeTab}
            onChange={(_, newValue) => setActiveTab(newValue as number)}
            variant="fullWidth"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'primary.main',
                height: '4px',
              },
              '& .MuiTab-root': {
                transition: 'all 0.2s ease-in-out',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(25, 118, 210, 0.08)',
                  color: 'primary.main',
                  '& .MuiSvgIcon-root': {
                    color: 'primary.main',
                  },
                },
              },
            }}
          >
            <Tab
              icon={<AttachMoney />}
              label="Crédito de consumo"
              iconPosition="start"
              sx={{
                minHeight: '64px',
                textTransform: 'none',
                fontSize: '16px',
              }}
            />
            <Tab
              icon={<CompareArrowsIcon />}
              label="Compra de cartera"
              iconPosition="start"
              sx={{
                minHeight: '64px',
                textTransform: 'none',
                fontSize: '16px',
              }}
            />
          </Tabs>
        </Box>
        {activeTab === 0 ? (
          <CreditSimulator onPreApprovalClick={onPreApprovalClick} />
        ) : (
          <RefinanceSimulator onPreApprovalClick={onPreApprovalClick} />
        )}
      </Box>
    </Box>
  );
};

CreditSimulatorPage.defaultProps = {
  onPreApprovalClick: undefined,
};

export default CreditSimulatorPage;
