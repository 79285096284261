import React, { useEffect, useState } from 'react';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { createClient } from '@supabase/supabase-js';
import useIsMobile from 'hooks/useIsMobile';

interface Room {
  name: string;
  capacity: number;
}

interface Reservation {
  id: string;
  room: string;
  username: string;
  date: string;
  startTime: string;
  endTime: string;
}

interface Person {
  username: string;
  company: string;
}

const ROOMS: Room[] = [
  { name: 'Ring', capacity: 4 },
  { name: 'Cancha', capacity: 6 },
  { name: 'Estadio', capacity: 8 },
];

const TIME_SLOTS = Array.from({ length: 22 }, (_, i) => {
  const hour = Math.floor(i / 2) + 8;
  const minutes = i % 2 === 0 ? '00' : '30';
  return `${hour.toString().padStart(2, '0')}:${minutes}`;
});

const useSupabase = () => {
  const [supabase] = useState(() =>
    createClient(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_ANON_PUBLIC_KEY)
  );

  return supabase;
};

const ReservationDialog = ({
  open,
  onClose,
  onSave,
  room,
  currentUser,
  date,
  initialTime,
  reservations,
}: {
  open: boolean;
  onClose: () => void;
  onSave: (startTime: string, endTime: string) => Promise<void>;
  room: Room;
  currentUser: string;
  date: string;
  initialTime?: string;
  reservations: Reservation[];
}) => {
  const [startTime, setStartTime] = useState(initialTime || '');
  const [duration, setDuration] = useState('30');

  useEffect(() => {
    if (initialTime) {
      setStartTime(initialTime);
    }
  }, [initialTime]);

  const calculateEndTime = (start: string, durationMinutes: number): string => {
    const [hours, minutes] = start.split(':').map(Number);
    const tempDate = new Date();
    tempDate.setHours(hours);
    tempDate.setMinutes(minutes + durationMinutes);
    return `${tempDate.getHours().toString().padStart(2, '0')}:${tempDate.getMinutes().toString().padStart(2, '0')}`;
  };

  const isDurationDisabled = (durationMinutes: number): boolean => {
    if (!startTime) return false;

    const endTime = calculateEndTime(startTime, durationMinutes);
    const startDateTime = new Date(`${date}T${startTime}:00`);
    const endDateTime = new Date(`${date}T${endTime}:00`);

    return reservations.some((reservation) => {
      if (reservation.room !== room.name) return false;

      const reservationStart = new Date(`${date}T${reservation.startTime}`);
      const reservationEnd = new Date(`${date}T${reservation.endTime}`);

      // Check if there's any overlap
      return (
        (startDateTime < reservationEnd && endDateTime > reservationStart) ||
        (startDateTime === reservationStart && endDateTime === reservationEnd)
      );
    });
  };

  const handleSave = () => {
    const endTime = calculateEndTime(startTime, parseInt(duration, 10));
    onSave(startTime, endTime)
      .then(() => onClose())
      .catch(console.error); // eslint-disable-line no-console
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          px: 3,
          py: 2,
        }}
      >
        <Typography variant="h6" fontWeight={500}>
          Reservar {room.name}
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Box display="flex" flexDirection="column" gap={1} mt={2}>
          <Typography variant="body1">
            <Box component="span" fontWeight="bold">
              Usuario:
            </Box>{' '}
            {currentUser}
          </Typography>
          <Typography variant="body1">
            <Box component="span" fontWeight="bold">
              Fecha:
            </Box>{' '}
            {new Date(date).toLocaleDateString('es-CL', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            })}
          </Typography>
          <Typography variant="body1">
            <Box component="span" fontWeight="bold">
              Capacidad:
            </Box>{' '}
            {room.capacity} personas
          </Typography>
          <Typography variant="body1">
            <Box component="span" fontWeight="bold">
              Sala:
            </Box>{' '}
            {room.name}
          </Typography>
          <Typography variant="body1">
            <Box component="span" fontWeight="bold">
              Hora inicio:
            </Box>{' '}
            {startTime}
          </Typography>

          <TextField
            select
            label="Duración"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
            SelectProps={{
              native: true,
            }}
            fullWidth
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 1,
              },
              mt: 2,
            }}
          >
            <option value="30" disabled={isDurationDisabled(30)}>
              30 minutos
            </option>
            <option value="60" disabled={isDurationDisabled(60)}>
              1 hora
            </option>
            <option value="90" disabled={isDurationDisabled(90)}>
              1.5 horas
            </option>
            <option value="120" disabled={isDurationDisabled(120)}>
              2 horas
            </option>
          </TextField>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
          gap: 1,
        }}
      >
        <Button
          onClick={onClose}
          sx={{
            minWidth: 100,
            borderRadius: 1,
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          disabled={!startTime || isDurationDisabled(parseInt(duration, 10))}
          sx={{
            minWidth: 100,
            borderRadius: 1,
          }}
        >
          Reservar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ReservationDialog.defaultProps = {
  initialTime: '',
};

const SelectUser = ({
  currentUser,
  people,
  onChange,
}: {
  currentUser: string;
  people: Person[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}) => (
  <Box>
    <Typography variant="h6" color="primary" fontWeight="500" mb={2}>
      Seleccionar Usuario
    </Typography>
    <TextField
      select
      fullWidth
      value={currentUser}
      onChange={onChange}
      size="small"
      SelectProps={{
        native: true,
      }}
    >
      <option value="" disabled>
        Selecciona un usuario
      </option>
      {people
        .sort((a, b) => a.username.localeCompare(b.username))
        .map((person) => (
          <option key={person.username} value={person.username}>
            {person.username} ({person.company})
          </option>
        ))}
    </TextField>
  </Box>
);

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  reservation,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  reservation: Reservation;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleConfirm = () => {
    setIsDeleting(true);
    onConfirm()
      .then(() => {
        onClose();
      })
      .catch((error) => {
        console.error('Error deleting reservation:', error); // eslint-disable-line no-console
      })
      .finally(() => {
        setIsDeleting(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <DialogTitle
        sx={{
          borderBottom: '1px solid',
          borderColor: 'divider',
          px: 3,
          py: 2,
          color: 'error.main',
        }}
      >
        <Typography variant="h6" fontWeight={500}>
          Confirmar Eliminación
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ p: 3 }}>
        <Typography sx={{ mb: 2 }}>¿Estás seguro que deseas eliminar esta reserva?</Typography>
        <Box
          sx={{
            bgcolor: '#f8f9fa',
            p: 2,
            borderRadius: 1,
          }}
        >
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
            Sala: {reservation.room}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
            Fecha: {new Date(reservation.date).toLocaleDateString('es-CL')}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Hora: {reservation.startTime} - {reservation.endTime}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          px: 3,
          py: 2,
          borderTop: '1px solid',
          borderColor: 'divider',
          gap: 1,
        }}
      >
        <Button
          onClick={onClose}
          disabled={isDeleting}
          sx={{
            minWidth: 100,
            borderRadius: 1,
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={handleConfirm}
          color="error"
          variant="contained"
          disabled={isDeleting}
          sx={{
            minWidth: 100,
            borderRadius: 1,
          }}
        >
          {isDeleting ? 'Eliminando...' : 'Eliminar'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const RoomReservations = () => {
  const [people, setPeople] = useState<Person[]>([]);
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const { isMobile } = useIsMobile();
  const [currentUser, setCurrentUser] = useState(() => localStorage.getItem('roomReservationsUser') || '');
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [reservationDialogOpen, setReservationDialogOpen] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<string>('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
  const supabase = useSupabase();

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedUser = event.target.value;
    setCurrentUser(selectedUser);
    localStorage.setItem('roomReservationsUser', selectedUser);
  };

  const fetchUsers = async () => {
    const { data } = await supabase.from('Users').select('username,company');
    setPeople((data as Person[]).sort((a, b) => a.username.localeCompare(b.username)));
  };

  useEffect(() => {
    fetchUsers().catch(console.error); // eslint-disable-line no-console
  }, []);

  const fetchReservations = async () => {
    const { data } = await supabase.from('RoomReservations').select('*').eq('date', selectedDate);
    setReservations(data as Reservation[]);
  };

  const handleDateChange = (direction: 'prev' | 'next') => {
    const currentDate = new Date(selectedDate);
    const newDate = new Date(currentDate);

    if (direction === 'prev') {
      newDate.setDate(currentDate.getDate() - 1);
    } else {
      newDate.setDate(currentDate.getDate() + 1);
    }

    setSelectedDate(newDate.toISOString().split('T')[0]);
  };

  const saveReservation = async (startTime: string, endTime: string) => {
    if (!selectedRoom) return;

    await supabase.from('RoomReservations').insert({
      room: selectedRoom.name,
      username: currentUser,
      date: selectedDate,
      startTime,
      endTime,
    });

    await fetchReservations();
  };

  const handleDeleteReservation = async () => {
    if (!selectedReservation) return;

    await supabase.from('RoomReservations').delete().eq('id', selectedReservation.id);
    await fetchReservations();
  };

  useEffect(() => {
    const fetchReservationsPromise = fetchReservations();

    // subscribe to new reservations or updates
    supabase
      .channel('room-reservations')
      .on('postgres_changes', { event: '*', schema: 'public', table: 'RoomReservations' }, () => {
        fetchReservations().catch(console.error); // eslint-disable-line no-console
      })
      .subscribe();

    return () => {
      fetchReservationsPromise.catch(console.error); // eslint-disable-line no-console
      supabase.removeAllChannels().catch(console.error); // eslint-disable-line no-console
    };
  }, [selectedDate]);

  // If no user is selected, show only the user selector
  if (!currentUser) {
    return (
      <Box
        minHeight="100vh"
        width="100vw"
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          backgroundColor: '#f5f5f5',
          py: 4,
          minHeight: '100vh',
          height: '100%',
        }}
      >
        <Paper
          elevation={3}
          sx={{
            width: isMobile ? '95%' : '60%',
            maxWidth: '800px',
            p: 4,
          }}
        >
          <Typography variant="h2" color="primary" textAlign="center" fontWeight="bold">
            Reserva de Salas
          </Typography>
          <SelectUser currentUser={currentUser} people={people} onChange={handleUserChange} />
        </Paper>
      </Box>
    );
  }

  const getReservationForTimeSlot = (room: Room, timeSlot: string) => {
    const timeSlotFormatted = `${timeSlot}:00`;
    const foundReservation = reservations.find(
      (r) => r.room === room.name && r.startTime <= timeSlotFormatted && r.endTime > timeSlotFormatted
    );

    return foundReservation;
  };

  return (
    <Box
      minHeight="100vh"
      width="100vw"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        backgroundColor: '#f5f5f5',
        py: 2,
        minHeight: '100vh',
        height: '100%',
      }}
    >
      <Paper
        elevation={3}
        sx={{
          width: isMobile ? '95%' : '90%',
          maxWidth: '1200px',
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          gap: 3,
          borderRadius: 2,
          bgcolor: '#ffffff',
        }}
      >
        <Typography
          variant="h2"
          color="primary"
          textAlign="center"
          fontWeight="bold"
          sx={{
            fontSize: '2rem',
          }}
        >
          Reserva de Salas
        </Typography>

        <Box display="flex" alignItems="center" justifyContent="center" gap={2}>
          <IconButton onClick={() => handleDateChange('prev')}>
            <ChevronLeftIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{
              fontWeight: 500,
              color: '#1a1a1a',
              minWidth: '300px',
              textAlign: 'center',
            }}
          >
            {new Date(selectedDate).toLocaleDateString('es-CL', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
          <IconButton onClick={() => handleDateChange('next')}>
            <ChevronRightIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: '100%', overflowX: 'auto' }}>
          <Grid container sx={{ minWidth: 800 }}>
            {/* Header row */}
            <Grid item xs={3}>
              <Box
                sx={{
                  p: 1,
                  borderBottom: '2px solid',
                  borderColor: 'primary.main',
                  height: '40px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  bgcolor: '#f8f9fa',
                }}
              >
                <Typography fontWeight="500">Hora</Typography>
              </Box>
            </Grid>
            {ROOMS.map((room) => (
              <Grid item xs={3} key={room.name}>
                <Box
                  sx={{
                    p: 1,
                    borderBottom: '2px solid',
                    borderLeft: 1,
                    borderColor: 'primary.main',
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: '#f8f9fa',
                  }}
                >
                  <Typography fontWeight="500">
                    {room.name} ({room.capacity}p)
                  </Typography>
                </Box>
              </Grid>
            ))}

            {/* Time slots */}
            {TIME_SLOTS.map((timeSlot) => (
              <React.Fragment key={timeSlot}>
                <Grid item xs={3}>
                  <Box
                    sx={{
                      p: 0,
                      m: 0,
                      borderBottom: 1,
                      borderColor: 'divider',
                      height: '32px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontSize: '13px',
                      color: '#666',
                      lineHeight: 1,
                      bgcolor: '#fafafa',
                      '&.highlight': {
                        bgcolor: 'rgba(0, 0, 0, 0.1)',
                      },
                    }}
                    className={`time-slot-${timeSlot.replace(':', '-')}`}
                  >
                    {timeSlot}
                  </Box>
                </Grid>
                {ROOMS.map((room) => {
                  const reservation = getReservationForTimeSlot(room, timeSlot);
                  return (
                    <Grid item xs={3} key={`${room.name}-${timeSlot}`}>
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderLeft: 1,
                          borderColor: 'divider',
                          height: '32px',
                          bgcolor: (() => {
                            if (!reservation) return 'transparent';
                            return reservation.username === currentUser
                              ? 'rgba(33, 150, 243, 0.15)'
                              : 'rgba(244, 67, 54, 0.15)';
                          })(),
                          cursor: !reservation ? 'pointer' : 'default',
                          '&:hover': reservation
                            ? {
                                filter: 'brightness(0.5)',
                              }
                            : {
                                bgcolor: 'rgba(0, 0, 0, 0.1)',
                              },
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                        onClick={() => {
                          if (!reservation) {
                            setSelectedRoom(room);
                            setSelectedTimeSlot(timeSlot);
                            setReservationDialogOpen(true);
                          }
                        }}
                        onMouseEnter={() => {
                          const timeSlotElement = document.querySelector(`.time-slot-${timeSlot.replace(':', '-')}`);
                          if (timeSlotElement) {
                            timeSlotElement.classList.add('highlight');
                          }
                        }}
                        onMouseLeave={() => {
                          const timeSlotElement = document.querySelector(`.time-slot-${timeSlot.replace(':', '-')}`);
                          if (timeSlotElement) {
                            timeSlotElement.classList.remove('highlight');
                          }
                        }}
                      >
                        {reservation && (
                          <Box display="flex" alignItems="center" gap={1}>
                            <Typography
                              fontSize="13px"
                              lineHeight={1}
                              sx={{
                                fontWeight: reservation.username === currentUser ? 500 : 400,
                                color: reservation.username === currentUser ? 'primary.main' : 'error.main',
                              }}
                            >
                              {reservation.username}
                            </Typography>
                            {reservation.username === currentUser && (
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedReservation(reservation);
                                  setDeleteDialogOpen(true);
                                }}
                                sx={{
                                  padding: '2px',
                                  color: 'error.main',
                                  '&:hover': {
                                    bgcolor: 'rgba(244, 67, 54, 0.08)',
                                  },
                                }}
                              >
                                <DeleteIcon sx={{ fontSize: 16 }} />
                              </IconButton>
                            )}
                          </Box>
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </React.Fragment>
            ))}
          </Grid>
        </Box>

        <Box sx={{ mt: 4 }}>
          <SelectUser currentUser={currentUser} people={people} onChange={handleUserChange} />
        </Box>

        {selectedRoom && (
          <ReservationDialog
            open={reservationDialogOpen}
            onClose={() => setReservationDialogOpen(false)}
            onSave={saveReservation}
            room={selectedRoom}
            currentUser={currentUser}
            date={selectedDate}
            initialTime={selectedTimeSlot}
            reservations={reservations}
          />
        )}

        {selectedReservation && (
          <DeleteConfirmationDialog
            open={deleteDialogOpen}
            onClose={() => {
              setDeleteDialogOpen(false);
              setSelectedReservation(null);
            }}
            onConfirm={handleDeleteReservation}
            reservation={selectedReservation}
          />
        )}
      </Paper>
    </Box>
  );
};

export default RoomReservations;
