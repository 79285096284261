import React from 'react';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import useIsMobile from 'hooks/useIsMobile';
import CreditSimulatorPage from 'pages/CreditSimulator';
import HeaderLogos from 'pages/UniqueDebtFlow/components/HeaderLogos';

import styles from './LandingPage.module.scss';
import BankCarousel from './components/BankCarousel';

const BukDashboard = () => {
  const { isMobile } = useIsMobile();

  return (
    <div>
      {/* Hero Section */}
      <section className={styles.heroSection}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} lg={6}>
              <HeaderLogos origin="Buk" />
              <Box className={styles.heroContent}>
                <Typography variant="h2" className={styles.heroTitle}>
                  Simula y cotiza con las <span className={styles.highlight}>mejores tasas</span> del mercado
                </Typography>
                <Box className={styles.featuresList}>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Tasas exclusivas para usuarios Buk</Typography>
                  </Box>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Gestiona online</Typography>
                  </Box>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Aprobación rápida</Typography>
                  </Box>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Paga justo por tus deudas</Typography>
                  </Box>
                </Box>
              </Box>
              {!isMobile && (
                <Box className={styles.heroContent}>
                  <BankCarousel />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={styles.simulatorContainer}>
                <CreditSimulatorPage />
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: isMobile ? '0.6rem' : '0.8rem', display: 'block' }}
            >
              * La CAE (Carga Anual Equivalente) de 13,3780% y el costo total del crédito de $6.101.677 están calculados
              con tasa de interés de 1,09% mensual (13,08% anual) correspondiente a $5.042.591, en 36 cuotas mensuales e
              iguales, con pago de la primera cuota a 30 días del curse, e incluye el costo de impuesto de timbres y
              estampillas, y notario. Los montos recién indicados, son referenciales. Tasa válida desde el día
              11-03-2025 hasta el día 17-03-2025, ambos inclusive, tasa válida únicamente para contratación en línea en
              el sitio de Banco Internacional en internacional.cl, y únicamente para usuarios que ingresen desde de la
              plataforma en línea de Buk, en relif.cl/buk/dashboard. Su aprobación y otorgamiento está sujeto a la
              evaluación del cliente y al cumplimiento de las políticas de riesgo, de crédito y comerciales de Banco
              Internacional. Infórmese sobre la garantía estatal de los depósitos en su banco o en www.cmfchile.cl
            </Typography>
          </Box>
        </Container>
      </section>
    </div>
  );
};

export default BukDashboard;
