import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Typography, Dialog } from '@mui/material';

import { PrimaryBtn } from 'components/UI/Buttons';
import { BottomNavBar } from 'components/layout/BottomNavBar';
import Sidebar, { drawerWidth } from 'components/layout/SideBar/Sidebar';
import useIsMobile from 'hooks/useIsMobile';
import useQuery from 'hooks/useQuery';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';
import trpc from 'trpc';
import getBankOfferInstructions from 'utils/getBankOfferInstructions';

import OfferContainer from '../OfferContainer';
import RefinanceOffer from '../RefinanceOffer';

const BankOffer = () => {
  const { uuid } = useParams();
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  const withLayout = query.get('withLayout') === 'true';
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const [getUser, user] = useLazyGetUserInformationQuery();
  const isFinancialAdvisorUser = !!user?.data?.isFinancialAdvisorUser;
  const isMobileFinancialAdvisorUser = isMobile && isFinancialAdvisorUser;

  useEffect(() => {
    getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
  }, []);

  if (!uuid) {
    return null;
  }

  const { data: bankOffer } = trpc.bankOffers.list.useQuery({ uuid });
  const setUserReaction = trpc.bankOffers.userReaction.useMutation();
  const [selectedTerm, setSelectedTerm] = useState<number | null>(null);
  const [openSelectedOptionDialog, setOpenSelectedOptionDialog] = useState(false);

  const acceptedTitle = `¡Felicidades! Según nuestros registros, aceptaste la oferta para ${
    bankOffer?.cashPaymentAmount ? 'saldar' : 'renegociar '
  } tu deuda con ${bankOffer?.bank}${bankOffer?.description ? '*' : ''}`;

  const instructions = getBankOfferInstructions(bankOffer || null);

  const setInterested = (id: number, term: number): void => {
    if (!bankOffer?.uuid) {
      return;
    }
    setSelectedTerm(term);
    setOpenSelectedOptionDialog(true);
    setUserReaction.mutate({
      uuid: bankOffer.uuid,
      id,
      userReaction: `interested in ${term} months`,
    });
  };

  if (!bankOffer) {
    return null;
  }

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: withLayout && !isMobileFinancialAdvisorUser ? `calc(100% - ${drawerWidth}px)` : '100%',
        ml: withLayout && !isMobileFinancialAdvisorUser ? `${drawerWidth}px` : 0,
        minHeight: '100vh',
        position: 'relative',
        pb: isMobileFinancialAdvisorUser ? '72px' : 0,
      }}
    >
      {!withLayout && (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" p={1}>
          <PrimaryBtn onClick={() => navigate('/bank-offers')}>Volver</PrimaryBtn>
          <PrimaryBtn onClick={() => navigate(redirectUrl || '/debt')}>Salir</PrimaryBtn>
        </Box>
      )}

      <Box sx={{ width: { xs: '100%', md: '80%', lg: '50%', xl: '40%' } }} p={1}>
        <Dialog
          open={openSelectedOptionDialog}
          onClose={() => setOpenSelectedOptionDialog(false)}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            p: 2,
          }}
        >
          <Typography variant="body1" m={2}>
            {instructions}
          </Typography>
        </Dialog>
        <RefinanceOffer
          bankOffer={bankOffer}
          setInterested={setInterested}
          selectedTerm={selectedTerm}
          withLayout={withLayout}
        />
        {!withLayout && (
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" p={1}>
            Si no estás interesado en la oferta, puedes{' '}
            <span style={{ padding: '0 5px' }}>
              <a href={redirectUrl || '/debt'}>seguir adelante</a>
            </span>
          </Box>
        )}
      </Box>
    </Box>
  );

  if (bankOffer?.isAccepted) {
    const acceptedContent = (
      <OfferContainer>
        <Typography
          variant="h2"
          sx={{
            fontSize: { lg: '25px', xs: '25px' },
            width: '100%',
            mb: '15px',
            fontFamily: 'Roboto',
            fontWeight: 800,
            color: 'white',
            textAlign: 'center',
          }}
        >
          {acceptedTitle}
        </Typography>
        <Typography color="white" sx={{ fontWeight: 'bold', mt: 1, textAlign: 'center' }}>
          ¿Tienes dudas? ¿Crees que fue un error? <br />
          <a
            href="/book-meeting"
            style={{ color: 'white', textDecoration: 'underline' }}
            target="_blank"
            rel="noreferrer"
          >
            Agenda aquí una asesoría con el equipo de Relif
          </a>
        </Typography>
      </OfferContainer>
    );

    return (
      <Box sx={{ position: 'relative', minHeight: '100vh' }}>
        {withLayout && !isMobileFinancialAdvisorUser && <Sidebar />}
        <Box
          sx={{
            width: withLayout && !isMobileFinancialAdvisorUser ? `calc(100% - ${drawerWidth}px)` : '100%',
            ml: withLayout && !isMobileFinancialAdvisorUser ? `${drawerWidth}px` : 0,
            pb: isMobileFinancialAdvisorUser ? '72px' : 0,
          }}
        >
          {acceptedContent}
        </Box>
        {withLayout && isMobileFinancialAdvisorUser && (
          <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
            <BottomNavBar />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ position: 'relative', minHeight: '100vh' }}>
      {withLayout && !isMobileFinancialAdvisorUser && <Sidebar />}
      {content}
      {withLayout && isMobileFinancialAdvisorUser && (
        <Box sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }}>
          <BottomNavBar />
        </Box>
      )}
    </Box>
  );
};

export default BankOffer;
