import React, { FC, useEffect } from 'react';

import Step from '@mui/material/Step';
import StepContent from '@mui/material/StepContent';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

interface Props {
  processSteps: { title: string; description: string | React.ReactElement }[];
  currentStepIndex: number;
}

const TraceStepper: FC<Props> = ({ processSteps, currentStepIndex }) => {
  const [selectedStep, setSelectedStep] = React.useState(currentStepIndex);
  useEffect(() => {
    setSelectedStep(currentStepIndex);
  }, [currentStepIndex, processSteps]);

  return (
    <Stepper activeStep={currentStepIndex} orientation="vertical">
      {processSteps?.map((step, index) => (
        <Step key={step.title + index.toString()} completed={currentStepIndex > index}>
          <StepLabel>
            <Typography fontSize={18} fontWeight={650}>
              {step.title}
            </Typography>
          </StepLabel>
          <StepContent>
            <Typography fontSize={16} fontWeight={500} sx={{ display: selectedStep === index ? 'block' : 'none' }}>
              {step.description}
            </Typography>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default TraceStepper;
