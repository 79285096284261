import { BankOffer } from '@relif/backend';

import baseApi from './baseApi';

const bankOfferApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getBankOffers: build.query({
      query: () => ({
        url: `/bankOffers`,
        method: 'GET',
      }),
      transformResponse: (response: BankOffer[] | null) => {
        if (!response) return null;
        const now = new Date();
        return response.filter((offer) => {
          const limitDate = new Date(offer.limit);
          return limitDate > now;
        });
      },
    }),
    getInternationalBankOffer: build.query({
      query: () => ({
        url: `/bankOffers/banco-internacional`,
        method: 'GET',
      }),
      transformResponse: (response: BankOffer | null) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetBankOffersQuery, useGetInternationalBankOfferQuery } = bankOfferApi;

export default bankOfferApi;
