import React, { FC } from 'react';

import SuccessDialog from 'components/UI/SuccessDialog';
import { UserRefinanceStatus } from 'services/types/user';
import { getRefinanceTypeDescription, getRefinanceTypeTitle, hasActionsRequired } from 'utils/refinanceStatusHelpers';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  refinanceStatus: UserRefinanceStatus | null;
}

const DebtSuccessDialog: FC<Props> = ({ open, setOpen, refinanceStatus }) => {
  let header = '¡Felicidades!';
  let extraMsg = '';
  if (refinanceStatus) {
    header += `\nAhora entrarás a un proceso de "${getRefinanceTypeTitle(refinanceStatus.type)}"`;
    if (hasActionsRequired(refinanceStatus.step)) {
      extraMsg =
        '* Alguna de tus deudas requiere que realices una acción para continuar con el proceso. Revisa las que tienen estado "Acciones requeridas" a continuación.';
    }
  }
  return (
    <SuccessDialog
      openDialog={open}
      setOpenDialog={setOpen}
      header={header}
      msg={refinanceStatus ? getRefinanceTypeDescription(refinanceStatus.type) : ''}
      extraMsg={extraMsg}
    />
  );
};

export default DebtSuccessDialog;
