import React from 'react';

import { Box, Typography } from '@mui/material';

import { BankOffer } from '@relif/backend';
import { dateFormat } from 'services/format/dateFormat';
import { captureUserEvent } from 'utils/posthogUtils';

import TermOptionCard from './TermOptionCard';

const BankOfferRefinanceDetails = ({
  bankOffer,
  setInterested,
}: {
  bankOffer: BankOffer;
  setInterested: (id: number, months: number) => void;
}) => {
  return (
    <Box>
      <Typography
        variant="body1"
        sx={{
          width: '100%',
          fontSize: 18,
          fontWeight: 600,
          color: 'primary.main',
          textAlign: 'left',
        }}
      >
        Si tienes deudas vigentes, las puedes refinanciar con esta opción. Presiona el botón si quieres continuar con el
        proceso.
      </Typography>
      <TermOptionCard
        isSelected={false}
        boldTitle="Me interesa"
        title=""
        amount={bankOffer.paymentPlanAmount || 0}
        onClick={() => {
          captureUserEvent('BankOfferFlow: User confirmed interest in a Refinance offer option', {
            bank: bankOffer.bank,
            bankOfferUuid: bankOffer.uuid,
            bankOfferOption: bankOffer.paymentPlanAmount,
          });
          setInterested(bankOffer.id, 0);
        }}
      />

      <ul>
        <li>
          <Typography
            variant="body1"
            sx={{
              width: '100%',
              fontSize: 16,
              fontWeight: 400,
              color: 'primary.main',
              textAlign: 'left',
            }}
          >
            Un ejecutivo te va a contactar para confirmar el monto total del refinanciamiento y las cuotas.
          </Typography>
        </li>
        {bankOffer.description && (
          <li>
            <Typography
              variant="body1"
              sx={{
                width: '100%',
                fontSize: 16,
                fontWeight: 400,
                color: 'primary.main',
                textAlign: 'left',
              }}
            >
              {bankOffer.description}
            </Typography>
          </li>
        )}
        {bankOffer.firstPaymentDate && (
          <li>
            <Typography
              variant="body1"
              sx={{
                width: '100%',
                fontSize: 16,
                fontWeight: 400,
                color: 'primary.main',
                textAlign: 'left',
              }}
            >
              La fecha del primer pago sería el: {dateFormat(new Date(bankOffer.firstPaymentDate))}
            </Typography>
          </li>
        )}
      </ul>
    </Box>
  );
};

export default BankOfferRefinanceDetails;
