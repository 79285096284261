/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect } from 'react';

import { AlertColor } from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import { GoalIcon } from 'assets/icons';
import DrawerContent from 'components/UI/DrawerContent';
import SideDrawer from 'components/UI/SideDrawer';
import DefinitionWrapper from 'components/common/DefinitionWrapper';
import { useCreateGoalMutation, useEditGoalMutation } from 'services/api/goals.api';
import { Goal, GoalTemplate, GoalTemplatesResponse } from 'services/types/goals';
import getIcon from 'utils/goalUtils';
import { captureUserEvent } from 'utils/posthogUtils';

import GoalAmountStep from './GoalAmountStep';
import GoalNameStep from './GoalNameStep';

interface AddGoalModalInterface {
  open: boolean;
  addGoal: (newGoal: Goal) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleAlert: (severity: AlertColor, text: string) => void;
  templatesData: GoalTemplatesResponse;
  prevGoal: Goal | null;
  handleUpdateGoal: (newName: string, newAmount: number, newTerm: number) => void;
}

const steps = ['Objetivo', 'Nombre', 'Monto'];

const AddGoalModal: FC<AddGoalModalInterface> = ({
  open,
  addGoal,
  handleAlert,
  setOpen,
  templatesData,
  prevGoal,
  handleUpdateGoal,
}) => {
  const [createGoal, { data: newGoal, isLoading, isError }] = useCreateGoalMutation();
  const [updateGoal, { data: updatedGoal, isLoading: isUpdating, isError: isUpdateError }] = useEditGoalMutation();

  const [activeStep, setActiveStep] = React.useState(0);
  const [selectedTemplate, setSelectedTemplate] = React.useState<GoalTemplate | null>(null);

  const handleSubmit = async (name: string, amount: number, term: number) => {
    if (prevGoal) {
      captureUserEvent('ValuableAction: Create Goal', {
        isEdit: true,
      });
      await updateGoal({
        id: prevGoal.id,
        body: {
          name,
          amount,
          term,
        },
      });
    } else {
      captureUserEvent('ValuableAction: Create Goal', {
        isEdit: false,
      });
      await createGoal({
        name,
        amount,
        term,
      });
      setActiveStep(0);
      setSelectedTemplate(null);
    }
  };

  useEffect(() => {
    if (newGoal) {
      addGoal(newGoal);
      setOpen(false);
      handleAlert('success', 'Objetivo creado correctamente!');
    }
    if (isError) {
      handleAlert('error', 'Error! Intenta crear objetivo nuevamente!');
    }
  }, [newGoal, isError]);

  useEffect(() => {
    if (updatedGoal) {
      handleUpdateGoal(updatedGoal.name, Number(updatedGoal.amount), Number(updatedGoal.term));
      setOpen(false);
      handleAlert('success', 'Objetivo actualizado correctamente!');
      setActiveStep(0);
    }
    if (isUpdateError) {
      handleAlert('error', 'Error! Intenta actualizar objetivo nuevamente!');
    }
  }, [updatedGoal, isUpdateError]);

  useEffect(() => {
    if (prevGoal) {
      setSelectedTemplate({
        name: prevGoal.name,
        icon: GoalIcon,
        amount: prevGoal.amount,
        term: prevGoal.term,
        isUnpaidDebt: true,
      });
      setActiveStep(2);
    }
  }, [prevGoal]);

  return (
    <SideDrawer
      title={selectedTemplate && selectedTemplate.name ? `${selectedTemplate.name}` : 'Nuevo objetivo'}
      open={open}
      onClose={() => {
        setOpen(false);
        setActiveStep(0);
        setSelectedTemplate(null);
      }}
      activeStep={activeStep}
      setActiveStep={setActiveStep}
    >
      <Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label} completed={activeStep > index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {activeStep === 0 && (
          <DrawerContent title="Escoge un tipo de objetivo">
            <Grid container>
              {templatesData.templates.map((goal) => {
                const { name } = goal;
                return (
                  <Grid item xs={12} key={name} sx={{ minHeight: '77px' }} p={1}>
                    <Button
                      fullWidth
                      variant={goal.isUnpaidDebt ? 'contained' : 'outlined'}
                      onClick={() => {
                        setSelectedTemplate(goal);
                        setActiveStep(1);
                      }}
                      sx={{ justifyContent: 'flex-start', textTransform: 'none' }}
                    >
                      <Box display="flex" alignItems="center">
                        {!goal.isUnpaidDebt && (
                          <Avatar sx={{ backgroundColor: 'backgroundGrey.main', color: '#3E4545' }}>
                            {getIcon(name)}
                          </Avatar>
                        )}
                        <Typography
                          variant="body2"
                          color={goal.isUnpaidDebt ? 'white' : '#3E4545'}
                          fontSize={{ xs: '14px', lg: '16px' }}
                          mx={2}
                        >
                          {name === 'Fondo de emergencias' ? (
                            <DefinitionWrapper definitionName={name} placement="bottom">
                              {name}
                            </DefinitionWrapper>
                          ) : (
                            name
                          )}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </DrawerContent>
        )}
        {activeStep === 1 && selectedTemplate && (
          <DrawerContent title="Dale un nombre">
            <GoalNameStep
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              handleNext={() => setActiveStep(2)}
            />
          </DrawerContent>
        )}
        {activeStep === 2 && selectedTemplate && (
          <DrawerContent title="¿Cuánto quieres ahorrar?">
            <GoalAmountStep
              isEditingGoal={!!prevGoal}
              prevGoal={selectedTemplate}
              handleSubmit={handleSubmit}
              netIncome={templatesData?.lastIncome || 0}
              isLoading={isLoading || isUpdating}
            />
          </DrawerContent>
        )}
      </Box>
    </SideDrawer>
  );
};

export default AddGoalModal;
