import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

interface BlogBreadCrumbsInterface {
  title: string;
  isHome: boolean;
}

const NewHomeBreadCrumbs: FC<BlogBreadCrumbsInterface> = ({ title, isHome }) => (
  <Breadcrumbs>
    <Link component={RouterLink} to="/new-home" color="inherit">
      Inicio
    </Link>
    {!isHome && <Typography color="text.primary">{title}</Typography>}
  </Breadcrumbs>
);

export default NewHomeBreadCrumbs;
