/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import priceFormat from 'services/format/priceFormat';
import { DebtToCategorize } from 'services/types/debt';
import showDebtIcon from 'utils/showDebtIcon';

interface DebtTableInterface {
  debtList: DebtToCategorize[];
}

const AVATAR_SIZE = '35px';

const DebtSummaryTable: FC<DebtTableInterface> = ({ debtList }) => {
  const getInstitutionAvatar = (debt: DebtToCategorize) => (
    <Avatar
      sx={{
        bgcolor: '#EDF1FA',
        color: 'primary.main',
        width: AVATAR_SIZE,
        height: AVATAR_SIZE,
      }}
    >
      {showDebtIcon(debt)}
    </Avatar>
  );
  const getTotalPaymentLabel = (debt: DebtToCategorize) => priceFormat.format(Number(debt.totalAmount));

  return (
    <TableContainer component={Paper} sx={{ boxShadow: 2 }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: AVATAR_SIZE }}>&nbsp;</TableCell>
            <TableCell>Institución</TableCell>
            <TableCell>Deuda Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {debtList.map((debt) => (
            <TableRow key={`debt-${debt.institutionName}-${debt.debtType}`}>
              <TableCell>
                <Box display="flex" justifyContent="space-between" alignContent="center">
                  <Box>
                    {debt.institutionName}
                    <br />
                    {debt.debtType === 'crédito de consumo' ? 'Consumo' : 'Hipotecario'}
                  </Box>
                </Box>
              </TableCell>
              <TableCell>{getInstitutionAvatar(debt)}</TableCell>
              <TableCell>{getTotalPaymentLabel(debt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DebtSummaryTable;
