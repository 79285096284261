import AccountBalance from '@mui/icons-material/AccountBalance';
import DirectionsCar from '@mui/icons-material/DirectionsCar';
import House from '@mui/icons-material/House';
import LocalHospital from '@mui/icons-material/LocalHospital';
import LocalMall from '@mui/icons-material/LocalMall';
import Savings from '@mui/icons-material/Savings';
import Storefront from '@mui/icons-material/Storefront';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon';

import Logo_Abcdin from 'assets/images/logos/abcdin.webp';
import Logo_Ahorro from 'assets/images/logos/ahorrocoop.webp';
import Logo_Amicar from 'assets/images/logos/amicar.webp';
import Logo_Autofin from 'assets/images/logos/autofin.webp';
import Logo_BanChile from 'assets/images/logos/banco-de-chile.webp';
import Logo_BanEstado from 'assets/images/logos/banco-estado.webp';
import Logo_BanFalabella from 'assets/images/logos/banco-falabella.webp';
import Logo_BancoInternacional from 'assets/images/logos/banco-internacional.webp';
import Logo_BCI from 'assets/images/logos/bci.webp';
import Logo_Bice from 'assets/images/logos/bice.webp';
import Logo_Caja18 from 'assets/images/logos/caja18.webp';
import Logo_Araucana from 'assets/images/logos/caja-la-araucana.webp';
import Logo_Andes from 'assets/images/logos/caja-los-andes.webp';
import Logo_Capual from 'assets/images/logos/capual.webp';
import Logo_Cencosud from 'assets/images/logos/cencosud.webp';
import Logo_Central from 'assets/images/logos/central.webp';
import Logo_Cmr from 'assets/images/logos/cmr.webp';
import Logo_Concrece from 'assets/images/logos/concrece.webp';
import Logo_Coop from 'assets/images/logos/coopeuch.webp';
import Logo_Creditu from 'assets/images/logos/creditu.webp';
import Logo_Cruz from 'assets/images/logos/cruz-verde.webp';
import Logo_Detacoop from 'assets/images/logos/detacoop.webp';
import Logo_Forum from 'assets/images/logos/forum.webp';
import Logo_Hites from 'assets/images/logos/hites.webp';
import Logo_Polar from 'assets/images/logos/la-polar.webp';
import Logo_Lider from 'assets/images/logos/lider.webp';
import Logo_Heroe from 'assets/images/logos/los-heroes.webp';
import Logo_MYV from 'assets/images/logos/m-y-v.webp';
import Logo_Oriencoop from 'assets/images/logos/oriencoop.webp';
import Logo_Ripley from 'assets/images/logos/ripley.webp';
import Logo_Salco from 'assets/images/logos/salcobrand.webp';
import Logo_Santander from 'assets/images/logos/santander.webp';
import Logo_Scotia from 'assets/images/logos/scotiabank.webp';
import Logo_Tanner from 'assets/images/logos/tanner.webp';
import Logo_Unimarc from 'assets/images/logos/unimarc.webp';

import debtTypeList from './debtTypeList';

interface Institution {
  name: string;
  logo: string | null;
}

interface InstitutionType {
  name: string;
  institutions: Institution[];
  debtTypes: typeof debtTypeList;
  emoji: string;
  icon: OverridableComponent<SvgIconTypeMap<unknown, 'svg'>>;
}

const returnInstitutions = (names: string[]) => {
  const institutions: Institution[] = names.map((name) => {
    return { name, logo: null };
  });
  return institutions;
};

const showBank: Institution[] = [
  { name: 'Banco Estado', logo: Logo_BanEstado },
  { name: 'Santander', logo: Logo_Santander },
  { name: 'BCI', logo: Logo_BCI },
  { name: 'Banco de Chile', logo: Logo_BanChile },
  { name: 'Scotiabank', logo: Logo_Scotia },
  { name: 'Banco Internacional', logo: Logo_BancoInternacional },
];
const otherBanks = returnInstitutions([
  'Itaú Corpbanca',
  'Bice',
  'Security',
  'Consorcio',
  'Banco Falabella',
  'BTG Pactual Chile',
  'Banco Ripley',
  'China Construction Bank, Agencia en Chile',
  'HSBC Bank (Chile)',
  'Jp Morgan Chase Bank, N.A.',
  'Bank of China, Agencia en Chile',
]);

showBank.push(...otherBanks);

const showComercial: Institution[] = [
  { name: 'CMR', logo: Logo_Cmr },
  { name: 'Cencosud', logo: Logo_Cencosud },
  { name: 'Ripley', logo: Logo_Ripley },
  { name: 'Abcdin', logo: Logo_Abcdin },
  { name: 'La Polar', logo: Logo_Polar },
];

const otherComercial: Institution[] = returnInstitutions(['Tricot', 'Corona', 'Fiso']).concat([
  {
    name: 'Hites',
    logo: Logo_Hites,
  },
]);

showComercial.push(...otherComercial);

const cajaList: Institution[] = [
  { name: 'Caja Los Andes', logo: Logo_Andes },
  { name: 'Los Heroes', logo: Logo_Heroe },
  { name: '18 De Septiembre', logo: Logo_Caja18 },
  { name: 'La Araucana', logo: Logo_Araucana },
];

const cooperativeList: Institution[] = [
  { name: 'Coopeuch', logo: Logo_Coop },
  { name: 'Capual', logo: Logo_Capual },
  { name: 'Oriencoop', logo: Logo_Oriencoop },
  { name: 'Ahorrocoop', logo: Logo_Ahorro },
  { name: 'Detacoop', logo: Logo_Detacoop },
  { name: 'Lautaro Rosas', logo: null },
  { name: 'Coocretal', logo: null },
];

const marketList: Institution[] = [
  { name: 'Salcobrand', logo: Logo_Salco },
  { name: 'Cruz Verde', logo: Logo_Cruz },
  { name: 'Unimarc', logo: Logo_Unimarc },
  { name: 'Líder', logo: Logo_Lider },
];

const autoList: Institution[] = [
  { name: 'Forum', logo: Logo_Forum },
  { name: 'Tanner', logo: Logo_Tanner },
  { name: 'Banco Falabella', logo: Logo_BanFalabella },
  { name: 'Amicar', logo: Logo_Amicar },
  { name: 'AutoFin', logo: Logo_Autofin },
];

const leftAuto = [
  'Santander Consumer',
  'Crediautos',
  'Crédito Digital',
  'Global Soluciones Financieras',
  'MAF',
  'Marubeni',
  'Mundo crédito ',
  'Nuevo Capital',
  'Olx Autos',
  'Transa Tu Auto',
  'Eurocapital',
  'Auto Cred',
  'Kavak',
  'Unidad Crédito Automotriz',
];

autoList.push(...returnInstitutions(leftAuto));

const mutualList: Institution[] = [
  { name: 'M Y V', logo: Logo_MYV },
  { name: 'Concreces', logo: Logo_Concrece },
  { name: 'Bice', logo: Logo_Bice },
  { name: 'Central', logo: Logo_Central },
  { name: 'Creditu', logo: Logo_Creditu },
];

const leftMutual = [
  'Bice',
  'Central',
  'Creditu',
  'Evoluciona',
  'Andes',
  'La Construccion',
  'Security Principal',
  'Metlife',
  'Renta Nacional',
  'Nuevo Capital',
  'Penta',
  'Xlc',
];

mutualList.push(...returnInstitutions(leftMutual));

// list taken from https://www.notion.so/Lista-instituciones-financieras-ff135f5638a5404090742548fac2366b
export const institutionTypeList: InstitutionType[] = [
  {
    name: 'Banco',
    institutions: showBank,
    debtTypes: [
      { name: 'crédito de consumo', familyType: 'installments' },
      { name: 'crédito automotriz', familyType: 'installments' },
      { name: 'tarjeta de crédito', familyType: 'revolving' },
      { name: 'linea de crédito', familyType: 'revolving' },
      { name: 'crédito hipotecario', familyType: 'uf' },
      { name: 'crédito de educación', familyType: 'uf' },
    ],
    emoji: '🏦',
    icon: AccountBalance,
  },
  {
    name: 'Casa Comercial',
    institutions: showComercial,
    debtTypes: [
      { name: 'tarjeta de crédito', familyType: 'revolving' },
      { name: 'crédito repactado', familyType: 'installments' },
    ],
    emoji: '🛍️',
    icon: LocalMall,
  },
  {
    name: 'Caja de Compensación',
    institutions: cajaList,
    debtTypes: [{ name: 'crédito de consumo', familyType: 'installments' }],
    emoji: '📦',
    icon: House,
  },
  {
    name: 'Cooperativas de ahorro y crédito',
    institutions: cooperativeList,
    debtTypes: [
      { name: 'crédito de consumo', familyType: 'installments' },
      { name: 'tarjeta de crédito', familyType: 'revolving' },

      { name: 'crédito hipotecario', familyType: 'uf' },
    ],
    emoji: '🐷',
    icon: Savings,
  },
  {
    name: 'Supermercados y farmacias',
    institutions: marketList,
    debtTypes: [
      { name: 'tarjeta de crédito', familyType: 'revolving' },
      { name: 'crédito repactado', familyType: 'installments' },
    ],
    emoji: '🛒',
    icon: Storefront,
  },
  {
    name: 'Prestador Automotriz',
    institutions: autoList,
    debtTypes: [{ name: 'crédito automotriz', familyType: 'installments' }],
    emoji: '🚘',
    icon: DirectionsCar,
  },
  {
    name: 'Mutuarias',
    institutions: mutualList,
    debtTypes: [{ name: 'crédito hipotecario', familyType: 'uf' }],
    emoji: '🏢',
    icon: LocalHospital,
  },
];

interface InstitutionWithLogo {
  name: string;
  logo: string;
}

const institutionsWithLogo: InstitutionWithLogo[] = [];
institutionTypeList.forEach((institutionType) => {
  institutionsWithLogo.push(
    ...(institutionType.institutions.filter((institution) => institution.logo !== null) as InstitutionWithLogo[])
  );
});

export const findCMFInstitutionsLogo = (institutionName: string) => {
  let searchString = institutionName
    .toLowerCase()
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace('banco', '')
    .trim();
  if (searchString.includes('de chile') && !searchString.includes('estado')) {
    searchString = 'banco de chile';
  } else if (searchString.includes('falabella')) {
    searchString = 'banco falabella';
  } else if (searchString.includes('santander')) {
    searchString = 'santander';
  } else if (searchString.includes('ripley')) {
    searchString = 'ripley';
  } else if (searchString.includes('lider')) {
    searchString = 'líder';
  } else if (searchString.includes('credito e inversiones')) {
    searchString = 'bci';
  } else if (searchString.includes('del estado')) {
    searchString = 'banco estado';
  } else if (searchString.includes('hites')) {
    searchString = 'hites';
  } else {
    searchString = searchString.replace('chile', '').trim();
  }
  const foundInstitutions = institutionsWithLogo.filter((institution) =>
    institution.name.toLowerCase().includes(searchString)
  );
  if (foundInstitutions.length > 0) {
    return foundInstitutions[0].logo;
  }
  return '';
};
