/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CajaTooltip from 'components/UI/CajaTooltip';
import MoneyInput from 'components/UI/MoneyInput';
import { DebtDraft } from 'services/types/debt';

interface ManualDebtStep4Interface {
  debtFamilyType: string | undefined;
  debtDraft: DebtDraft;
  setDebtDraft: (debtDraft: DebtDraft) => void;
}

const ManualDebtStep4: FC<ManualDebtStep4Interface> = ({ debtDraft, setDebtDraft, debtFamilyType }) => {
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} py={1}>
          <FormControl fullWidth>
            <InputLabel id="currency-label">Moneda</InputLabel>
            <Select
              labelId="currency-label"
              id="currency"
              value={debtDraft.currency}
              label="Moneda"
              onChange={(e) => setDebtDraft({ ...debtDraft, currency: e.target.value })}
            >
              {['CLP', 'UF'].map((currency) => (
                <MenuItem value={currency} key={currency}>
                  <Typography variant="body2" color="primary">
                    {currency}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <CajaTooltip title="Monto de dinero que te queda por pagar de la deuda">
          <Grid item xs={12} lg={6} py={1}>
            <MoneyInput
              label={`Saldo vigente en ${debtDraft.currency}`}
              value={Number(debtDraft.amount)}
              setValue={(value) => setDebtDraft({ ...debtDraft, amount: value })}
              disabled={false}
            />
          </Grid>
        </CajaTooltip>
        {debtFamilyType !== 'revolving' && (
          <>
            <CajaTooltip
              title={
                debtFamilyType === 'revolving'
                  ? 'Monto mínimo que debes pagar mensualmente'
                  : 'Monto de dinero que pagas mensualmente'
              }
            >
              <Grid item xs={12} lg={6} py={1}>
                <MoneyInput
                  label={debtFamilyType === 'revolving' ? 'Mínimo a pagar mensualmente' : 'Monto mensual'}
                  value={Number(debtDraft.monthlyPayment)}
                  setValue={(value) => setDebtDraft({ ...debtDraft, monthlyPayment: value })}
                />
              </Grid>
            </CajaTooltip>
            <CajaTooltip title="N° de cuotas cuando iniciaste el crédito">
              <Grid item xs={12} lg={6} py={1}>
                <TextField
                  id="term"
                  name="term"
                  type="number"
                  label="N° de cuotas iniciales"
                  value={debtDraft.term.toString()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDebtDraft({ ...debtDraft, term: Math.trunc(Number(e.target.value)) })
                  }
                  inputProps={{ min: 0 }}
                  fullWidth
                />
              </Grid>
            </CajaTooltip>
            <CajaTooltip title="N° de cuotas que te quedan por pagar del crédito">
              <Grid item xs={12} lg={6} py={1}>
                <TextField
                  id="pendingPayments"
                  name="pendingPayments"
                  type="number"
                  label="N° de cuotas por pagar"
                  value={debtDraft.pendingPayments.toString()}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setDebtDraft({ ...debtDraft, pendingPayments: Math.trunc(Number(e.target.value)) })
                  }
                  inputProps={{ min: 0 }}
                  fullWidth
                />
              </Grid>
            </CajaTooltip>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default ManualDebtStep4;
