import React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import styles from './BankCarousel.module.scss';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const banks = [
  {
    name: 'Banco de Chile',
    logo: '/landing_page/img/companies/banco-de-chile.webp',
  },
  {
    name: 'Banco Internacional',
    logo: '/landing_page/img/companies/banco-internacional.webp',
  },
  {
    name: 'Caja Los Andes',
    logo: '/landing_page/img/companies/caja-los-andes.webp',
  },
  {
    name: 'Banco Estado',
    logo: '/landing_page/img/companies/banco-estado.webp',
  },
  {
    name: 'Banco BCI',
    logo: '/landing_page/img/companies/bci.webp',
  },
];

const BankCarousel = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  // Crear un array con los bancos duplicados para la rotación infinita
  const duplicatedBanks = [...banks, ...banks];

  return (
    <Box className={styles.carouselContainer}>
      <Box className={styles.carouselWrapper}>
        <AutoPlaySwipeableViews
          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents={false}
          interval={3000}
          slideCount={banks.length}
          resistance
          threshold={10}
        >
          {duplicatedBanks.map((bank, index) => (
            <Box key={`${bank.name}-${index >= banks.length ? 'duplicate' : 'original'}`} className={styles.bankCard}>
              <img src={bank.logo} alt={bank.name} className={styles.bankLogo} />
              <Typography variant="h6" className={styles.bankName}>
                {bank.name}
              </Typography>
            </Box>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
    </Box>
  );
};

export default BankCarousel;
