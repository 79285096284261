import React, { FC } from 'react';

import { Typography } from '@mui/material';

import DrawerContent from 'components/UI/DrawerContent';
import SideDrawer from 'components/UI/SideDrawer';
import TraceStepper from 'components/UI/TraceStepper';
import { UserRefinanceStatus } from 'services/types/user';
import { getRefinanceTypeDescription, getRefinanceTypeTitle } from 'utils/refinanceStatusHelpers';

interface Props {
  open: boolean;
  onClose: () => void;
  refinanceStatus: UserRefinanceStatus | null;
}

const normalizationProcessSteps: {
  title: string;
  description: string;
  steps: UserRefinanceStatus['step'][];
  isManualStep?: boolean;
}[] = [
  {
    title: 'Revisamos tu boletín comercial',
    description:
      'Revisamos la información contenida en tu boletín, para conocer a quiénes les debes y así poder ayudarte.',
    steps: [],
  },
  {
    title: 'Buscando ofertas',
    description: 'Estamos en busca de ofertas de pago con tus acreedores.',
    steps: ['waitingForOptions'],
  },
  {
    title: 'Acciones requeridas',
    description:
      'Necesitamos que te comuniques con alguna de tus instituciones para buscar una oferta. Puedes regresar a la lista de deudas y revisar las que indican "Acciones requeridas".',
    steps: ['actionsRequired', 'actionsRequiredAndSomeOptions'],
    isManualStep: true,
  },
  {
    title: 'Ofertas encontradas',
    description:
      'Algunas de tus instituciones han enviado una oferta de pago. Puedes regresar a la lista de deudas y revisar las que indican "Nueva oferta".',
    steps: ['hasSomeOptions', 'hasAllOptions'],
  },
  {
    title: 'Ofertas aceptadas',
    description: 'Has aceptado la oferta de pago.',
    steps: [],
  },
  {
    title: 'Monitoreo',
    description: 'Vamos a actualizar tu boletín comercial para verificar que se haya limpiado.',
    steps: [],
  },
];

const refinanceProcessSteps = [
  {
    title: 'Deudas categorizadas',
    description: 'Nos indicaste de que se tratan cada una de tus deudas.',
    steps: [],
  },
  {
    title: 'Buscando ofertas',
    description: 'Estamos en busca de una oferta de compra de cartera con alguna de nuestras instituciones aliadas.',
    steps: ['waitingForOptions'],
  },
  {
    title: 'Ofertas encontradas',
    description: 'Algunas de nuestras instituciones aliadas han enviado una oferta de compra de cartera.',
    steps: ['hasSomeOptions', 'hasAllOptions'],
  },
  {
    title: 'Portabilidad',
    description: 'Te ayudamos a realizar el proceso de portabilidad de deudas hacia la nueva institución.',
    steps: [],
  },
  {
    title: 'Monitoreo',
    description:
      'Vamos a estar actualizando el estado de tus deudas para verificar que te mantengas con una carga financiera sostenible.',
    steps: [],
  },
];

const getProcessSteps = (type: UserRefinanceStatus['type'], step: UserRefinanceStatus['step']) => {
  if (type === 'normalize') {
    const isManualProcess = ['actionsRequired', 'actionsRequiredAndSomeOptions'].includes(step);
    return isManualProcess
      ? normalizationProcessSteps
      : normalizationProcessSteps.filter((_step) => !_step.isManualStep);
  }
  if (type === 'refinance') {
    return refinanceProcessSteps;
  }
  return [];
};

const RefinanceStatusDrawer: FC<Props> = ({ open, onClose, refinanceStatus }) => {
  if (!refinanceStatus) {
    return null;
  }
  const { type, step } = refinanceStatus;

  const getCurrentStepIndex = () => {
    if (!step) {
      return 0;
    }
    const processSteps = getProcessSteps(type, step);
    const currentStepIndex = processSteps.findIndex((processStep) => processStep.steps.includes(step));
    return currentStepIndex === -1 ? 0 : currentStepIndex;
  };
  return (
    <SideDrawer open={open} onClose={onClose} title="Estado de refinanciamiento" activeStep={null} setActiveStep={null}>
      <DrawerContent title={getRefinanceTypeTitle(type)}>
        <Typography
          variant="body1"
          sx={{
            textAlign: 'justify',
            mb: 2,
          }}
        >
          {getRefinanceTypeDescription(type)}
        </Typography>
        <TraceStepper processSteps={getProcessSteps(type, step)} currentStepIndex={getCurrentStepIndex()} />
      </DrawerContent>
    </SideDrawer>
  );
};

export default RefinanceStatusDrawer;
