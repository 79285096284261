import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AttachMoney from '@mui/icons-material/AttachMoney';
import CalendarToday from '@mui/icons-material/CalendarToday';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';

import styles from '../CreditSimulator.module.scss';
import PreApprovalButton from './PreApprovalButton';

interface Props {
  onPreApprovalClick?: () => void;
}

const RefinanceSimulator: React.FC<Props> = ({ onPreApprovalClick }) => {
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const [monthlyPayment, setMonthlyPayment] = useState(0); // Cuanto pagas mensualmente por tus deudas
  const [paidTerms, setPaidTerms] = useState(24); // Cuantas cuotas te quedan por pagar
  const [newTerm, setNewTerm] = useState(24); // Cuantas cuotas te gustaría pagar para renegociar tu deuda
  const [newMonthlyPayment, setNewMonthlyPayment] = useState(0); // Cuota nueva
  const [currentMonthlyPayment, setCurrentMonthlyPayment] = useState(0); // Cuota actual
  const [currentBalance, setCurrentBalance] = useState(0); // Saldo actual
  const [currentTotalPayment, setCurrentTotalPayment] = useState(0); // Total pago actual
  const [newTotalPayment, setNewTotalPayment] = useState(0); // Total pago nuevo

  const debtFlow = useDebtFlow();
  const redirectUrl = debtFlow.CMFResponse ? '/chat' : '/onboarding/buk';

  // Constants for interest rates - different rates based on route
  const NEW_ANNUAL_RATE = location.pathname.includes('/buk/dashboard') ? 0.131 : 0.143; // 13.1% for Buk, 14.3% for Landing
  const CURRENT_ANNUAL_RATE = 0.26; // Base comparison rate stays at 26%

  // Calculate Present Value (VA) - Current Balance using provided CAE
  const calculatePresentValue = (nper: number, pmt: number, monthlyRate: number) => {
    return (pmt * (1 - (1 + monthlyRate) ** -nper)) / monthlyRate;
  };

  // Calculate Payment (PAGO) - New Monthly Payment
  const calculateMonthlyPayment = (rate: number, amount: number, term: number) => {
    if (amount > 0 && term > 0) {
      return (amount * rate) / (1 - (1 + rate) ** -term);
    }
    return 0;
  };

  // Calculate current balance and new payment when inputs change
  useEffect(() => {
    if (monthlyPayment > 0 && paidTerms > 0) {
      // Calculate new monthly payment if new term is selected
      if (newTerm > 0) {
        // const currentMonthlyRate = (1 + CURRENT_ANNUAL_RATE) ** (1 / 12) - 1;
        const currentMonthlyRate = CURRENT_ANNUAL_RATE / 12;
        // const newMonthlyRate = (1 + NEW_ANNUAL_RATE) ** (1 / 12) - 1;
        const newMonthlyRate = NEW_ANNUAL_RATE / 12;

        // Calculate current balance using Present Value formula with current CAE
        setCurrentBalance(calculatePresentValue(paidTerms, monthlyPayment, currentMonthlyRate));

        // Calculate current monthly payment
        setCurrentMonthlyPayment(calculateMonthlyPayment(currentMonthlyRate, currentBalance, paidTerms));

        // Calculate new monthly payment
        setNewMonthlyPayment(calculateMonthlyPayment(newMonthlyRate, currentBalance, newTerm));

        // Calculate the currente total payment
        setCurrentTotalPayment(currentMonthlyPayment * paidTerms);

        // Calculate the new total payment
        setNewTotalPayment(newMonthlyPayment * newTerm);
      }
    }
  }, [monthlyPayment, paidTerms, newTerm, currentMonthlyPayment, newMonthlyPayment]);

  const handleMonthlyPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseFloat(event.target.value.replace(/,/g, ''));
    if (!Number.isNaN(value)) {
      setMonthlyPayment(value);
    } else {
      setMonthlyPayment(0);
    }
  };

  const handleNewTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target.value, 10);
    if (!Number.isNaN(value)) {
      setNewTerm(value);
    } else {
      setNewTerm(0);
    }
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatPercent = (value: number) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'percent',
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    }).format(value);
  };

  return (
    <>
      <Box className={styles.header} sx={{ p: isMobile ? 2 : 3 }}>
        <Typography variant="h5" component="h1" className={styles.sectionTitle}>
          <CompareArrowsIcon sx={{ fontSize: 24, mr: 1 }} />
          Simula una compra de cartera para tus deudas actuales
        </Typography>
      </Box>
      <CardContent sx={{ p: isMobile ? 1.5 : 3 }}>
        <Grid container spacing={isMobile ? 1.5 : 3}>
          <Grid item xs={12} md={6}>
            <Box className={styles.inputContainer}>
              <Box className={styles.inputContainer}>
                <Typography className={styles.inputLabel}>¿Cuánto pagas mensualmente por tus deudas?</Typography>
                <Box className={styles.inputWrapper}>
                  <AttachMoney sx={{ color: 'text.secondary' }} />
                  <TextField
                    fullWidth
                    value={monthlyPayment.toLocaleString()}
                    onChange={handleMonthlyPaymentChange}
                    variant="outlined"
                    size={isMobile ? 'small' : 'medium'}
                    className={styles.styledTextField}
                    inputProps={{ maxLength: 10 }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={styles.inputContainer}>
              <Box className={styles.inputContainer}>
                <Typography className={styles.inputLabel}>¿Cuántas cuotas te quedan por pagar?</Typography>
                <Box className={styles.inputWrapper} sx={{ gap: 1 }}>
                  <CalendarToday sx={{ color: 'text.secondary' }} />
                  <Box sx={{ display: 'flex', flex: 1, gap: 1 }}>
                    <TextField
                      fullWidth
                      type="number"
                      value={paidTerms}
                      variant="outlined"
                      size={isMobile ? 'small' : 'medium'}
                      className={styles.styledTextField}
                      inputProps={{ readOnly: true }}
                    />
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                      <Box
                        onClick={() => setPaidTerms((prev) => Math.min(prev + 1, 60))}
                        sx={{
                          cursor: 'pointer',
                          bgcolor: 'primary.main',
                          color: 'white',
                          borderRadius: '4px',
                          width: '24px',
                          height: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'all 0.1s ease-in-out',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                          '&:active': {
                            transform: 'scale(0.9)',
                          },
                        }}
                      >
                        +
                      </Box>
                      <Box
                        onClick={() => setPaidTerms((prev) => Math.max(prev - 1, 1))}
                        sx={{
                          cursor: 'pointer',
                          bgcolor: 'primary.main',
                          color: 'white',
                          borderRadius: '4px',
                          width: '24px',
                          height: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          transition: 'all 0.1s ease-in-out',
                          '&:hover': {
                            bgcolor: 'primary.dark',
                          },
                          '&:active': {
                            transform: 'scale(0.9)',
                          },
                        }}
                      >
                        -
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.inputContainer}>
              <Box className={styles.inputContainer}>
                <Box className={styles.inputLabelContainer}>
                  <Typography className={styles.inputLabel}>
                    ¿Cuántas cuotas te gustaría pagar para renegociar tu deuda?
                  </Typography>
                </Box>
                <Box className={styles.inputWrapper}>
                  <CalendarToday sx={{ color: 'text.secondary' }} />
                  <TextField
                    fullWidth
                    type="number"
                    value={newTerm}
                    onChange={handleNewTermChange}
                    variant="outlined"
                    size={isMobile ? 'small' : 'medium'}
                    className={styles.styledTextField}
                    inputProps={{ maxLength: 2, readOnly: true }}
                  />
                </Box>
                <Slider
                  value={newTerm}
                  min={6}
                  max={60}
                  step={1}
                  onChange={(_, value) => setNewTerm(value as number)}
                  className={styles.sliderContainer}
                />
                <Box className={styles.sliderLabels}>
                  <Typography variant="caption">6 cuotas</Typography>
                  <Typography variant="caption">60 cuotas</Typography>
                </Box>
              </Box>
            </Box>
          </Grid>

          {monthlyPayment > 0 && paidTerms > 0 && (
            <Grid item xs={12}>
              <Box className={styles.resultCard}>
                <Typography variant="h6" sx={{ textAlign: 'center', mb: 3 }}>
                  Comparación de Cuotas
                </Typography>

                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography sx={{ color: 'text.secondary', mr: 1 }}>Cuota Mensual:</Typography>
                    <Typography className={styles.oldValue}>{formatCurrency(currentMonthlyPayment)}</Typography>
                    <Typography className={styles.newValue}>{formatCurrency(newMonthlyPayment)}</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography sx={{ color: 'text.secondary', mr: 1 }}>Total a pagar:</Typography>
                    <Typography className={styles.oldValue}>{formatCurrency(currentTotalPayment)}</Typography>
                    <Typography className={styles.newValue}>{formatCurrency(newTotalPayment)}</Typography>
                  </Box>

                  <Divider sx={{ width: '100%', my: 1 }} />

                  <Box className={styles.savingsHighlight}>
                    <TrendingDownIcon className={styles.savingsHighlightIcon} />
                    <Typography
                      className={styles.savingsHighlightText}
                      sx={{
                        color: newMonthlyPayment < currentMonthlyPayment ? 'success.main' : 'error.main',
                      }}
                    >
                      {newMonthlyPayment < currentMonthlyPayment
                        ? `¡Ahorra ${formatPercent(
                            (currentMonthlyPayment - newMonthlyPayment) / currentMonthlyPayment
                          )} en tu cuota mensual${
                            newTotalPayment < currentTotalPayment
                              ? ` y reduce el total a pagar en ${formatPercent(
                                  (currentTotalPayment - newTotalPayment) / currentTotalPayment
                                )}`
                              : ` pero el total a pagar aumenta en ${formatPercent(
                                  (newTotalPayment - currentTotalPayment) / currentTotalPayment
                                )}`
                          }`
                        : `Tu cuota mensual aumenta en ${formatPercent(
                            (newMonthlyPayment - currentMonthlyPayment) / currentMonthlyPayment
                          )}${
                            newTotalPayment < currentTotalPayment
                              ? ` pero reduce el total a pagar en ${formatPercent(
                                  (currentTotalPayment - newTotalPayment) / currentTotalPayment
                                )}`
                              : ` y el total a pagar aumenta en ${formatPercent(
                                  (newTotalPayment - currentTotalPayment) / currentTotalPayment
                                )}`
                          }`}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}

          <Grid item xs={12}>
            <Box className={styles.buttonContainer}>
              <Box className={styles.ButtonWrapper}>
                <PreApprovalButton
                  text="Revisa si estas pre-aprobado"
                  route={redirectUrl}
                  onPreApprovalClick={onPreApprovalClick}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

RefinanceSimulator.defaultProps = {
  onPreApprovalClick: undefined,
};

export default RefinanceSimulator;
