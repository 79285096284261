import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ContactSupport } from '@mui/icons-material';
import AdminPanelSettings from '@mui/icons-material/AdminPanelSettings';
import Dashboard from '@mui/icons-material/Dashboard';
import FactCheck from '@mui/icons-material/FactCheck';
import Logout from '@mui/icons-material/Logout';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

import {
  ProfileIcon,
  BudgetsIcon,
  SearchIcon,
  WhatsAppIcon,
  MoneyIcon,
  MenuIcon,
  ProfileCircleIcon,
} from 'assets/icons';
import logo from 'assets/images/logo.webp';
import { checkIfTokenIsValid } from 'authStorage';
import { CloseButton } from 'components/UI/Buttons';
import useAccessToken from 'hooks/useAccessToken';
import useCurrentRoute from 'hooks/useCurrentRoute';
import useIsMobile from 'hooks/useIsMobile';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';
import { posthogReset } from 'utils/posthogUtils';

export const drawerWidth = 310;

const SideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(8vh, 56px)',
  color: theme.palette.primary.main,
  backgroundColor: '#fff',
  paddingLeft: '38px',
  paddingRight: '38px',
  borderRadius: '37px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    height: '75px',
    '& span': {
      fontSize: '22px !important',
      color: 'inherit',
      fontWeight: 400,
    },
  },
  '& span': {
    fontSize: '18px !important',
    color: 'inherit',
    fontWeight: 400,
  },
  '&:hover': {
    color: '#26859C',
    '& span': {
      color: '#26859C',
    },
  },
}));

const CurrentSideBarItemButton = styled(ListItemButton)(({ theme }) => ({
  width: '238px',
  height: 'min(8vh, 56px)',
  color: '#fff',
  backgroundColor: theme.palette.primary.main,
  paddingLeft: '38px',
  paddingRight: '38px',
  borderRadius: '37px',
  transition: 'all 0.15s ease-in-out',
  [theme.breakpoints.up('xl')]: {
    height: '75px',
    '& span': {
      fontSize: '22px !important',
      color: 'inherit',
      fontWeight: 600,
    },
  },
  '& span': {
    fontSize: '18px !important',
    color: 'inherit',
    fontWeight: 600,
  },
  '&:hover': {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    '& span': {
      color: '#fff',
    },
  },
}));

interface SideBarItemInterface {
  text: string;
  Icon: React.ReactNode;
  isSelected: boolean;
  path:
    | '/'
    | '/debt'
    | '/my-plan'
    | '/budget'
    | '/education'
    | '/faq'
    | '/admin'
    | '/profile'
    | '/admin/users'
    | '/admin/metrics'
    | '/admin/bank-offer-requests'
    | '/admin/db-reader'
    | '/admin/chat'
    | '/hr'
    | '/chat'
    | '/debt-profile'
    | `/onboarding/${string}?redirectUrl=/chat?refetchFlag=1`
    | '/financial-order';
}

interface SideBarButtonInterface extends SideBarItemInterface {
  setMobileOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const SignOutButton: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    posthogReset();
    dispatch(setAccessToken({ accessToken: null }));
    navigate('/welcome');
  };

  return (
    <ListItem sx={{ px: '38px' }}>
      <SideBarItemButton onClick={handleClick} sx={{ px: 4 }}>
        <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>
          <Logout />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </SideBarItemButton>
    </ListItem>
  );
};

const SideBarButton: React.FC<SideBarButtonInterface> = ({ text, Icon, isSelected, path, setMobileOpen }) => {
  const { isMobile } = useIsMobile();
  const navigate = useNavigate();
  const handleClick = () => {
    if (isMobile) {
      setMobileOpen(false);
    }
    navigate(path);
  };

  return (
    <ListItem key={text} sx={{ px: '38px', py: '4px' }}>
      {isSelected ? (
        <CurrentSideBarItemButton onClick={handleClick} sx={{ px: 4 }}>
          <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
          <ListItemText primary={text} />
        </CurrentSideBarItemButton>
      ) : (
        <SideBarItemButton onClick={handleClick} sx={{ px: 4 }}>
          <ListItemIcon sx={{ color: 'inherit', minWidth: '0px', mr: 1 }}>{Icon}</ListItemIcon>
          <ListItemText primary={text} />
        </SideBarItemButton>
      )}
    </ListItem>
  );
};

const SideBar = () => {
  const { path } = useCurrentRoute();
  const query = new URLSearchParams(window.location.search);
  const withLayout = query.get('withLayout') === 'true';
  const isAdminRoute = path.includes('/admin');
  const navigate = useNavigate();
  const token = useAccessToken();

  const [getUser, user] = useLazyGetUserInformationQuery();

  const isAdmin = !!user?.data?.isAdmin;
  const isHRAdmin = !!user?.data?.isHRAdmin;
  const companyName = user?.data?.company?.name;

  const isBukUser = companyName === 'Buk';

  const disableOnboarding = companyName === 'Tapp';

  const [mobileOpen, setMobileOpen] = useState(false);

  useEffect(() => {
    if (checkIfTokenIsValid(token)) {
      getUser(null).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [token]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const firstSection: SideBarItemInterface[] = [
    {
      text: 'Chat',
      path: '/chat',
      Icon: <WhatsAppIcon />,
      isSelected: path.includes('/chat'),
    },
  ];
  if (!isBukUser) {
    firstSection.push({
      text: 'Orden financiero',
      path: '/financial-order',
      Icon: <BudgetsIcon />,
      isSelected: path.includes('/financial-order'),
    });
  }
  if (!disableOnboarding) {
    firstSection.push({
      text: 'Perfil financiero',
      path: '/debt-profile',
      Icon: <MoneyIcon />,
      isSelected:
        path.includes('/debt-profile') ||
        (path.includes('/bank-offers') && withLayout) ||
        path.includes('/debts-report'),
    });
  }

  const adminFirstSection: SideBarItemInterface[] = [
    {
      text: 'Inicio',
      Icon: <Dashboard />,
      path: '/admin',
      isSelected: path === '/admin',
    },
    {
      text: 'Usuarios',
      Icon: <ProfileCircleIcon />,
      path: '/admin/users',
      isSelected: path.includes('/admin/users'),
    },
    {
      text: 'Refinanciamientos',
      Icon: <MoneyIcon />,
      path: '/admin/bank-offer-requests',
      isSelected: path.includes('/admin/bank-offer-requests'),
    },
    {
      text: 'Métricas',
      path: '/admin/metrics',
      Icon: <FactCheck />,
      isSelected: path.includes('/admin/metrics'),
    },
    {
      text: 'Base de datos',
      path: '/admin/db-reader',
      Icon: <SearchIcon />,
      isSelected: path.includes('/admin/db-reader'),
    },
  ];

  const secondSection: SideBarItemInterface[] = [
    {
      text: 'Mi perfil',
      Icon: <ProfileIcon />,
      isSelected: path.includes('/profile'),
      path: '/profile',
    },
    {
      text: 'Ayuda',
      Icon: <ContactSupport />,
      isSelected: path.includes('/faq'),
      path: '/faq',
    },
  ];

  if (isAdmin) {
    secondSection.push({
      text: 'Admin',
      Icon: <AdminPanelSettings />,
      isSelected: isAdminRoute,
      path: isAdminRoute ? '/' : '/admin',
    });
  }
  if (isHRAdmin) {
    secondSection.push({
      text: 'Admin RRHH',
      Icon: <AdminPanelSettings />,
      isSelected: path.includes('/hr'),
      path: '/hr',
    });
  }

  const adminSecondSection: SideBarItemInterface[] = [
    {
      text: 'Chat',
      Icon: <WhatsAppIcon />,
      isSelected: path.includes('/admin/chat'),
      path: '/admin/chat',
    },
    {
      text: 'Salir de admin',
      Icon: <AdminPanelSettings />,
      isSelected: !isAdminRoute,
      path: isAdminRoute ? '/' : '/admin',
    },
  ];

  const drawer = (
    <div>
      <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'right', mr: 2, mt: 2 }}>
        <CloseButton aria-label="open drawer" onClick={handleDrawerToggle} color="inherit" />
      </Box>
      <Toolbar
        disableGutters={false}
        sx={{
          py: { xs: '26px', xl: 6 },
          px: '46px !important',
          height: { xs: 'min(100px, 14vh)', xl: '138px' },
          cursor: 'pointer',
        }}
        onClick={() => {
          if (isAdminRoute) {
            navigate('/admin');
          } else {
            navigate('/');
          }
        }}
      >
        <img src={logo} alt="logo" style={{ width: '80%', maxWidth: '240px' }} />
      </Toolbar>

      <List sx={{ pb: { xs: '10px', xl: '30px' } }}>
        {(isAdminRoute ? adminFirstSection : firstSection).map((section) => (
          <SideBarButton
            Icon={section.Icon}
            text={section.text}
            key={section.text}
            isSelected={section.isSelected}
            path={section.path}
            setMobileOpen={setMobileOpen}
          />
        ))}
      </List>
      <Divider sx={{ width: '75%', mx: 'auto' }} />
      <List>
        {(isAdminRoute ? adminSecondSection : secondSection).map((section) => (
          <SideBarButton
            Icon={section.Icon}
            text={section.text}
            key={section.text}
            isSelected={section.isSelected}
            path={section.path}
            setMobileOpen={setMobileOpen}
          />
        ))}
        <SignOutButton />
      </List>
    </div>
  );

  return (
    <>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 2,
          display: { xs: 'block', md: 'none' },
          position: 'fixed',
          top: '20px',
          left: `25px`,
          zIndex: '100',
        }}
      >
        <MenuIcon />
      </IconButton>
      <Box
        component="nav"
        sx={{ width: { xs: '100%', md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: '100%',
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', md: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidth,
              border: 0,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
};

export default SideBar;
