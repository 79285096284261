import priceFormat, { UFFormat } from 'services/format/priceFormat';
import { Debt } from 'services/types/debt';

export const getDebtStatus = (status: string) => {
  if (status === 'incomplete') {
    return { text: 'Datos faltantes', value: 0 };
  }
  if (status === 'onDebt' || status === 'onDue') {
    return { text: 'Deuda ingresada', value: (100 * 1) / 3 };
  }
  if (status === 'refinanceActionsRequired') {
    return { text: 'Acciones requeridas', value: 100 };
  }
  if (status === 'waitingForOptions') {
    return { text: 'Buscando alternativas', value: (100 * 2) / 3 };
  }
  if (status === 'hasNewOptions') {
    return { text: 'Nueva oferta', value: (100 * 3) / 3 };
  }
  if (status === 'offerAccepted') {
    return { text: 'Oferta aceptada', value: 100 };
  }
  return { text: 'Deuda ingresada', value: (100 * 1) / 3 };
};

export const getMonthlyPayment = (debt: Debt) => {
  if (debt.currency === 'UF') {
    return UFFormat(Number(debt.monthlyPayment));
  }
  return priceFormat.format(Number(debt.monthlyPayment));
};

const implicitTermTypes = ['tarjeta de crédito', 'linea de crédito'];
export const isImplicitTermType = (debt: Debt) => implicitTermTypes.includes(debt.debtType || '');

const longTermTypes = ['crédito hipotecario', 'crédito de educación'];
export const isLongTermType = (debt: Debt) => longTermTypes.includes(debt.debtType || '');
