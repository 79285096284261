/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import definitionsData, { Definition } from 'assets/data/definitionsData';
import { SearchIcon } from 'assets/icons';
import PaperContainer from 'components/UI/PaperContainer';

interface DefinitionsByLetter {
  [letter: string]: Definition[];
}

const definitionsByLetter: DefinitionsByLetter = definitionsData
  .sort((x, y) => (x.name < y.name ? -1 : 1))
  .reduce<DefinitionsByLetter>((acc, def) => {
    const firstLetter = def.name[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(def);
    return acc;
  }, {});

const Glossary = () => {
  const [filteredDefinitions, setFilteredDefinitions] = React.useState<DefinitionsByLetter>(definitionsByLetter);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value.toLowerCase();
    if (!searchValue) {
      setFilteredDefinitions(definitionsByLetter);
      return;
    }
    const newFiltered = Object.keys(definitionsByLetter).reduce<DefinitionsByLetter>((acc, letter) => {
      const filtered = definitionsByLetter[letter].filter((def) => def.name.toLowerCase().includes(searchValue));
      if (filtered.length) {
        acc[letter] = filtered;
      }
      return acc;
    }, {});
    setFilteredDefinitions(newFiltered);
  };
  return (
    <PaperContainer fullWidth title="Glosario 📖">
      <TextField
        type="text"
        onChange={handleSearch}
        placeholder="Buscar"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
      <Box
        mb={2}
        sx={{
          height: '500px',
          overflowY: 'auto',
        }}
      >
        {Object.keys(filteredDefinitions).map((letter) => (
          <Box key={letter}>
            <Typography variant="h3" my={2}>
              {letter}
            </Typography>
            {filteredDefinitions[letter].map((definition) => (
              <Box
                key={definition.name}
                mb={2}
                sx={{
                  borderLeft: '3px solid #E5E5E5',
                  pl: 2,
                  '&:last-child': {
                    mb: 0,
                  },
                  '&:hover': {
                    borderLeft: '3px solid',
                    borderColor: 'info.main',
                  },
                }}
              >
                <Typography fontSize="24px" lineHeight="32px" mb={1} fontWeight={500}>
                  {definition.name}
                </Typography>
                <Typography fontSize="18px" lineHeight="24px" maxWidth={500} textAlign="justify">
                  {definition.description}
                </Typography>
                {definition.link && (
                  <a href={definition.link} target="_blank" rel="noreferrer">
                    <Button variant="text">Ver más</Button>
                  </a>
                )}
              </Box>
            ))}
          </Box>
        ))}
      </Box>
    </PaperContainer>
  );
};

export default Glossary;
