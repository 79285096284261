import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { PrimaryBtn } from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { Budget, BudgetRow } from 'services/types/budget';

import BudgetForm from './components/BudgetForm';
import {
  DEFAULT_INCOME_ROWS,
  DEFAULT_HOME_ROWS,
  DEFAULT_PERSONAL_ROWS,
  DEFAULT_ENTRETE_ROWS,
  DEFAULT_FINANCE_ROWS,
  DEFAULT_CHILD_ROWS,
} from './constants';

interface EditOrCreateBudgetViewState {
  budgetData?: Budget;
}

const EditOrCreateBudgetView = () => {
  const location = useLocation();
  const state = location.state as EditOrCreateBudgetViewState | null;

  const route = useCurrentRoute();
  const navigate = useNavigate();

  let initialBudgetName = '';
  const isEdit = route.path === '/budget/edit';
  let budgetId = null;

  if (isEdit) {
    initialBudgetName = state?.budgetData?.name ?? '';
    budgetId = state?.budgetData?.id ?? null;
  } else {
    const date = new Date();
    const day = date.getDate();
    const monthNext = new Date(date.getFullYear(), date.getMonth() + 1, date.getDate());
    const monthName = (day > 25 ? monthNext : date).toLocaleString('es-ES', { month: 'long' });
    initialBudgetName = `Presupuesto de ${monthName}`;
  }

  const budgetData = state?.budgetData ?? null;
  const [budgetName, setBudgetName] = React.useState(initialBudgetName);
  const incomeRows = budgetData?.rows.filter((row) => !row.isExpense) ?? [];
  const homeRows = budgetData?.rows.filter((row) => row.category === 'Hogar') ?? [];
  const personalRows = budgetData?.rows.filter((row) => row.category === 'Personal') ?? [];
  const childRows = budgetData?.rows.filter((row) => row.category === 'Hijos') ?? [];
  const entreteRows = budgetData?.rows.filter((row) => row.category === 'Entretención') ?? [];
  const financeRows = budgetData?.rows.filter((row) => row.category === 'Financiero') ?? [];
  const goalRows = budgetData?.rows.filter((row) => row.category === 'Objetivos') ?? [];

  DEFAULT_INCOME_ROWS.forEach((row) => {
    if (!incomeRows.find((incomeRow) => incomeRow.name === row.name)) {
      incomeRows.push(row as BudgetRow);
    }
  });

  DEFAULT_HOME_ROWS.forEach((row) => {
    if (!homeRows.find((homeRow) => homeRow.name === row.name)) {
      homeRows.push(row as BudgetRow);
    }
  });

  DEFAULT_PERSONAL_ROWS.forEach((row) => {
    if (!personalRows.find((personalRow) => personalRow.name === row.name)) {
      personalRows.push(row as BudgetRow);
    }
  });

  DEFAULT_CHILD_ROWS.forEach((row) => {
    if (!childRows.find((childRow) => childRow.name === row.name)) {
      childRows.push(row as BudgetRow);
    }
  });

  DEFAULT_ENTRETE_ROWS.forEach((row) => {
    if (!entreteRows.find((entreteRow) => entreteRow.name === row.name)) {
      entreteRows.push(row as BudgetRow);
    }
  });

  DEFAULT_FINANCE_ROWS.forEach((row) => {
    if (!financeRows.find((financeRow) => financeRow.name === row.name)) {
      financeRows.push(row as BudgetRow);
    }
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBudgetName(event.target.value);
  };

  return (
    <Box>
      <SectionHeader
        text=""
        button={
          <Box
            display="flex"
            sx={{ flexDirection: { xs: 'column', lg: 'row' }, alignItems: { xs: 'flex-start', lg: 'end' } }}
          >
            <Box sx={{ pb: '4px', mr: '20px' }}>
              <PrimaryBtn onClick={() => navigate('/financial-order')}>Volver</PrimaryBtn>
            </Box>
            <TextField
              id="budget-name"
              type="text"
              label="Nombre"
              value={budgetName}
              onChange={handleChange}
              sx={{ mr: 2 }}
            />
          </Box>
        }
      />
      <BudgetForm
        inicialIncomeRows={incomeRows}
        inicialHomeRows={homeRows}
        inicialGoalRows={goalRows}
        inicialChildRows={childRows}
        inicialEntertainmentRows={entreteRows}
        inicialFamilyRows={personalRows}
        inicialFinanceRows={financeRows}
        isEdit={isEdit}
        budgetID={budgetId}
        name={budgetName}
      />
    </Box>
  );
};

export default EditOrCreateBudgetView;
