import React, { FC } from 'react';

import { Box } from '@mui/material';

import styles from './ChatWrapper.module.scss';

interface Props {
  children: JSX.Element;
}

const ChatWrapper: FC<Props> = ({ children }: Props) => {
  return (
    <Box className={styles.Link}>
      <a
        href="https://wa.me/%2B56948829975?text=Hola%21+Me+gustaría+saber+más+sobre+Planer"
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    </Box>
  );
};

export default ChatWrapper;
