/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import priceFormat from 'services/format/priceFormat';

import type { BudgetRowDraft } from '../../../services/types/budget';

interface Props {
  budgetRows: BudgetRowDraft[];
}

const IncomeTable: FC<Props> = ({ budgetRows }) => {
  if (budgetRows.length === 0) {
    return (
      <Typography fontSize="18px" fontWeight={500}>
        No tienes ingresos en este presupuesto
      </Typography>
    );
  }
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table aria-label="Tabla Ingreso">
        <TableHead>
          <TableRow>
            <TableCell width="50%">Nombre</TableCell>
            <TableCell>Categoría</TableCell>
            <TableCell align="right">Monto ($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgetRows.map((row, index) => (
            <TableRow key={`${index + 1}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell align="right">{priceFormat.format(Number(row.amount))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ExpenseTable: FC<Props> = ({ budgetRows }) => {
  if (budgetRows.length === 0) {
    return (
      <Typography fontSize="18px" fontWeight={500}>
        No tienes gastos en esta sección
      </Typography>
    );
  }
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table aria-label="Tabla Ingreso">
        <TableHead>
          <TableRow>
            <TableCell width="50%">Nombre</TableCell>
            <TableCell>Subcategoría</TableCell>
            <TableCell align="right">Monto ($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgetRows
            .filter((row) => row.isExpense)
            .map((row, index) => (
              <TableRow key={`${index + 1}`}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.subcategory}</TableCell>
                <TableCell align="right">{priceFormat.format(Number(row.amount))}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const ObjectiveTable: FC<Props> = ({ budgetRows }) => {
  const navigate = useNavigate();

  if (budgetRows.length === 0) {
    return (
      <Typography fontSize="18px" fontWeight={500}>
        No tienes objetivo para este presupuesto
        <Button onClick={() => navigate('/my-plan')} variant="outlined" color="secondary" sx={{ ml: 2 }}>
          Crear objetivos
        </Button>
      </Typography>
    );
  }
  return (
    <TableContainer component={Paper} sx={{ borderRadius: 2 }}>
      <Table aria-label="Tabla Ingreso">
        <TableHead>
          <TableRow>
            <TableCell width="50%">Nombre</TableCell>
            <TableCell>Categoría</TableCell>
            <TableCell align="right">Monto ($)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {budgetRows.map((row, index) => (
            <TableRow key={`${index + 1}`}>
              <TableCell>{row.name}</TableCell>
              <TableCell>{row.category}</TableCell>
              <TableCell align="right">{priceFormat.format(Number(row.amount))}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export { IncomeTable, ExpenseTable, ObjectiveTable };
