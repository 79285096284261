import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Timelapse } from '@mui/icons-material';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { Box, Typography, Card, CardContent, Alert, Stack, CircularProgress } from '@mui/material';

import { BlackBtn } from 'components/UI/Buttons';
import { setAccessToken } from 'redux/slices/accessToken.slice';
import { useGetInternationalBankOfferQuery } from 'services/api/bankOffer.api';
import { Origin } from 'services/types/origin';

import ModalWithHeader from './ModalWithHeader';

interface Props {
  origin: Origin;
}

const BukExecutiveContact: FC<Props> = (props: Props) => {
  const { origin } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentOffer, setCurrentOffer] = useState<{
    bank: string;
    freeAvailabilityAmount: number;
  } | null>(null);

  const { data: bankOffer, isLoading } = useGetInternationalBankOfferQuery(null);

  const handleLogout = () => {
    dispatch(setAccessToken({ accessToken: null }));
    navigate('/buk/dashboard');
  };

  useEffect(() => {
    if (bankOffer) {
      setCurrentOffer({
        bank: bankOffer.bank,
        freeAvailabilityAmount: bankOffer.freeAvailabilityAmount || 0,
      });
    }
  }, [bankOffer]);

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      );
    }

    if (currentOffer) {
      return (
        <Box>
          <Alert
            icon={<CheckCircleIcon fontSize="large" />}
            severity="success"
            sx={{ mb: 3, '& .MuiAlert-message': { width: '100%' } }}
          >
            <Typography variant="h6" sx={{ mb: 1 }}>
              ¡Buenas noticias! Tienes una oferta preaprobada disponible
            </Typography>
          </Alert>
          <Card sx={{ mb: 3, backgroundColor: '#f8f9fa' }}>
            <CardContent>
              <Stack spacing={2}>
                <Box display="flex" alignItems="center" gap={2}>
                  <AccountBalanceIcon color="primary" />
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Banco
                    </Typography>
                    <Typography variant="h6">{currentOffer.bank}</Typography>
                  </Box>
                </Box>

                <Box display="flex" alignItems="center" gap={2}>
                  <MonetizationOnIcon color="primary" />
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Monto disponible
                    </Typography>
                    <Typography variant="h6">
                      ${new Intl.NumberFormat('es-CL').format(currentOffer.freeAvailabilityAmount || 0)}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </CardContent>
          </Card>

          <Alert severity="info" sx={{ mb: 2, backgroundColor: '#f8f9fa' }}>
            Un ejecutivo te contactará para brindarte más información.
          </Alert>
        </Box>
      );
    }

    return (
      <Box>
        <Card sx={{ mb: 3, backgroundColor: '#f8f9fa' }}>
          <CardContent>
            <Stack spacing={3} alignItems="center" textAlign="center">
              <Typography variant="h6" color="text.secondary">
                ¡Estamos buscando ofertas para ti!
              </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <Timelapse color="success" />
                <Typography variant="body1">
                  Te contactaremos en los próximos días para brindarte más información.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" gap={1}>
                <WhatsAppIcon color="success" />
                <Typography variant="body1">Puedes contactarnos a través de Whatsapp si te quedan dudas.</Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    );
  };

  return (
    <ModalWithHeader origin={origin} title="">
      <Box sx={{ p: 3 }}>{renderContent()}</Box>
      <Box display="flex" justifyContent="center" alignItems="center" py={3}>
        <BlackBtn onClick={handleLogout}>Salir</BlackBtn>
      </Box>
    </ModalWithHeader>
  );
};

export default BukExecutiveContact;
