/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { institutionTypeList } from 'assets/data/institutionTypeList';
import { DebtDraft } from 'services/types/debt';

interface Props {
  debtDraft: DebtDraft;
  setDebtDraft: (debtDraft: DebtDraft) => void;
  setActiveStep: (step: number) => void;
}

const NewDebtStepTwo: FC<Props> = ({ debtDraft, setDebtDraft, setActiveStep }) => {
  const MAX_DISPLAY_NUMBER = 5;

  const getInstitutionList = (type: string) => {
    if (institutionTypeList) {
      const selectedType = institutionTypeList.find((item) => item.name === type);
      return selectedType ? selectedType.institutions.slice(0, MAX_DISPLAY_NUMBER) : [];
    }
    return [];
  };

  const getRemainingInstitutionList = (type: string) => {
    if (institutionTypeList) {
      const selectedType = institutionTypeList.find((item) => item.name === type);
      return selectedType ? selectedType.institutions.slice(MAX_DISPLAY_NUMBER).map((ins) => ins.name) : [];
    }
    return [];
  };
  return (
    <Grid container>
      {getInstitutionList(debtDraft.institutionType || '').map((institution) => (
        <Grid item xs={12} key={institution.name} sx={{ minHeight: '77px' }} p={1}>
          <Button
            variant="outlined"
            onClick={() => {
              setDebtDraft({ ...debtDraft, institutionName: institution.name });
              setActiveStep(2);
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar>
                {institution.logo ? (
                  <img
                    src={institution.logo}
                    style={{
                      borderRadius: '25px',
                      width: '45px',
                      height: '45px',
                    }}
                    alt="company logo"
                  />
                ) : null}
              </Avatar>
              <Typography variant="body2" color="#3E4545" fontSize={{ xs: '14px', lg: '18px' }} mx={2}>
                {institution.name}
              </Typography>
            </Box>
          </Button>
        </Grid>
      ))}
      {getInstitutionList(debtDraft.institutionType || '').length >= MAX_DISPLAY_NUMBER ? (
        <Grid item xs={12}>
          <Select
            type="select"
            label="Otra institución"
            value={
              getRemainingInstitutionList(debtDraft.institutionType || '').includes(debtDraft.institutionName)
                ? debtDraft.institutionName
                : ''
            }
            onChange={(e: { target: { value: string } }) => {
              setActiveStep(2);
              setDebtDraft({ ...debtDraft, institutionName: e.target.value });
            }}
          >
            {getRemainingInstitutionList(debtDraft.institutionType || '').map((element) => (
              <MenuItem value={element} key={element}>
                <Typography variant="body2" color="primary">
                  {element}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default NewDebtStepTwo;
