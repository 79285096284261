import React from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { SupportIcon, EmailIcon, ChatIcon } from 'assets/icons';
import PaperContainer from 'components/UI/PaperContainer';
import ChatWrapper from 'components/common/ChatWrapper';
import SectionHeader from 'components/layout/SectionHeader';
import { useGetUserInformationQuery } from 'services/api/user.api';

import styles from './FAQ.module.scss';

const FAQ = () => {
  const { data: userInfo } = useGetUserInformationQuery(null);
  return (
    <>
      <SectionHeader text="Ayuda" button={null} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <PaperContainer fullWidth fullHeight title="Preguntas Frecuentes">
            <Box>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Qué hace Relif?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Relif es una plataforma para entender y mejorar tus créditos 💳. ¿Qué significa eso? Junto a ti,
                    revisamos tus créditos y buscamos formas de que estés mejor, ya sea refinanciando o renegociando tus
                    deudas. Todo lo hacemos a través de tecnología 💻, para que esto sea rápido y seguro. Con Relif
                    podrás:
                    <ul>
                      <li>Consolidar o refinanciar tus créditos para bajar tu carga financiera</li>
                      <li>
                        Si estás apretado/a hoy, puedes postergar pagos repactando a condiciones mejores que las que te
                        darán en la sucursal
                      </li>
                      <li>Salir de DICOM o evitar caer en él</li>
                      <li>Negociar créditos morosos o castigados</li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Por qué mi empleador lo está ofreciendo?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Tu empleador quiere que los colaboradores estén felices 😀, y una buena salud financiera es muy
                    importante para esto. Con el aumento en el costo de vida y en el costo de los créditos que hemos
                    visto en el último tiempo, tu empleador ha querido buscar formas de ayudar a los colaboradores, y
                    ahí es donde entramos nosotros. 💪
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Si no tengo créditos, la plataforma me sirve?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    ¡Claro! Además de ayudar con el endeudamiento, la plataforma tiene otras funcionalidades como:
                    <ul>
                      <li>💰 Herramientas para ordenar tus finanzas personales (presupuestos, objetivos, etc.)</li>
                      <li>📚 Educación financiera (artículos, charlas, consejos, etc.)</li>
                      <li>
                        🔜 ¡Y próximamente mucho más! Estamos construyendo la plataforma donde podrás encontrar todo lo
                        necesario para tener tus finanzas sanas y controladas. Escríbenos si quieres sugerir ideas.
                      </li>
                    </ul>
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Mi empleador tiene acceso a mi información personal?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    ¡No! Actuamos de manera independiente a la empresa y no compartimos información personal con ellos
                    🤐. A esto le llamamos la &ldquo;muralla china&rdquo; entre empleador y usuarios.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Tiene algún costo para mí?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>¡No! Relif no le cobra por ninguno de sus servicios a los colaboradores.🙌</Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Mi empleador revisó la seguridad de la plataforma?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    ¡Sí! Llevamos semanas trabajando con la empresa, donde ellos han revisado 🔍 todos los estándares de
                    seguridad con los que contamos.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>¿Pueden hacer algo sin mi autorización?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    ¡No! Somos muy cuidadosos con la información personal de nuestros usuarios 🔒. Por esto, les pedimos
                    que firmen un poder simple en caso que quieran que los ayudemos.
                  </Typography>
                </AccordionDetails>
              </Accordion>

              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    Quiero refinanciar un crédito en la plataforma y me piden firmar un poder con firma digital. ¿Para
                    qué es esto?
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Lo que pedimos firmar es un poder simple, que nos permite hacer tres cosas:
                    <ul>
                      <li>Cotizar nuevos créditos</li>
                      <li>Solicitar certificados de créditos actuales</li>
                      <li>Solicitar al empleador tus liquidaciones de sueldo.</li>
                    </ul>
                    Con esas tres cosas, nosotros podemos ir a buscarte las mejores condiciones crediticias en el
                    mercado y así mejorar tu situación financiera. El poder NO permite tomar decisiones por ti, por lo
                    que tú siempre tienes la última palabra.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Box>
          </PaperContainer>
        </Grid>
        <Grid item xs={12} lg={4}>
          <PaperContainer fullWidth title="¿Sigues con dudas?">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <ChatWrapper>
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ backgroundColor: 'info.main', width: '65px', height: '65px' }}>
                      <ChatIcon sx={{ height: '60%', width: '60%' }} />
                    </Avatar>
                    <Typography variant="h4" ml={3} fontSize={{ xs: '19px', lg: '22px' }} lineHeight="35px">
                      <Box sx={{ '&:hover': { color: 'info.main' } }} className={styles.ContactLinks}>
                        Háblanos
                      </Box>{' '}
                      a nuestro chat
                    </Typography>
                  </Box>
                </ChatWrapper>
              </Grid>

              <Grid item xs={12}>
                <a
                  href="mailto:contacto@relif.cl"
                  className={styles.ContactLinksWrapper}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Box display="flex" alignItems="center">
                    <Avatar sx={{ backgroundColor: 'info.main', width: '65px', height: '65px' }}>
                      <EmailIcon sx={{ height: '60%', width: '60%' }} />
                    </Avatar>
                    <Typography variant="h4" ml={3} fontSize={{ xs: '19px', lg: '22px' }} lineHeight="35px">
                      <Box sx={{ '&:hover': { color: 'info.main' } }} className={styles.ContactLinks}>
                        Escríbenos
                      </Box>{' '}
                      a nuestro mail: contacto@relif.cl
                    </Typography>
                  </Box>
                </a>
              </Grid>

              {userInfo?.company?.subscriptionType !== 'free' && (
                <Grid item xs={12}>
                  <a href="/book-meeting" className={styles.ContactLinksWrapper} target="_blank" rel="noreferrer">
                    <Box display="flex" alignItems="center">
                      <Avatar sx={{ backgroundColor: 'info.main', width: '65px', height: '65px' }}>
                        <SupportIcon sx={{ height: '60%', width: '60%' }} />
                      </Avatar>
                      <Typography variant="h4" ml={3} fontSize={{ xs: '19px', lg: '22px' }} lineHeight="35px">
                        <Box sx={{ '&:hover': { color: 'info.main' } }} className={styles.ContactLinks}>
                          AGENDA UNA HORA
                        </Box>{' '}
                        con nuestro equipo (sí, nosotros mismos te ayudamos)
                      </Typography>
                    </Box>
                  </a>
                </Grid>
              )}
            </Grid>
          </PaperContainer>
        </Grid>
      </Grid>
    </>
  );
};

export default FAQ;
