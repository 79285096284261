import React, { FC, useEffect } from 'react';

import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import Typography from '@mui/material/Typography';

import logo from 'assets/images/logo.webp';
import { CustomConnector } from 'components/UI/CustomStepper';
import ClaveUnicaModal from 'components/common/ClaveUnicaModal';
import useDebtFlow from 'hooks/useDebtFlow';
import { useDeleteOldCMFDebtsMutation, useListDebtsQuery, useRefinanceDebtsMutation } from 'services/api/debt.api';
import { useUpdateCamaraComercioMutation, useGetCamaraComercioLast30DaysQuery } from 'services/api/floid.api';
import { useGetUserDebtProfileQuery, useGetUserQuery } from 'services/api/user.api';

import styles from '../../Debts.module.scss';
import CategorizeCMFData from './CategorizeCMFData';

interface Props {
  isCMFUpdate?: boolean;
  onSuccess: () => void;
}

const ClaveUnicaDebtForm: FC<Props> = ({ isCMFUpdate, onSuccess }) => {
  const steps = ['Clave Única', 'Perfilamiento', 'Categorización'];

  const { data: user } = useGetUserQuery(null);
  const { refetch: refetchDebtData } = useListDebtsQuery(null);
  const { refetch: refetchDebtProfileData } = useGetUserDebtProfileQuery(null);
  const debtFlow = useDebtFlow();
  const [deleteOldCMFDebts] = useDeleteOldCMFDebtsMutation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [updateCamaraComercio] = useUpdateCamaraComercioMutation();
  const [refinanceDebts] = useRefinanceDebtsMutation();
  const { data: has30DaysCamaraComercioRequest } = useGetCamaraComercioLast30DaysQuery(null);

  useEffect(() => {
    // go back to clave unica step if user is updating CMF data and has no recent CMF response
    if (isCMFUpdate && activeStep === 0 && debtFlow.hasCMFResponseInLast30Days) {
      setActiveStep(0);
    }
  }, [debtFlow?.CMFResponse, isCMFUpdate]);

  const handleSaveClaveUnica = () => {
    setActiveStep(1);
    if (isCMFUpdate) {
      if (!has30DaysCamaraComercioRequest) {
        updateCamaraComercio(null).catch((e) => console.error(e)); // eslint-disable-line no-console
      }
    } else {
      refetchDebtProfileData().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  };

  const saveCategorizeDebtData = async () => {
    await deleteOldCMFDebts(null);
    const data = await refetchDebtData();
    if (data.data) {
      await refinanceDebts({
        debtIds: data.data.filter((debt) => !debt.refinance).map((debt) => debt.id) || [],
      });
    }
    onSuccess();
  };

  const handleSaveCategorizeCMFData = () => {
    saveCategorizeDebtData().catch((e) => console.error(e)); // eslint-disable-line no-console
  };

  return (
    <Box className={styles.ModalContainer}>
      <Box className={styles.Modal}>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            <img src={logo} alt="logo" style={{ width: '150px' }} />
            <Box width={64} />
          </Box>
          <Typography variant="h5" fontSize="22px" textAlign="center">
            Agregar Deudas
          </Typography>
          {activeStep !== -1 && (
            <Stepper sx={{ ml: '-8px', py: '15px' }} connector={<CustomConnector />} activeStep={activeStep}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel sx={{ fontWeight: 700 }} />
                </Step>
              ))}
            </Stepper>
          )}
          <Box className={styles.ContentBox}>
            <Box display="flex" justifyContent="center" alignContent="center">
              {activeStep === 0 && user?.rut && (
                <ClaveUnicaModal rut={user.rut} onSave={handleSaveClaveUnica} isCMFUpdate={isCMFUpdate || false} />
              )}
              {activeStep === 1 && <CategorizeCMFData onSave={handleSaveCategorizeCMFData} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

ClaveUnicaDebtForm.defaultProps = {
  isCMFUpdate: false,
};

export default ClaveUnicaDebtForm;
