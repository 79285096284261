import React from 'react';

import { useTheme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { PrimaryBtn } from 'components/UI/Buttons';

const FINANCIAL_INSTITUTION_LOGOS = [
  {
    imageUrl: '/landing_page/img/companies/banco-internacional.webp',
    name: 'Banco Internacional',
    href: 'https://www.internacional.cl/',
  },
  {
    imageUrl: '/landing_page/img/companies/bci.webp',
    name: 'Banco de BCI',
    href: 'https://www.bancobci.cl/',
  },
  {
    imageUrl: '/landing_page/img/companies/banco-de-chile.webp',
    name: 'Banco de Chile',
    href: 'https://www.bancodechile.cl/',
  },
  {
    imageUrl: '/landing_page/img/companies/caja-los-andes.webp',
    name: 'Caja los Andes',
    href: 'https://www.cajalosandes.cl/',
  },
  {
    imageUrl: '/landing_page/img/companies/banco-estado.webp',
    name: 'Banco Estado',
    href: 'https://www.bancoestado.cl/',
  },
];

interface RefiRobotProps {
  handleOpenFinancialAdvisorAuthModal: (isExistingUser: boolean) => void;
}

const RefiRobot = ({ handleOpenFinancialAdvisorAuthModal }: RefiRobotProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      <Grid
        container
        justifyContent="center"
        spacing={{ xs: 0, lg: 8 }}
        pb={0}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item alignSelf="center" sx={{ pt: { xs: 2 } }}>
          <Box display="flex" flexDirection="row" gap={{ xs: 2, md: 4 }} justifyContent="center" my={4} px={2}>
            {FINANCIAL_INSTITUTION_LOGOS.map((logo) => (
              <Box
                key={logo.name}
                onClick={() => window.open(logo.href, '_blank')}
                sx={{ cursor: 'pointer' }}
                borderRadius={5}
                overflow="hidden"
                height={isMobile ? '50px' : '100px'}
              >
                <img
                  src={logo.imageUrl}
                  alt={logo.name}
                  style={{
                    height: isMobile ? '50px' : '100px',
                    objectFit: 'contain',
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" my={2}>
            <PrimaryBtn
              variant="outlined"
              sx={{
                color: 'black',
                backgroundColor: '#F9D00A',
                borderColor: '#F9D00A',
                '&:hover': {
                  backgroundColor: '#DCB705',
                  borderColor: '#DCB705',
                  color: 'black',
                },
                width: '200px',
              }}
              onClick={() => {
                handleOpenFinancialAdvisorAuthModal(false);
              }}
            >
              Registrarse
            </PrimaryBtn>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default RefiRobot;
