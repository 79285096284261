import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Typography from '@mui/material/Typography';

import { BackIcon, CloseIcon } from 'assets/icons';

interface Props {
  open: boolean;
  onClose: () => void;
  title: string;
  activeStep: number | null;
  setActiveStep: ((value: number) => void) | null;
}

const SideDrawer: FC<Props> = ({ open, onClose, title, children, activeStep, setActiveStep }) => {
  return (
    <SwipeableDrawer
      open={open}
      onOpen={() => {}}
      anchor="right"
      onClose={onClose}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
      sx={{
        '& .MuiDrawer-paper': {
          width: {
            xs: '95vw',
            lg: '35vw',
          },
          boxSizing: 'border-box',
          p: 2,
          borderRadius: '20px 0 0 20px',
        },
      }}
      BackdropProps={{ style: { opacity: 0.1 } }}
    >
      <Box>
        <Grid container justifyContent="space-between" alignItems="center">
          {activeStep !== null && setActiveStep !== null && (
            <Grid item xs={4}>
              <Button
                variant="text"
                onClick={() => activeStep !== 0 && setActiveStep(activeStep - 1)}
                disabled={activeStep === 0}
                startIcon={<BackIcon />}
              >
                Atrás
              </Button>
            </Grid>
          )}
          <Grid item xs={4} textAlign="center">
            <Typography variant="h4" sx={{ display: { xs: 'none', sm: 'block' } }}>
              {title}
            </Typography>
          </Grid>
          <Grid item xs={4} display="flex" justifyContent="end">
            <Button variant="text" onClick={onClose} startIcon={<CloseIcon />}>
              Cerrar
            </Button>
          </Grid>
        </Grid>
        <Typography variant="h4" sx={{ display: { xs: 'block', sm: 'none' }, textAlign: 'center' }}>
          {title}
        </Typography>
        <Box sx={{ overflowY: 'auto' }}>{children}</Box>
      </Box>
    </SwipeableDrawer>
  );
};

export default SideDrawer;
