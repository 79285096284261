import React, { useState } from 'react';

import Box from '@mui/material/Box';

import FinancialAdvisorAuthModal from 'components/common/FinancialAdvisorAuthModal';

import Footer from '../components/Footer';
import Navigation from '../components/Navigation';
import WhoWeAre from '../components/WhoWeAre';

const RELIF_FINANCIAL_ADVISOR_COMPANY_ID = 59;
const SOURCE = 'B2C-LANDING-PAGE';

const AboutUsPage = () => {
  const [openFinancialAdvisorAuthModal, setOpenFinancialAdvisorAuthModal] = useState(false);
  const [isExistingUser, setIsExistingUser] = useState(false);

  const handleOpenFinancialAdvisorAuthModal = (defaultIsExistingUser: boolean) => {
    setIsExistingUser(defaultIsExistingUser);
    setOpenFinancialAdvisorAuthModal(true);
  };

  return (
    <Box
      sx={{ backgroundColor: '#FBFCFE', zoom: '100%' }}
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <FinancialAdvisorAuthModal
        open={openFinancialAdvisorAuthModal}
        onClose={() => setOpenFinancialAdvisorAuthModal(false)}
        companyId={RELIF_FINANCIAL_ADVISOR_COMPANY_ID}
        defaultIsExistingUser={isExistingUser}
        source={SOURCE}
      />
      <Navigation handleOpenFinancialAdvisorAuthModal={handleOpenFinancialAdvisorAuthModal} />
      <WhoWeAre />
      <Footer />
    </Box>
  );
};

export default AboutUsPage;
