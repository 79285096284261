import React, { useEffect, useState } from 'react';

import { AttachFile, Send } from '@mui/icons-material';
import { Box, IconButton, TextField } from '@mui/material';

import Conversation from 'components/common/Conversation';
import LoadingTooltip from 'components/common/LoadingTooltip';
import useIsMobile from 'hooks/useIsMobile';
import useQuery from 'hooks/useQuery';
import Pusher from 'pusher-js';
import {
  useCreateChatMessageMutation,
  useGetChatUserMessagesQuery,
  useGetUserChatIdentifierQuery,
  useSendFileMessageMutation,
} from 'services/api/chat.api';
import { useListDebtsQuery } from 'services/api/debt.api';
import { useGetCMFRequestLast30DaysQuery } from 'services/api/floid.api';
import { captureUserEvent } from 'utils/posthogUtils';

import ScannerBanner from './components/ScannerBanner';

const PUSHER_APP_KEY = import.meta.env.VITE_PUSHER_APP_KEY || '';

const Chat = () => {
  const query = useQuery();
  const refetchFlagParam = query.get('refetchFlag');
  const firstMessage = query.get('firstMessage');

  const [messageToSend, setMessageToSend] = useState(firstMessage || '');
  const [createChatMessage] = useCreateChatMessageMutation();

  const { data: userChatIdentifier } = useGetUserChatIdentifierQuery(null);

  const { data: messages, refetch } = useGetChatUserMessagesQuery(null);
  const [refetchFlag, setRefetchFlag] = useState<number>(1);

  const { refetch: refetchDebtData } = useListDebtsQuery(null);
  const { isMobile } = useIsMobile();

  const isLastMessageProcessing = Boolean(
    messages &&
      messages.length > 0 &&
      messages[messages.length - 1].text === 'Dame un minuto mientras reviso el documento 📄 🔍 🕒'
  );

  const { refetch: refetchGetCMFRequestLast30Days } = useGetCMFRequestLast30DaysQuery(null);
  const handleSendMessage = () => {
    if (!userChatIdentifier || !messageToSend) {
      return;
    }
    captureUserEvent('ValuableAction: Send chat message', {
      messageLength: messageToSend.length,
    });
    createChatMessage({ message: messageToSend })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
        setMessageToSend('');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending demo chat message', error);
      });
  };

  const [sendFileMessage] = useSendFileMessageMutation();

  const handleSendFile = (file: File) => {
    console.log('Sending file', file); // eslint-disable-line no-console
    sendFileMessage({ file })
      .then(() => {
        setRefetchFlag(refetchFlag + 1);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error sending file message', error);
      });
  };

  useEffect(() => {
    refetch().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching messages');
    });
  }, [refetchFlag]);

  useEffect(() => {
    if (refetchFlagParam) {
      setRefetchFlag((prev) => prev + 1);
    }
  }, [refetchFlagParam]);

  useEffect(() => {
    const pusher = new Pusher(PUSHER_APP_KEY, { cluster: 'sa1' });

    const channel = pusher.subscribe(`chat-${userChatIdentifier}`);
    channel.bind('new-message', (data: object) => {
      setRefetchFlag((prev) => prev + 1);
      console.log('New message', JSON.stringify(data)); // eslint-disable-line no-console
    });
    return () => {
      channel.unbind_all();
      pusher.unsubscribe(`chat-${userChatIdentifier}`);
      pusher.disconnect();
    };
  }, [userChatIdentifier, setRefetchFlag]);

  useEffect(() => {
    if (firstMessage && messageToSend === firstMessage) {
      handleSendMessage();
    }
  }, [firstMessage, messageToSend]);

  // send message on enter
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        event.preventDefault();
        handleSendMessage();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSendMessage]);

  useEffect(() => {
    refetchDebtData().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching debt data');
    });
    refetchGetCMFRequestLast30Days().catch(() => {
      // eslint-disable-next-line no-console
      console.error('Error refetching CMF request last 30 days');
    });
  }, []);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          height: !isMobile ? 'calc(100% - 100px)' : 'calc(100% - 100px)',
          paddingBottom: isMobile ? '60px' : '0',
          alignItems: 'center',
          width: '100%',
          overflow: 'hidden',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
          }}
          sx={{
            md: {
              maxWidth: '60%',
            },
          }}
        >
          <Box
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              overflowX: 'hidden',
              paddingBottom: '90px',
            }}
          >
            <ScannerBanner alignment="flex-end" />
            <Conversation
              conversation={messages || []}
              scheduledMessages={[]}
              isClientView
              refreshConversation={() => {
                refetch().catch(console.error); // eslint-disable-line no-console
              }}
            />
          </Box>
          <Box
            p={2}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            id="chat-input"
            sx={{
              backgroundColor: 'white',
              zIndex: 1,
              position: 'fixed',
              bottom: isMobile ? 50 : 0,
              width: isMobile ? '100%' : '80%',
            }}
          >
            <Box display="flex" flexDirection="row" justifyContent="space-between" width="100%">
              <LoadingTooltip isLoading={isLastMessageProcessing} title="Analizando documento...">
                <IconButton
                  sx={{
                    height: '3.5rem',
                  }}
                  component="label"
                  disabled={isLastMessageProcessing}
                >
                  <AttachFile
                    sx={{
                      fontSize: '1.5rem',
                      color: isLastMessageProcessing ? 'grey.400' : 'inherit',
                    }}
                  />
                  {/* just allow png, jpg and pdf */}
                  <input
                    type="file"
                    accept="image/*,.pdf"
                    hidden
                    disabled={isLastMessageProcessing}
                    onChange={(e) => e.target.files && handleSendFile(e.target.files[0])}
                  />
                </IconButton>
              </LoadingTooltip>
              <TextField
                id="outlined-basic-email"
                label="Mensaje"
                fullWidth
                multiline
                disabled={isLastMessageProcessing}
                sx={{
                  width: '100%',
                  backgroundColor: '#fff',
                  borderRadius: '40px',
                  opacity: isLastMessageProcessing ? 0.7 : 1,
                  mr: 1,
                  '& .MuiOutlinedInput-root': { borderRadius: '40px' },
                }}
                value={messageToSend}
                onChange={(e) => setMessageToSend(e.target.value)}
              />
              <IconButton
                sx={{
                  height: '3.5rem',
                }}
                onClick={handleSendMessage}
                disabled={isLastMessageProcessing}
              >
                <Send
                  sx={{
                    fontSize: '1.5rem',
                    color: isLastMessageProcessing ? 'grey.400' : 'inherit',
                  }}
                />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Chat;
