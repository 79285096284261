import React, { FC, useEffect } from 'react';

import Box from '@mui/material/Box';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { BigCheckWithText, BigCrossWithText, CheckWithText, CrossWithText } from 'components/common/IconsWithText';
import { useListDebtsQuery, useRefinanceDebtsMutation } from 'services/api/debt.api';
import { BankOffer } from 'services/types/bankOffers';
import { Origin } from 'services/types/origin';

import styles from '../UniqueDebtFlow.module.scss';
import ModalWithHeader from './ModalWithHeader';

interface Props {
  onRedirectClick: () => void;
  onSuccess: () => void;
  origin: Origin;
  isFromScanner?: boolean;
  setInstantBankOffer?: (bankOffer: BankOffer | null) => void;
}

const RedirectToDebtsModal: FC<Props> = (props: Props) => {
  const { onRedirectClick, onSuccess, origin, isFromScanner, setInstantBankOffer } = props;
  const [refinanceDebts, { data: refinanceDebtsData, isLoading: isRefinanceDebtsLoading }] =
    useRefinanceDebtsMutation();
  const { refetch: refetchDebtData } = useListDebtsQuery(null);

  const handleRefinanceDebts = async () => {
    if (isFromScanner) {
      return null;
    }
    const data = await refetchDebtData();
    if (data.data) {
      const result = await refinanceDebts({
        debtIds: data.data.filter((debt) => !debt.refinance).map((debt) => debt.id) || [],
      });
      return result;
    }
    return null;
  };

  const handleContinue = async () => {
    try {
      const result = await handleRefinanceDebts();
      if (isFromScanner) {
        const currentUrl = new URL(window.location.href);
        window.location.href = currentUrl.toString();
      } else if (result && 'data' in result && !result.data?.bankOffer) {
        if (setInstantBankOffer) {
          setInstantBankOffer(null);
        }
        onRedirectClick();
      } else if (result && 'data' in result && result.data?.bankOffer) {
        onSuccess?.();
      }
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
    }
  };

  useEffect(() => {
    if (refinanceDebtsData?.bankOffer && setInstantBankOffer) {
      setInstantBankOffer(refinanceDebtsData.bankOffer);
    }
  }, [refinanceDebtsData]);

  return (
    <ModalWithHeader origin={origin} title="¿Qué haremos?">
      {isRefinanceDebtsLoading && <LoadingOverlay visible />}
      <Box className={styles.ContentBox}>
        <Box mt={2}>
          <BigCheckWithText text="Planer puede:" />
          <CheckWithText text="Negociar con instituciones financieras" />
          <CheckWithText text="Enviarte información con contenido necesario" />
          <CheckWithText text="Acceder a tu Boletín Comercial" />
          <CheckWithText text="Acceder a tu informe de deudas de la CMF" />
        </Box>
        <Box mt={2}>
          <BigCrossWithText text="Planer no puede:" />
          <CrossWithText text="Entregar información bancaria sin tu consentimiento" />
          <CrossWithText text="Prestar plata" />
          <CrossWithText text="Pedir créditos a tu nombre" />
          <CrossWithText text="Hacer cambios a tus créditos actuales" />
        </Box>
      </Box>
      <Box display="grid" justifyContent="center" alignItems="center" py={5}>
        <PrimaryBtn
          type="submit"
          onClick={() => {
            handleContinue().catch((e) => console.error(e)); // eslint-disable-line no-console
          }}
        >
          Continuar
        </PrimaryBtn>
      </Box>
    </ModalWithHeader>
  );
};

RedirectToDebtsModal.defaultProps = {
  isFromScanner: false,
  setInstantBankOffer: undefined,
};

export default RedirectToDebtsModal;
