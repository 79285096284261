import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { checkIfTokenIsValid } from 'authStorage';
import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import useQuery from 'hooks/useQuery';
import { useLazyListDebtsQuery, useRefinanceDebtsMutation } from 'services/api/debt.api';
import { useLazyGetIsUserValidatedQuery } from 'services/api/firmaYa.api';
import { useLazyGetLast30DaysCMFRequestQuery, useLazyGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';
import { dateFormat } from 'services/format/dateFormat';
import { BankOffer } from 'services/types/bankOffers';
import { Origin } from 'services/types/origin';
import { captureUserEvent } from 'utils/posthogUtils';

import BukExecutiveContact from './components/BukExecutiveContact';
import ClaveUnicaDebtForm from './components/ClaveUnicaDebtForm';
import RedirectToDebtsModal from './components/RedirectToDebtsModal';
import SetRutPhoneAndEmail from './components/SetRutPhoneAndEmail';

const UniqueDebtFlow = () => {
  const BUK_COMPANY_ID = 35;
  const path = window.location.href;
  let origin: Origin = 'UniqueFlow';
  if (path.includes('banco-chile')) {
    origin = 'Banco de Chile';
  } else if (path.includes('caja-los-andes') || path.includes('caja%20los%20andes%20')) {
    origin = 'Caja Los Andes';
  }
  const debtFlow = useDebtFlow();
  const navigate = useNavigate();
  const query = useQuery();
  const redirectUrl = query.get('redirectUrl');
  const isFromScanner = query.get('isFromScanner') === 'true';
  const token = useAccessToken();

  const [getCurrentUser, userQuery] = useLazyGetUserInformationQuery();
  const currentUser = userQuery.data;
  const [triggerIsUserValidated, isUserValidatedResult] = useLazyGetIsUserValidatedQuery();
  const [triggerCMF, last30CMFResult] = useLazyGetLast30DaysCMFRequestQuery();
  const [triggerLastCMFRequest] = useLazyGetLastCMFRequestQuery();
  const [triggerDebts, debtsResult] = useLazyListDebtsQuery();
  const [instantBankOffer, setInstantBankOffer] = useState<BankOffer | null>(null);
  const [isFlowSuccess, setIsFlowSuccess] = useState(false);
  const [isComingFromStep0, setIsComingFromStep0] = useState(false);
  const [refinanceDebts, { data: refinanceDebtsData, isLoading: isRefinanceLoading }] = useRefinanceDebtsMutation();

  const [activeStep, setActiveStep] = useState(0);
  const [rut, setRut] = useState('');
  const [phone, setPhone] = useState('+569');
  const [email, setEmail] = useState('');

  const lastCMFRequestDate = debtFlow.lastCMFRequestDate ? new Date(debtFlow.lastCMFRequestDate) : null;

  if (query.get('uniqueFlowRedirect') || query.get('rut')) {
    window.history.pushState({}, document.title, window.location.pathname); // reload all queries to see what step should the user be redirected to
  }

  const handleSuccessRedirect = () => {
    if (instantBankOffer) {
      navigate(`/bank-offers/${instantBankOffer.uuid}?redirectUrl=${redirectUrl || '/debt'}`);
    } else if (redirectUrl && currentUser?.companyId !== BUK_COMPANY_ID) {
      navigate(redirectUrl);
    } else {
      navigate('/debt');
    }
  };

  useEffect(() => {
    if (checkIfTokenIsValid(token)) {
      getCurrentUser(null)
        .unwrap()
        .then(() => {
          // Trigger the queries immediately after getting the user
          triggerIsUserValidated(null); // eslint-disable-line
          triggerLastCMFRequest(null); // eslint-disable-line
          triggerCMF(null); // eslint-disable-line
          triggerDebts(null); // eslint-disable-line
          refinanceDebts({ debtIds: [] }); // eslint-disable-line
        })
        .catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [token]);

  useEffect(() => {
    if (currentUser && currentUser.id !== 0) {
      if (currentUser.origin === null) {
        setRut(currentUser.rut || '');
        setPhone(currentUser.phone || '');
        setEmail(currentUser.email || '');
        origin = null;
      }
    }
  }, [currentUser]);

  useEffect(() => {
    const determineStep = () => {
      if (!currentUser || currentUser.id === 0) {
        return;
      }

      // Check for recent CMF request first
      if (last30CMFResult.data !== null && lastCMFRequestDate) {
        return;
      }

      if (refinanceDebtsData?.bankOffer) {
        setInstantBankOffer(refinanceDebtsData.bankOffer);
        return;
      }

      // If there's no rut, always go to step 0
      if (!currentUser.rut) {
        setActiveStep(0);
        return;
      }

      // If already in step 1, don't change it
      if (activeStep === 1) {
        // Check for BUK company ID before maintaining step 1
        if (currentUser.companyId === BUK_COMPANY_ID) {
          setActiveStep(1.5);
          return;
        }
        return;
      }

      // If user has rut, check validation and source
      if (currentUser.rut) {
        // Show modal (step 1) if coming from step 0 and not from scanner
        if (isComingFromStep0 && !isFromScanner) {
          if (currentUser.companyId === BUK_COMPANY_ID) {
            setActiveStep(1.5);
            setIsComingFromStep0(false);
            return;
          }
          setActiveStep(1);
          setIsComingFromStep0(false);
          return;
        }

        if (isUserValidatedResult.data?.isValidated) {
          if (isFromScanner) {
            setActiveStep(2);
          } else {
            // Only redirect to chat if not coming from step 0 and not in step 1 or 1.5
            // eslint-disable-next-line no-lonely-if
            if (!isComingFromStep0 && activeStep !== 1 && activeStep !== 1.5) {
              if (currentUser.companyId === BUK_COMPANY_ID) {
                setActiveStep(1.5);
              } else {
                navigate(redirectUrl || '/chat');
              }
            }
          }
        } else {
          setActiveStep(2);
        }
        return;
      }

      // If there's no recent CMF request, go to step 2
      if (last30CMFResult.data === null) {
        setActiveStep(2);
        return;
      }
      handleSuccessRedirect();
    };
    if (
      !isUserValidatedResult.isFetching &&
      !last30CMFResult.isFetching &&
      !debtsResult.isFetching &&
      currentUser &&
      isUserValidatedResult.data !== undefined
    ) {
      determineStep();
    }
  }, [
    currentUser,
    isUserValidatedResult.data,
    isUserValidatedResult.isFetching,
    last30CMFResult.data,
    last30CMFResult.isFetching,
    debtsResult.data,
    debtsResult.isFetching,
    isFromScanner,
    redirectUrl,
    navigate,
  ]);

  useEffect(() => {
    if (isFlowSuccess) {
      handleSuccessRedirect();
    }
  }, [isFlowSuccess, isFromScanner]);

  if (last30CMFResult.data !== null && lastCMFRequestDate) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6">Completaste el escáner financiero recientemente</Typography>
        <Typography variant="body1">
          Podrás completarlo nuevamente a partir del{' '}
          {dateFormat(new Date(lastCMFRequestDate.getTime() + 30 * 24 * 60 * 60 * 1000))}
        </Typography>
        <PrimaryBtn onClick={() => navigate('/')} sx={{ mt: 2 }}>
          Volver
        </PrimaryBtn>
      </Box>
    );
  }

  if (activeStep === 0) {
    return (
      <SetRutPhoneAndEmail
        onSuccess={() => {
          captureUserEvent('OnboardingFlow: Set Rut and accept terms');
          setIsComingFromStep0(true); // Set the flag when step 0 is completed
          setActiveStep(1);
        }}
        origin={origin}
        rut={rut}
        setRut={setRut}
        phone={phone}
        setPhone={setPhone}
        email={email}
        setEmail={setEmail}
        externalProviderId={currentUser?.externalProviderId || undefined}
        redirectUrl={redirectUrl || undefined}
      />
    );
  }
  if (activeStep === 1) {
    return (
      <RedirectToDebtsModal
        onRedirectClick={() => {
          captureUserEvent('OnboardingFlow: Relif permissions');
          if (isFromScanner) {
            setActiveStep(3);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (currentUser?.companyId === BUK_COMPANY_ID) {
              setActiveStep(1.5);
            } else {
              navigate(redirectUrl || '/chat');
            }
          }
        }}
        onSuccess={() => {
          setIsFlowSuccess(true);
        }}
        origin={origin}
        isFromScanner={isFromScanner}
        setInstantBankOffer={setInstantBankOffer}
      />
    );
  }
  if (activeStep === 2) {
    return (
      <ClaveUnicaDebtForm
        onSuccess={() => {
          setIsFlowSuccess(true);
        }}
        origin={origin}
        setInstantBankOffer={setInstantBankOffer}
      />
    );
  }
  if (activeStep === 1.5) {
    const isLoading =
      isRefinanceLoading ||
      isUserValidatedResult.isFetching ||
      last30CMFResult.isFetching ||
      debtsResult.isFetching ||
      userQuery.isFetching;

    return (
      <>
        <LoadingOverlay visible={isLoading} />
        <BukExecutiveContact origin={origin} />
      </>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
};

export default UniqueDebtFlow;
