import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ClockIcon } from 'assets/icons';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { longDateFormat } from 'services/format/dateFormat';
import { BlogPartialPage } from 'services/types/notion';

interface ArticleCardInterface {
  blogPage: BlogPartialPage;
}

const ArticleHorizontalCard: FC<ArticleCardInterface> = ({ blogPage }) => {
  const { path } = useCurrentRoute();
  const url = path.includes('/blog') ? '/blog' : '/education';
  return (
    <Grid container>
      <Grid item xs={5} xl={4}>
        <img src={blogPage.imageUrl} alt="article" style={{ height: '150px', objectFit: 'cover', width: '100%' }} />
      </Grid>
      <Grid item xs={7} xl={8} pl={3}>
        <Typography variant="body1" fontSize="16px">
          {longDateFormat(blogPage.publishedDate)}
        </Typography>
        <Typography variant="h5" my={1}>
          <Link to={`${url}/${blogPage.slug}`}>{blogPage.title}</Link>
        </Typography>
        <Typography variant="body1" fontSize="16px">
          {blogPage.readingTime} minutos de lectura
        </Typography>
      </Grid>
    </Grid>
  );
};

const ArticleVerticalCard: FC<ArticleCardInterface> = ({ blogPage }) => {
  const navigate = useNavigate();
  const { path } = useCurrentRoute();
  const url = path.includes('/blog') ? '/blog' : '/education';
  return (
    <Card
      sx={{
        borderRadius: '15px',
        width: { xs: '100%', lg: '24%' },
        height: '420px',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
        mt: { xs: 2, lg: 0 },
        mb: 2,
        ':hover': { boxShadow: 'rgba(99, 99, 99, 0.2) 0px 12px 16px 8px;' },
      }}
      variant="outlined"
    >
      <CardMedia component="img" height="200" image={blogPage.imageUrl} alt="article" />
      <CardContent sx={{ height: '150px' }}>
        <Typography variant="body1" mt={0.5}>
          {longDateFormat(blogPage.publishedDate)}{' '}
        </Typography>
        <Typography variant="h3" mt={1} fontWeight={500}>
          <Link to={`${url}/${blogPage.slug}`}>{blogPage.title}</Link>
        </Typography>
        <Typography variant="body1" mt={0.5} display="flex" alignItems="center">
          <ClockIcon sx={{ mr: 1 }} />
          {blogPage.readingTime} mins de lectura
        </Typography>
      </CardContent>
      <CardActions sx={{ mt: 'auto' }}>
        <Button onClick={() => navigate(`${url}/${blogPage.slug}`)} variant="text">
          Leer
        </Button>
      </CardActions>
    </Card>
  );
};

export default ArticleHorizontalCard;

export { ArticleVerticalCard };
