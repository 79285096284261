import React, { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ForwardIcon } from 'assets/icons';
import { SecondaryBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import useCurrentRoute from 'hooks/useCurrentRoute';
import { useGetDatabasePagesQuery } from 'services/api/notion.api';
import { longDateFormat } from 'services/format/dateFormat';

import { ArticleVerticalCard } from './ArticleCard';

const HorizontalCardSkeleton: React.FC = () => {
  return (
    <Grid container sx={{ width: '100%' }}>
      <Grid item xs={5} lg={4}>
        <Skeleton variant="rectangular" width="100%" height={175} />
      </Grid>
      <Grid item xs={7} lg={8} sx={{ pl: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={175} />
      </Grid>
    </Grid>
  );
};

const HighLights: FC = () => {
  const { data: blogPages } = useGetDatabasePagesQuery(null);
  const navigate = useNavigate();
  const { path } = useCurrentRoute();
  const [seeAllArticles, setSeeAllArticles] = useState(path.includes('/blog'));
  const url = path.includes('/blog') ? '/blog' : '/education';

  const latestArticles = [...(blogPages ?? [])].sort((a, b) => {
    return a.publishedDate < b.publishedDate ? 1 : -1;
  });

  const latestArticle = latestArticles.length > 0 ? latestArticles[0] : undefined;
  const olderArticles = seeAllArticles
    ? latestArticles.slice(1)
    : latestArticles
        .slice(1)
        .sort(() => Math.random() - 0.5)
        .slice(1, 5);

  return (
    <>
      <PaperContainer fullWidth title="Lo último ✨">
        <Grid container sx={{ mt: { xs: 2, lg: 2 } }}>
          {latestArticle ? (
            <Grid
              item
              xs={12}
              sx={{
                height: 'auto',
                display: { xs: 'block', lg: 'flex' },
                mb: 2,
              }}
            >
              <Box sx={{ width: { xs: '100%', lg: '55%' }, height: '300px' }}>
                <img
                  src={latestArticle.imageUrl}
                  alt="article"
                  style={{ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '20px' }}
                />
              </Box>
              <Box px={2}>
                <Typography variant="body1" mt={1}>
                  {longDateFormat(latestArticle.publishedDate)}
                </Typography>
                <Typography variant="h3" mt={1} fontWeight={500}>
                  <Link to={`${url}/${latestArticle?.slug || ''}`}>{latestArticle.title}</Link>
                </Typography>
                <Typography
                  variant="body1"
                  color="black"
                  fontSize={{ xs: 16, lg: 18, xl: 20 }}
                  fontWeight={300}
                  mt={1}
                  textAlign="justify"
                >
                  {latestArticle.summary}
                </Typography>
                <Typography variant="body1" my={2}>
                  Tiempo de lectura: {latestArticle?.readingTime} minutos
                </Typography>
                <SecondaryBtn
                  sx={{ ml: 'auto', textDecoration: 'none', px: 2 }}
                  endIcon={<ForwardIcon />}
                  onClick={() => navigate(`${url}/${latestArticle?.slug || ''}`)}
                >
                  Leer articulo
                </SecondaryBtn>
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              sx={{
                height: '450px',
                pr: 2,
                display: 'flex',
              }}
            >
              <Skeleton variant="rectangular" width="50%" height={300} />
              <Box width="45%" ml={2}>
                <Skeleton sx={{ fontSize: '2rem' }} />
                <Skeleton variant="rectangular" width="100%" height={200} />
              </Box>
            </Grid>
          )}
        </Grid>
      </PaperContainer>
      <PaperContainer fullWidth title={seeAllArticles ? 'Publicaciones 👌' : 'Destacados 👌'}>
        <Box sx={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
          {olderArticles.length > 0 ? (
            olderArticles.map((blogPage) => <ArticleVerticalCard blogPage={blogPage} key={blogPage.id} />)
          ) : (
            <Stack spacing={1} divider={<Divider orientation="horizontal" flexItem />} sx={{ width: '100%' }}>
              {[1, 2, 3, 4].map((number) => (
                <HorizontalCardSkeleton key={`Skeleton ${number}`} />
              ))}
            </Stack>
          )}
          <Button onClick={() => setSeeAllArticles(true)} variant="text">
            Ver todos
          </Button>
        </Box>
      </PaperContainer>
    </>
  );
};

export default HighLights;
