import React, { FC, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import debtTypeList from 'assets/data/debtTypeList';
import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useAdminUpdateDebtMutation, useCreateDebtMutation, useEditDebtMutation } from 'services/api/debt.api';
import {
  Debt as DebtInterface,
  DebtDraft as DebtDraftInterface,
  DebtType as DebtTypeInterface,
} from 'services/types/debt';

import ManualDebtStepOne from './ManualDebtStep1';
import ManualDebtStepTwo from './ManualDebtStep2';
import ManualDebtStepThree from './ManualDebtStep3';
import ManualDebtStepFour from './ManualDebtStep4';

const steps = ['Tipo de institución', 'Nombre de institución', 'Tipo de producto', 'Detalles de deuda'];

interface Props {
  debtToEdit: DebtInterface | null;
  setDebtToEdit: (debt: DebtInterface | null) => void;
  setFormOpen: (state: boolean) => void;
  activeStep: number;
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  adminMode: boolean;
  showBanks?: boolean;
  onSave?: () => void;
}

const ManualDebtFormSteps: FC<Props> = ({
  debtToEdit,
  setFormOpen,
  setDebtToEdit,
  activeStep,
  setActiveStep,
  adminMode,
  showBanks,
  onSave,
}) => {
  const [debtDraft, setDebtDraft] = useState<DebtDraftInterface>({
    institutionType: '',
    institutionName: '',
    debtType: '',
    currency: 'CLP',
    amount: 0,
    term: 0,
    interestRate: 0,
    monthlyPayment: 0,
    pendingPayments: 0,
    monthsBehind: 0,
  });
  const [debtType, setDebtType] = useState<DebtTypeInterface | null>(null);
  const [createDebt, { data, isLoading, isError }] = useCreateDebtMutation();
  const [editDebt, { data: editData, isLoading: isEditLoading, isError: isEditError }] = useEditDebtMutation();
  const [adminUpdateDebt, { data: adminUpdateData, isLoading: isAdminUpdateLoading, isError: isAdminUpdateError }] =
    useAdminUpdateDebtMutation();

  const clearFormFields = () => {
    setDebtDraft({
      institutionType: '',
      institutionName: '',
      debtType: '',
      currency: 'CLP',
      amount: 0,
      term: 0,
      interestRate: 0,
      monthlyPayment: 0,
      pendingPayments: 0,
      monthsBehind: 0,
    });
    setDebtType(null);
  };

  const handleClose = () => {
    clearFormFields();
    setDebtToEdit(null);
    setFormOpen(false);
    setActiveStep(0);
  };

  const verifyForm = () => {
    if (debtType && debtType.familyType === 'revolving') {
      return (debtDraft.amount || 0) > 0;
    }

    return (
      (debtDraft.amount || 0) > 0 &&
      (debtDraft.monthlyPayment || 0) > 0 &&
      debtDraft.term !== 0 &&
      debtDraft.pendingPayments !== 0
    );
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep: number) => prevActiveStep + 1);
  };

  const handleSubmit = async () => {
    if (verifyForm()) {
      const body = {
        ...debtDraft,
        debtType: debtType ? debtType.name : '',
        currency: debtDraft.currency || 'CLP',
        interestRate: 0,
        monthsBehind: debtDraft.monthsBehind || 0,
        pendingPayments: debtDraft.pendingPayments || 0,
        term: debtDraft.term || 0,
      };
      if (debtToEdit && !adminMode) {
        await editDebt({
          body,
          id: debtToEdit.id,
        });
      } else if (debtToEdit && adminMode) {
        await adminUpdateDebt({
          body,
          id: debtToEdit.id,
        });
      } else {
        await createDebt(body);
      }
    }
  };

  const checkNextDisabled = (step: number) => {
    if (step === 0) {
      return debtDraft.institutionType === '';
    }
    if (step === 1) {
      return debtDraft.institutionName === '';
    }
    if (step === 2) {
      return debtType === null;
    }
    if (step === 3) {
      return !verifyForm();
    }
    return false;
  };

  useEffect(() => {
    if (data || editData || adminUpdateData) {
      clearFormFields();
      setActiveStep(0);
      handleClose();
      if (onSave) {
        onSave();
      }
    }
    if (isError || isEditError || isAdminUpdateError) {
      clearFormFields();
      handleNext();
    }
  }, [data, isError, editData, isEditError, adminUpdateData, isAdminUpdateError]);

  useEffect(() => {
    if (!debtToEdit) {
      return;
    }
    setDebtDraft({
      institutionName: debtToEdit.institutionName || '',
      institutionType: debtToEdit.institutionType || '',
      debtType: debtToEdit.debtType || '',
      currency: debtToEdit.currency || 'CLP',
      amount: Number(debtToEdit.amount) || 0,
      term: debtToEdit.term || 0,
      interestRate: debtToEdit.interestRate || 0,
      monthlyPayment: debtToEdit.monthlyPayment || 0,
      pendingPayments: debtToEdit.pendingPayments || 0,
      monthsBehind: debtToEdit.monthsBehind || 0,
    });
    setActiveStep(debtToEdit.debtType ? 3 : 2);
    const oldDebtType = debtTypeList.find((type) => type.name === debtToEdit.debtType);
    setDebtType(oldDebtType || debtTypeList[0]);
  }, [debtToEdit]);

  return (
    <Box pt={2}>
      <LoadingOverlay visible={isLoading || isEditLoading || isAdminUpdateLoading} />
      {activeStep === steps.length && (
        <>
          <Typography variant="h2">Error al crear la deuda, por favor intenta de nuevo</Typography>
          <PrimaryBtn variant="contained" sx={{ my: 2 }} fullWidth onClick={() => setActiveStep(0)}>
            Volver
          </PrimaryBtn>
        </>
      )}
      {activeStep === 0 && (
        <ManualDebtStepOne
          debtDraft={debtDraft}
          setDebtDraft={setDebtDraft}
          setActiveStep={setActiveStep}
          showBanks={showBanks}
        />
      )}
      {activeStep === 1 && (
        <ManualDebtStepTwo debtDraft={debtDraft} setDebtDraft={setDebtDraft} setActiveStep={setActiveStep} />
      )}
      {activeStep === 2 && (
        <ManualDebtStepThree
          institutionType={debtDraft.institutionType || ''}
          setDebtType={setDebtType}
          setActiveStep={setActiveStep}
        />
      )}
      {activeStep === 3 && (
        <ManualDebtStepFour debtDraft={debtDraft} setDebtDraft={setDebtDraft} debtFamilyType={debtType?.familyType} />
      )}
      {activeStep >= steps.length - 1 && (
        <Box display="flex" justifyContent="center" pt={1}>
          <Button
            variant="contained"
            onClick={() => {
              if (!checkNextDisabled(activeStep)) {
                handleSubmit().catch((e) => console.error(e)); // eslint-disable-line no-console
              }
            }}
            disabled={checkNextDisabled(activeStep)}
          >
            {debtToEdit ? 'Editar ' : 'Agregar '} Deuda
          </Button>
        </Box>
      )}
    </Box>
  );
};

ManualDebtFormSteps.defaultProps = {
  showBanks: true,
  onSave: () => {},
};

export default ManualDebtFormSteps;
