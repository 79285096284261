import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

import useIsMobile from 'hooks/useIsMobile';

import styles from '../CreditSimulator.module.scss';

interface Props {
  text: string;
  route?: string | undefined;
  onPreApprovalClick?: () => void; // eslint-disable-line react/require-default-props
}

const PreApprovalButton = ({ text, route, onPreApprovalClick }: Props) => {
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();
  const handleClick = () => {
    if (onPreApprovalClick) {
      onPreApprovalClick();
    } else if (route) {
      navigate(route);
    } else {
      navigate(`/chat?firstMessage=${text}`);
    }
  };
  return (
    <Grid item xs={isMobile ? 12 : 6}>
      <button className={styles.ButtonContainer} type="button" onClick={handleClick}>
        {text}
      </button>
    </Grid>
  );
};

PreApprovalButton.defaultProps = {
  route: undefined,
};

export default PreApprovalButton;
