/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import type { DebtsReport as DebtsReportResponse } from '@relif/backend';
import logo from 'assets/images/logo.webp';
import { SecondaryBtn } from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import DefinitionWrapper from 'components/common/DefinitionWrapper';
import useQuery from 'hooks/useQuery';
import { useAdminGetDebtsReportMutation } from 'services/api/admin-users.api';
import { useGetDebtsReportMutation } from 'services/api/user.api';
import priceFormat from 'services/format/priceFormat';

interface ReportHeaderProps {
  reportDate: Date;
  debtsReportData: DebtsReportResponse;
}

const ReportHeader: FC<ReportHeaderProps> = ({ reportDate, debtsReportData }) => {
  return (
    <Box sx={{ width: '100%', borderTopRightRadius: 10, borderTopLeftRadius: 10 }} bgcolor="info.light" p={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <img src={logo} alt="logo" style={{ width: '150px' }} />
          <Typography variant="h4" sx={{ textAlign: 'left' }}>
            {' '}
            Fecha:{' '}
            {reportDate.toLocaleString('es-CL', {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            })}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} display="flex" flexDirection="column" justifyContent="flex-end">
          <Typography variant="h4" sx={{ textAlign: 'right' }}>
            Empresa: {debtsReportData.user.company}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'right' }}>
            {' '}
            {debtsReportData.user.firstName} {debtsReportData.user.lastName}
          </Typography>
          <Typography variant="h4" sx={{ textAlign: 'right' }}>
            {debtsReportData.user.rut}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

const DebtsReport = () => {
  const [getDebtsReport, { data: userData }] = useGetDebtsReportMutation();
  const [adminGetDebtsReport, { data: adminData }] = useAdminGetDebtsReportMutation();
  const [selectedCamaraComercioReport, setSelectedCamaraComercioReport] = useState<
    DebtsReportResponse['camaraComercioReports'][0] | null
  >();
  const [selectedCMFReport, setSelectedCMFReport] = useState<DebtsReportResponse['cmfReports'][0] | null>();

  const [reportType, setReportType] = useState<'CAMARA_COMERCIO' | 'CMF'>('CAMARA_COMERCIO');
  const debtsReportData = userData || adminData;
  const hasUnpaidCMFDebts = selectedCMFReport?.directDebt?.some(
    (debt) => Number(debt.between30To89Days) > 0 || Number(debt.over90Days) > 0
  );
  const navigate = useNavigate();
  const getNextReportDate = (lastReportDate: string) => {
    const date = new Date(lastReportDate);
    date.setMonth(date.getMonth() + 1);
    return date;
  };
  // get user id param
  const query = useQuery();
  const userId = query.get('userId');
  useEffect(() => {
    if (userId) {
      adminGetDebtsReport(Number(userId)).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    } else {
      getDebtsReport(null).catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    }
  }, [getDebtsReport, userId]);

  useEffect(() => {
    if (debtsReportData) {
      setSelectedCamaraComercioReport(debtsReportData.camaraComercioReports[0]);
      setSelectedCMFReport(debtsReportData.cmfReports[0]);
    }
  }, [debtsReportData]);

  return (
    <Box sx={{ px: 2, py: { xs: 0, lg: 4 }, display: 'flex', flexDirection: 'column' }}>
      <Button
        variant="contained"
        onClick={() => {
          if (userId) {
            navigate(`/admin/users?userId=${userId}`);
          } else {
            navigate('/debt-profile');
          }
        }}
      >
        Volver
      </Button>
      <Box pt={2}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>¿Que tipo de reporte deseas ver?</InputLabel>
          <Select
            value={reportType}
            label="¿Que tipo de reporte deseas ver?"
            onChange={(e) => setReportType(e.target.value as 'CAMARA_COMERCIO' | 'CMF')}
          >
            <MenuItem value="CAMARA_COMERCIO">Boletín comercial</MenuItem>
            <MenuItem value="CMF">Informe de deudas CMF</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box pt={2}>
        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel>¿De qué fecha?</InputLabel>
          <Select
            value={reportType === 'CMF' ? selectedCMFReport?.reportDate : selectedCamaraComercioReport?.reportDate}
            label="¿De qué fecha?"
            onChange={(e) => {
              if (reportType === 'CMF') {
                setSelectedCMFReport(
                  debtsReportData?.cmfReports.find((report) => report.reportDate === e.target.value) || null
                );
              } else {
                setSelectedCamaraComercioReport(
                  debtsReportData?.camaraComercioReports.find((report) => report.reportDate === e.target.value) || null
                );
              }
            }}
          >
            {reportType === 'CAMARA_COMERCIO'
              ? debtsReportData?.camaraComercioReports.map((report) => (
                  <MenuItem key={report.reportDate} value={report.reportDate}>
                    {new Date(report.reportDate).toLocaleString('es-CL', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    })}
                  </MenuItem>
                ))
              : debtsReportData?.cmfReports.map((report) => (
                  <MenuItem key={report.reportDate} value={report.reportDate}>
                    {new Date(report.reportDate).toLocaleString('es-CL', {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    })}
                  </MenuItem>
                ))}
          </Select>
        </FormControl>
      </Box>

      {reportType === 'CAMARA_COMERCIO' && debtsReportData && selectedCamaraComercioReport && (
        <PaperContainer fullWidth>
          <ReportHeader
            reportDate={new Date(selectedCamaraComercioReport.reportDate)}
            debtsReportData={debtsReportData}
          />
          <Box p={2}>
            <Box py={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h4">
                Deudas publicadas en el{' '}
                <DefinitionWrapper definitionName="boletín comercial" placement="bottom">
                  boletín comercial
                </DefinitionWrapper>
              </Typography>
            </Box>
            {selectedCamaraComercioReport.bicDebts.length === 0 ? (
              <Typography variant="body1" mb={2}>
                No tienes deudas publicadas en el boletín comercial.
              </Typography>
            ) : (
              <Box paddingBottom={1}>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Institución financiera</TableCell>
                        <TableCell>Fecha de publicación</TableCell>
                        <TableCell>Monto adeudado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedCamaraComercioReport.bicDebts.map((debt) => (
                        <TableRow key={`${debt.institutionName}-${debt.amount}`}>
                          <TableCell>{debt.institutionName}</TableCell>
                          <TableCell>
                            {new Date(debt.dueDate).toLocaleString('es-CL', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          </TableCell>
                          <TableCell>{priceFormat.format(debt.amount)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <Box py={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h4">
                <DefinitionWrapper definitionName="deuda morosa">Deudas morosas</DefinitionWrapper> no publicadas en el
                boletín comercial
              </Typography>
            </Box>
            {selectedCamaraComercioReport.molDebts.length === 0 ? (
              <Typography variant="body1" mb={2}>
                No tienes deudas morosas no publicadas en el boletín comercial.
              </Typography>
            ) : (
              <Box paddingBottom={1}>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Institución financiera</TableCell>
                        <TableCell>Fecha de vencimiento</TableCell>
                        <TableCell>Monto adeudado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedCamaraComercioReport.molDebts.map((debt) => (
                        <TableRow key={`${debt.institutionName}-${debt.amount}`}>
                          <TableCell>{debt.institutionName}</TableCell>
                          <TableCell>
                            {new Date(debt.dueDate).toLocaleString('es-CL', {
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                            })}
                          </TableCell>
                          <TableCell>{priceFormat.format(debt.amount)}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <Typography variant="h4" py={1}>
              Nuestro consejo:
            </Typography>
            {selectedCamaraComercioReport.molDebts.length > 0 || selectedCamaraComercioReport.bicDebts.length > 0 ? (
              <>
                <Typography variant="body1">
                  Tienes deudas que no has pagado, lo que puede afectar tu evaluación crediticia. No te preocupes, esto
                  le pasa a mucha gente. Habla con Nina para que te ayude a crear un plan de pago de tus deudas.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <SecondaryBtn
                    onClick={() => {
                      navigate('/chat?firstMessage=Me interesa un plan de pago de deudas');
                    }}
                  >
                    Hablar con Nina
                  </SecondaryBtn>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  No hemos detectado deudas que no hayas pagado. ¡Estás en el buen camino! ☺️ Asegúrate de seguir
                  pagando a tiempo. Es un buen momento para comenzar a ahorrar. Puedes hablar con Nina para que te ayude
                  a crear un plan de ahorro.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <SecondaryBtn
                    onClick={() => {
                      navigate('/chat?firstMessage=Me interesa un plan de ahorro');
                    }}
                  >
                    Hablar con Nina
                  </SecondaryBtn>
                </Box>
              </>
            )}
          </Box>
          <Box p={2} mt={4}>
            <Alert severity="success" sx={{ mb: 2 }}>
              Información actualizada correctamente
            </Alert>
          </Box>
        </PaperContainer>
      )}

      {reportType === 'CMF' && debtsReportData && selectedCMFReport && (
        <PaperContainer fullWidth>
          <ReportHeader reportDate={new Date(selectedCMFReport.reportDate)} debtsReportData={debtsReportData} />
          <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }} p={2}>
            <Box py={1} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <Typography variant="h4">
                <DefinitionWrapper definitionName="Informe de deudas CMF" placement="bottom">
                  Informe de deudas CMF
                </DefinitionWrapper>
              </Typography>
            </Box>
            {selectedCMFReport.directDebt.length === 0 ? (
              <Typography variant="body1" mb={2}>
                No encontramos deudas en la CMF.
              </Typography>
            ) : (
              <Box paddingBottom={1}>
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Institución financiera</TableCell>
                        <TableCell>Saldo vigente</TableCell>
                        <TableCell>Atraso: 30 a 89 días</TableCell>
                        <TableCell>Atraso: 90 a más días</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {selectedCMFReport.directDebt.map((debt) => (
                        <TableRow key={`${debt.institution}-${debt.total}`}>
                          <TableCell>{debt.institution}</TableCell>
                          <TableCell>{priceFormat.format(Number(debt.currentDebt))}</TableCell>
                          <TableCell>{priceFormat.format(Number(debt.between30To89Days))}</TableCell>
                          <TableCell>{priceFormat.format(Number(debt.over90Days))}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            )}
            <Typography variant="h4" py={1}>
              Nuestro consejo:
            </Typography>
            {hasUnpaidCMFDebts ? (
              <>
                <Typography variant="body1">
                  Tienes deudas atrasadas, lo que puede afectar tu evaluación crediticia. No te preocupes, esto le pasa
                  a mucha gente. Habla con Nina para que te ayude a crear un plan de pago de tus deudas.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <SecondaryBtn
                    onClick={() => {
                      navigate('/chat?firstMessage=Me interesa un plan de pago de deudas');
                    }}
                  >
                    Hablar con Nina
                  </SecondaryBtn>
                </Box>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  No hemos detectado deudas atrasadas en la CMF. ¡Estás en el buen camino! ☺️ Asegúrate de seguir
                  pagando a tiempo. Es un buen momento para comenzar a ahorrar. Puedes hablar con Nina para que te ayude
                  a crear un plan de ahorro.
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                  <SecondaryBtn
                    onClick={() => {
                      navigate('/chat?firstMessage=Me interesa un plan de ahorro');
                    }}
                  >
                    Hablar con Nina
                  </SecondaryBtn>
                </Box>
              </>
            )}
          </Box>
          <Box p={2} mt={4}>
            <Alert severity="success" sx={{ mb: 2 }}>
              Información actualizada correctamente
              {getNextReportDate(selectedCMFReport.reportDate).toLocaleString('es-CL', {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              })}
            </Alert>
          </Box>
        </PaperContainer>
      )}
    </Box>
  );
};

export default DebtsReport;
