import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import LoadingOverlay from 'components/UI/LoadingOverlay';
import ClaveUnicaModal from 'components/common/ClaveUnicaModal';
import useDebtFlow from 'hooks/useDebtFlow';
import CategorizeCMFData from 'pages/Debts/components/DebtFlow/CategorizeCMFData';
import { useDeleteOldCMFDebtsMutation, useListDebtsQuery, useRefinanceDebtsMutation } from 'services/api/debt.api';
import { useGetUserDebtProfileQuery, useGetUserInformationQuery } from 'services/api/user.api';
import { BankOffer } from 'services/types/bankOffers';
import { Origin } from 'services/types/origin';

import styles from '../UniqueDebtFlow.module.scss';
import HeaderLogos from './HeaderLogos';

interface Props {
  onSuccess: () => void;
  origin: Origin;
  setInstantBankOffer: (bankOffer: BankOffer | null) => void;
}

const ClaveUnicaDebtForm: FC<Props> = ({ onSuccess, origin, setInstantBankOffer }) => {
  const navigate = useNavigate();
  const { refetch: refetchDebtData } = useListDebtsQuery(null);
  const { refetch: refetchDebtProfileData } = useGetUserDebtProfileQuery(null);
  const debtFlow = useDebtFlow();
  const [deleteOldCMFDebts] = useDeleteOldCMFDebtsMutation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [refinanceDebts, { data: refinanceDebtsData, isLoading: isRefinanceDebtsLoading }] =
    useRefinanceDebtsMutation();

  const { data: currentUser } = useGetUserInformationQuery(null);

  const handleSaveClaveUnica = () => {
    setActiveStep(1);
    refetchDebtProfileData().catch((e) => console.error(e)); // eslint-disable-line no-console
  };

  const saveCategorizeDebtData = async () => {
    await deleteOldCMFDebts(null);
    const data = await refetchDebtData();
    if (data.data) {
      await refinanceDebts({
        debtIds: data.data.filter((debt) => !debt.refinance).map((debt) => debt.id) || [],
      });
    }
  };

  const handleSaveCategorizeCMFData = () => {
    saveCategorizeDebtData().catch((e) => console.error(e)); // eslint-disable-line no-console
  };

  useEffect(() => {
    // skip clave unica step if user has already done it in the last 30 days
    if (debtFlow?.hasCMFResponseInLast30Days && activeStep === 0) {
      setActiveStep(1);
    }
  }, [debtFlow?.hasCMFResponseInLast30Days]);

  useEffect(() => {
    if (refinanceDebtsData?.bankOffer) {
      setInstantBankOffer(refinanceDebtsData.bankOffer);
    }
    if (refinanceDebtsData) {
      setActiveStep(2);
      onSuccess();
    }
  }, [refinanceDebtsData]);

  const steps = ['Clave Única', 'Perfilamiento', 'Categorizar deudas al dia'];
  return (
    <Box className={styles.ModalContainer}>
      {isRefinanceDebtsLoading && <LoadingOverlay visible />}
      <Box className={styles.Modal}>
        <Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
            {activeStep !== 1 && (
              <Button
                startIcon={<ArrowBackIcon />}
                onClick={() => navigate(-1)}
                sx={{
                  color: 'info.main',
                  '&:hover': {
                    backgroundColor: 'info.light',
                  },
                }}
              >
                Volver
              </Button>
            )}
            <HeaderLogos origin={origin} />
            <Box width={64} />
          </Box>
          <Box py={2}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => (
                <Step key={label} completed={activeStep > index}>
                  <StepLabel
                    StepIconProps={{
                      sx: {
                        '&.Mui-completed': {
                          color: 'success.main',
                        },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box className={styles.ContentBox}>
            <Box display="flex" justifyContent="center" alignContent="center">
              {activeStep === 0 && currentUser && currentUser.rut && (
                <ClaveUnicaModal rut={currentUser.rut} onSave={handleSaveClaveUnica} isCMFUpdate={false} />
              )}
              {activeStep === 1 && <CategorizeCMFData onSave={handleSaveCategorizeCMFData} />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ClaveUnicaDebtForm;
