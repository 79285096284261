/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Dispatch, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';

import { AddIcon, HelpIcon } from 'assets/icons';
import CajaTooltip from 'components/UI/CajaTooltip';
import MoneyInput from 'components/UI/MoneyInput';
import { Debt } from 'services/types/debt';

import type { BudgetRowDraft } from '../../../services/types/budget';
import { INCOME_CATEGORIES } from '../constants';

interface BasicTableInterface {
  budgetRows: BudgetRowDraft[];
  setBudgetRowAmount: (
    amount: number | string,
    index: number,
    rows: BudgetRowDraft[],
    setRows: Dispatch<BudgetRowDraft[]>
  ) => void;
  removeRow: (index: number, rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>) => void;
  setBudgetRows: Dispatch<BudgetRowDraft[]>;
}

interface FormTableInterface extends BasicTableInterface {
  setBudgetRowStringAttribute: (
    budgetRowName: string,
    index: number,
    attribute: 'name' | 'category' | 'subcategory',
    rows: BudgetRowDraft[],
    setRows: Dispatch<BudgetRowDraft[]>
  ) => void;
  addRow: (rows: BudgetRowDraft[], setRows: Dispatch<BudgetRowDraft[]>, isExpense: boolean, category: string) => void;
}

interface ExpenseTableInterface extends FormTableInterface {
  subcategories: string[];
  debtsData?: Debt[];
  formName: string;
}

const IncomeFormTable: FC<FormTableInterface> = ({
  budgetRows,
  setBudgetRowAmount,
  setBudgetRowStringAttribute,
  removeRow,
  addRow,
  setBudgetRows,
}) => {
  return (
    <>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Categoría</TableCell>
              <TableCell>Monto ($)</TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetRows.map((row, index) => (
              <TableRow key={`${index + 1}`}>
                <TableCell>
                  <TextField
                    type="text"
                    value={row.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setBudgetRowStringAttribute(e.target.value, index, 'name', budgetRows, setBudgetRows)
                    }
                    fullWidth
                  />
                </TableCell>
                <TableCell>
                  <FormControl fullWidth>
                    <InputLabel>Categoría</InputLabel>
                    <Select
                      value={row.category}
                      label="Categoría"
                      onChange={(e) =>
                        setBudgetRowStringAttribute(e.target.value, index, 'category', budgetRows, setBudgetRows)
                      }
                    >
                      {INCOME_CATEGORIES.map((categoryName) => (
                        <MenuItem key={categoryName} value={categoryName}>
                          {categoryName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <MoneyInput
                    label="Monto ($)"
                    value={Number(row.amount)}
                    setValue={(value) => setBudgetRowAmount(value, index, budgetRows, setBudgetRows)}
                  />
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="error" onClick={() => removeRow(index, budgetRows, setBudgetRows)}>
                    Eliminar
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box pt={1}>
        <Button
          variant="contained"
          onClick={() => addRow(budgetRows, setBudgetRows, false, 'Ingreso')}
          startIcon={<AddIcon />}
        >
          Agregar ingreso
        </Button>
      </Box>
    </>
  );
};

const IncomeTable = React.memo(IncomeFormTable);

const ExpenseFormTable: FC<ExpenseTableInterface> = ({
  budgetRows,
  setBudgetRowAmount,
  setBudgetRowStringAttribute,
  removeRow,
  addRow,
  setBudgetRows,
  formName,
  subcategories,
  debtsData,
}) => {
  const navigate = useNavigate();
  const explainText = () => {
    if (formName === 'Hogar') {
      return 'En gris está indicado el gasto promedio chileno de la respectiva categoría tomando en cuenta una familia de 3 personas, puedes tomarlo como referencia.';
    }
    if (formName === 'Entretención') {
      return 'En gris está indicado el gasto promedio chileno, en caso de salidas es el promedio de una salida de dicha categoría, en el caso de suscripciones es el precio de plan estándar, puedes tomarlo como referencia.';
    }
    return 'En gris está indicado el gasto promedio chileno de la respectiva categoría, puedes tomarlo como referencia.';
  };

  return (
    <>
      {formName === 'Financiero' && (!debtsData || debtsData.length === 0) && (
        <Alert
          action={
            <Button size="small" onClick={() => navigate('/debt')}>
              Agregar deuda
            </Button>
          }
          severity="warning"
        >
          No has agregado ninguna deuda
        </Alert>
      )}
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Nombre</TableCell>
              <TableCell>Subcategoría</TableCell>
              <TableCell>
                Monto ($)
                <CajaTooltip title={explainText()} placement="top">
                  <IconButton aria-label="Ayuda para el monto" size="small" sx={{ ml: 2, pt: '2.5px' }}>
                    <HelpIcon sx={{ color: 'white !important' }} fontSize="inherit" />
                  </IconButton>
                </CajaTooltip>
              </TableCell>
              <TableCell align="center">Acción</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budgetRows
              .filter((row) => row.isExpense)
              .map((row, index) => (
                <TableRow key={`${index + 1}`}>
                  <TableCell>
                    <TextField
                      type="text"
                      value={row.name}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setBudgetRowStringAttribute(e.target.value, index, 'name', budgetRows, setBudgetRows)
                      }
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl fullWidth>
                      <InputLabel>Subcategoría</InputLabel>
                      <Select
                        value={row.subcategory}
                        label="Subcategoría"
                        onChange={(e) =>
                          setBudgetRowStringAttribute(e.target.value, index, 'subcategory', budgetRows, setBudgetRows)
                        }
                      >
                        {subcategories.map((subcategoryName) => (
                          <MenuItem key={subcategoryName} value={subcategoryName}>
                            {subcategoryName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </TableCell>
                  <TableCell>
                    <MoneyInput
                      label="Monto ($)"
                      value={Number(row.amount)}
                      setValue={(value) => setBudgetRowAmount(value, index, budgetRows, setBudgetRows)}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => removeRow(index, budgetRows, setBudgetRows)}
                    >
                      Eliminar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        variant="contained"
        onClick={() => addRow(budgetRows, setBudgetRows, true, formName)}
        startIcon={<AddIcon />}
      >
        Agregar gasto
      </Button>
    </>
  );
};
ExpenseFormTable.defaultProps = {
  debtsData: undefined,
};
const ExpenseTable = React.memo(ExpenseFormTable);

export { IncomeTable, ExpenseTable };
