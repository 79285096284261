import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';

const TypeWriterTexts = [
  'baja tus cuotas hasta un 40%',
  'simula créditos hipotecarios',
  'te guía con tus finanzas',
  'te ayuda a ahorrar',
];

interface HeaderProps {
  handleOpenFinancialAdvisorAuthModal: (open: boolean) => void;
}

const Header = ({ handleOpenFinancialAdvisorAuthModal }: HeaderProps) => {
  const [currentText, setCurrentText] = useState(TypeWriterTexts[0]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentDisplayText, setCurrentDisplayText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDeleting && currentIndex < currentText.length) {
        setCurrentDisplayText(currentText.slice(0, currentIndex + 1));
        setCurrentIndex(currentIndex + 1);
      } else if (!isDeleting && currentIndex === currentText.length) {
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && currentIndex > 0) {
        setCurrentDisplayText(currentText.slice(0, currentIndex - 1));
        setCurrentIndex(currentIndex - 1);
      } else if (isDeleting && currentIndex === 0) {
        const currentTextIndex = TypeWriterTexts.indexOf(currentText);
        setCurrentText(TypeWriterTexts[(currentTextIndex + 1) % TypeWriterTexts.length]);
        setIsDeleting(false);
      }
    }, 20);

    return () => clearInterval(interval);
  }, [currentText, currentIndex, isDeleting]);
  return (
    <Box
      sx={{
        backgroundImage: 'url("/landing_page/img/headerBackground.png")',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderRadius: { xs: '', sm: '0 0 0 200px' },
        pt: { xs: '130px' },
      }}
    >
      <Box display="flex" mx="auto" justifyContent="center" flexDirection={{ xs: 'column', md: 'row' }}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            width: { md: '50%', xs: '100%' },
            ml: { md: 4 },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'inline',
              width: { md: '80%', xs: '90%' },
              margin: '0 auto',
              textAlign: { xs: 'left', md: 'left' },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontWeight: 700,
                fontSize: { xs: 20, sm: 28 },
                lineHeight: '30px',
                mt: { xs: 8, md: 0 },
                color: 'white',
                mb: { xs: 2, sm: 0 },
              }}
            >
              Tu partner financiero
            </Typography>
            <Box sx={{ minHeight: { xs: '100px', sm: '50px' } }}>
              <Typography
                sx={{
                  color: '#9FC6EA',
                  fontFamily: 'Roboto',
                  fontWeight: '800',
                  fontSize: { xs: 35, sm: 45 },
                  lineHeight: { xs: '38px', sm: '50px' },
                }}
              >
                {currentDisplayText}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            flex: 1,
            pb: 0,
            display: 'flex',
            justifyContent: 'center',
            flexDirection: { xs: 'column', md: 'row' },
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center" pb={4}>
            <PrimaryBtn
              variant="outlined"
              sx={{
                color: 'black',
                backgroundColor: '#F9D00A',
                borderColor: '#F9D00A',
                '&:hover': {
                  backgroundColor: '#DCB705',
                  borderColor: '#DCB705',
                  color: 'black',
                },
                width: '200px',
              }}
              onClick={() => {
                handleOpenFinancialAdvisorAuthModal(false);
              }}
            >
              Comenzar
            </PrimaryBtn>
          </Box>
          <Box display={{ xs: 'none', md: 'flex' }} alignItems="end" justifyContent="center">
            <img alt="" src="/landing_page/img/nina.png" width="30%" />
          </Box>

          <Box display={{ xs: 'flex', md: 'none' }} alignItems="end" justifyContent="center" pr={2}>
            <img alt="" src="/landing_page/img/nina.png" width="40%" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
