import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { checkIfTokenIsValid } from 'authStorage';
import FinancialAdvisorAuthModal from 'components/common/FinancialAdvisorAuthModal';
import useAccessToken from 'hooks/useAccessToken';
import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';
import BankCarousel from 'pages/BukDashboard/components/BankCarousel';
import CreditSimulatorPage from 'pages/CreditSimulator';
import Footer from 'pages/LandingPageNew/components/Footer';
import Navigation from 'pages/LandingPageNew/components/Navigation';
import { useLazyGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useLazyGetUserInformationQuery } from 'services/api/user.api';

import styles from './LandingPageSimulator.module.scss';

const RELIF_FINANCIAL_ADVISOR_COMPANY_ID = 59;
const SOURCE = 'B2C-LANDING-PAGE';

const LandingPageSimulator = () => {
  const { isMobile } = useIsMobile();
  const [isSignUpModalOpen, setIsSignUpModalOpen] = React.useState(false);
  const navigate = useNavigate();
  const token = useAccessToken();
  const tokenIsValid = checkIfTokenIsValid(token);
  const debtFlow = useDebtFlow();
  const [getUserInformation, { data: userInformation }] = useLazyGetUserInformationQuery();
  const [getLastCMFRequest, { isSuccess: isLastCMFRequestSuccess }] = useLazyGetLastCMFRequestQuery();

  const handlePreApprovalClick = () => {
    setIsSignUpModalOpen(true);
  };

  const handleOpenFinancialAdvisorAuthModal = () => {
    setIsSignUpModalOpen(true);
  };

  // Fetch user information once the user is logged in
  useEffect(() => {
    if (tokenIsValid) {
      getUserInformation(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching user information');
      });
    }
  }, [tokenIsValid]);

  // Fetch CMF request once the user information is fetched
  useEffect(() => {
    if (userInformation && userInformation.rut) {
      getLastCMFRequest(null).catch(() => {
        // eslint-disable-next-line no-console
        console.error('Error fetching CMF request');
      });
    }
    if (userInformation && !userInformation.rut) {
      navigate('/onboarding/relif?redirectUrl=/chat?refetchFlag=1');
    }
  }, [userInformation]);

  // Redirect to the chat page if the user has a CMF response
  useEffect(() => {
    if (!isLastCMFRequestSuccess) return;
    if (debtFlow.CMFResponse) {
      navigate('/chat');
    } else {
      navigate('/onboarding/relif?redirectUrl=/chat?refetchFlag=1');
    }
  }, [debtFlow.CMFResponse, isLastCMFRequestSuccess]);

  return (
    <Box
      sx={{ backgroundColor: '#FBFCFE', zoom: '100%' }}
      display="flex"
      flexDirection="column"
      minHeight="100vh"
      justifyContent="space-between"
    >
      <FinancialAdvisorAuthModal
        open={isSignUpModalOpen}
        onClose={() => setIsSignUpModalOpen(false)}
        companyId={RELIF_FINANCIAL_ADVISOR_COMPANY_ID}
        defaultIsExistingUser={false}
        source={SOURCE}
      />
      <Navigation handleOpenFinancialAdvisorAuthModal={handleOpenFinancialAdvisorAuthModal} />
      {/* Hero Section */}
      <section className={styles.heroSection}>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} lg={6}>
              {/* <HeaderLogos origin="UniqueFlow" /> */}
              <Box className={styles.heroContent}>
                <Typography variant="h2" className={styles.heroTitle}>
                  Simula y cotiza con las <span className={styles.highlight}>mejores tasas</span> del mercado
                </Typography>
                <Box className={styles.featuresList}>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Tasas exclusivas para usuarios Planer</Typography>
                  </Box>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Gestiona online</Typography>
                  </Box>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Aprobación rápida</Typography>
                  </Box>
                  <Box className={styles.featureItem}>
                    <CheckCircleIcon className={styles.featureIcon} />
                    <Typography variant="body1">Paga justo por tus deudas</Typography>
                  </Box>
                </Box>
              </Box>
              {!isMobile && (
                <Box className={styles.heroContent}>
                  <BankCarousel />
                </Box>
              )}
            </Grid>
            <Grid item xs={12} lg={6}>
              <Box className={styles.simulatorContainer}>
                <CreditSimulatorPage onPreApprovalClick={handlePreApprovalClick} />
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Typography
              variant="caption"
              color="text.secondary"
              sx={{ fontSize: isMobile ? '0.6rem' : '0.8rem', display: 'block' }}
            >
              * La CAE (Carga Anual Equivalente) de 15,0607% y el costo total del crédito de $6.250.282 están calculados
              con tasa de interés de 1,19% mensual (14,28% anual) correspondiente a $5.042.591, en 36 cuotas mensuales e
              iguales, con pago de la primera cuota a 30 días del curse, e incluye el costo de impuesto de timbres y
              estampillas, y notario. Los montos recién indicados, son referenciales. Tasa válida desde el día
              11-03-2025 hasta el día 17-03-2025, ambos inclusive, tasa válida únicamente para contratación en línea. Su
              aprobación y otorgamiento está sujeto a la evaluación del cliente y al cumplimiento de las políticas de
              riesgo, de crédito y comerciales de Banco Internacional. Infórmese sobre la garantía estatal de los
              depósitos en su banco o en www.cmfchile.cl
            </Typography>
          </Box>
        </Container>
      </section>
      <Footer />
    </Box>
  );
};

export default LandingPageSimulator;
