import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const YellowSeparator = () => (
  <svg
    width="100%"
    preserveAspectRatio="none"
    height="2"
    viewBox="0 0 180 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.00003L180 1.00005" stroke="#F9D00A" strokeWidth="2" />
  </svg>
);

const FooterContacts = (title: string, content: string) => (
  <Box px={{ xs: 3, lg: 0 }} pb={{ xs: 1.5, lg: 0 }}>
    <Box width="min-content" display="inline-grid">
      <Typography
        fontFamily="Roboto"
        fontSize={{ lg: '15px', xs: '16px' }}
        lineHeight="24px"
        fontWeight="600"
        textTransform="uppercase"
        color="white"
      >
        {title}
      </Typography>
      <YellowSeparator />
    </Box>
    <Typography
      fontFamily="Jost"
      fontSize={{ lg: '15px', xs: '14px' }}
      lineHeight="24px"
      fontWeight="regular"
      color="white"
    >
      {content}
    </Typography>
  </Box>
);

const Footer: FC = () => {
  return (
    <Grid>
      <Box
        display="flex"
        flexDirection={{ lg: 'row', xs: 'column' }}
        justifyContent="space-around"
        textAlign={{ xs: 'center', lg: 'start' }}
        sx={{ backgroundColor: '#15183A' }}
        py={{ xs: 3, lg: 8 }}
      >
        <Typography
          fontFamily="Roboto"
          fontSize={{ lg: '22px', xs: '18px' }}
          lineHeight="24px"
          fontWeight="600"
          color="white"
          px={{ xs: 3, lg: 0 }}
          pb={{ xs: 2, lg: 0 }}
        >
          CONTACTO
        </Typography>
        {FooterContacts('Dirección', 'Av. Vitacura 4380, piso 2, Vitacura, Santiago')}
        {FooterContacts('Teléfono', '+56 9 9226 8245')}
        {FooterContacts('Correo', 'contacto@relif.cl')}
      </Box>
    </Grid>
  );
};

export default Footer;
