import React, { FC } from 'react';
import { useParams } from 'react-router-dom';

import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';

import logo from 'assets/images/logo.webp';
import logoBChile from 'assets/images/logos/banco-de-chile-long.png';
import logoCajaAndes from 'assets/images/logos/caja-los-andes-blue.svg';
import logoBuk from 'assets/images/logos/companies/buk.webp';
import { Origin } from 'services/types/origin';

import styles from '../UniqueDebtFlow.module.scss';
import COMPANIES from './Companies';

interface Props {
  origin: Origin;
}

const HeaderLogos: FC<Props> = ({ origin }) => {
  const { companyName } = useParams<{ companyName: string }>();
  const company = COMPANIES.find((x) => x.id === companyName);

  if (!company) {
    return (
      <Box display="flex" alignContent="center" justifyContent="center" alignItems="center">
        <img src={logo} alt="logo" className={styles.logoStyle} />
        {(origin === 'Banco de Chile' || origin === 'Caja Los Andes') && (
          <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: '30px' }} />
        )}
        {origin === 'Banco de Chile' && <img src={logoBChile} alt="logo" className={styles.secondaryLogoStyle} />}
        {origin === 'Caja Los Andes' && <img src={logoCajaAndes} alt="logo" className={styles.secondaryLogoStyle} />}
        {origin === 'Buk' && <img src={logoBuk} alt="logo" className={styles.secondaryLogoStyle} />}
      </Box>
    );
  }
  return (
    <Box display="flex" alignContent="center" justifyContent="center" alignItems="center">
      <img src={logo} alt="logo" className={styles.logoStyle} />
      {(origin === null || origin === 'UniqueFlow' || origin === 'Caja Los Andes') && (
        <>
          {company.masterCompanyLogo && (
            <>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: '30px' }} />
              <img src={company.masterCompanyLogo} alt={company.name} className={styles.secondaryLogoStyle} />
            </>
          )}
          <Divider orientation="vertical" variant="middle" flexItem sx={{ marginLeft: '30px' }} />
          {company.id === 'rrss' ? (
            <Box display="flex" alignItems="center" pl="25px">
              <InstagramIcon style={{ fontSize: '40px' }} />
              <YouTubeIcon style={{ fontSize: '40px' }} />
              <FacebookIcon style={{ fontSize: '40px' }} />
            </Box>
          ) : (
            <img src={company.logo} alt={company.name} className={styles.secondaryLogoStyle} />
          )}
        </>
      )}
    </Box>
  );
};
export default HeaderLogos;
