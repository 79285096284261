import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { BackIcon } from 'assets/icons';
import PrimaryBtn from 'components/UI/Buttons';
import PaperContainer from 'components/UI/PaperContainer';
import SectionHeader from 'components/layout/SectionHeader';

interface Props {
  title?: string;
  isHome?: boolean;
}

const NewHomePaper: FC<Props> = ({ title, isHome, children }) => {
  const navigate = useNavigate();
  const returnButton = () => (
    <PrimaryBtn onClick={() => navigate(-1)} startIcon={<BackIcon />}>
      <Typography variant="inherit" sx={{ display: { xs: 'none', lg: 'block' }, ml: 0.8 }}>
        Volver
      </Typography>
    </PrimaryBtn>
  );

  return (
    <Box>
      <SectionHeader text={title || ''} button={!isHome && returnButton()} />
      <Box
        sx={{
          mt: 2,
          textAlign: 'start',
          pr: { xs: 0, lg: 8, xl: 10 },
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <PaperContainer fullWidth>{children}</PaperContainer>
      </Box>
    </Box>
  );
};

NewHomePaper.defaultProps = {
  title: 'Comprar tu Casa Propia',
  isHome: false,
};

export default NewHomePaper;
