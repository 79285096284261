import React, { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Box from '@mui/material/Box';

import PrimaryBtn from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import FinancialQuizModal from 'components/common/FinancialQuizModal';
import useDebtFlow from 'hooks/useDebtFlow';
import { useListDebtsQuery, useRefinanceDebtsMutation } from 'services/api/debt.api';
import { useGetCMFRequestLast30DaysQuery, useGetLastCMFRequestQuery } from 'services/api/floid.api';
import { useGetQuizResultByNameQuery } from 'services/api/quizResult.api';
import { useGetRefinanceStatusQuery, useGetUserDebtProfileQuery } from 'services/api/user.api';
import { Debt as DebtInterface } from 'services/types/debt';

import ClaveUnicaDebtForm from './components/DebtFlow/ClaveUnicaDebtForm';
import DebtSuccessDialog from './components/DebtFlow/SuccessDialog';
import DebtSectionHeader from './components/DebtSectionHeader';
import DebtTracesDrawer from './components/DebtTracesDrawer';
import DebtsTable from './components/DebtsTable';
import InitialPaper from './components/InitialPaper';
import DrawerDebtForm from './components/ManualDebt/DrawerDebtForm';
import RefinanceStatusBox from './components/RefinanceStatus/RefinanceStatusBox';
import RefinanceStatusDrawer from './components/RefinanceStatus/RefinanceStatusDrawer';

const DebtCreation: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [claveUnicaDebtFormOpen, setClaveUnicaDebtFormOpen] = useState(false);
  const [drawerDebtFormOpen, setDrawerDebtFormOpen] = useState(false);
  const [debtToEdit, setDebtToEdit] = useState<DebtInterface | null>(null);
  const [debtTracesDrawerOpen, setDebtTracesDrawerOpen] = useState(false);
  const [debtToViewTraces, setDebtToViewTraces] = useState<DebtInterface | null>(null);
  const [openClaveUnicaSuccessMessage, setOpenClaveUnicaSuccessMessage] = useState(false);
  const [openRefinanceStatusDrawer, setOpenRefinanceStatusDrawer] = useState(false);
  const [isCMFUpdate, setIsCMFUpdate] = useState(false);
  const [shouldOpenFinancialQuiz, setShouldOpenFinancialQuiz] = useState(false);

  const openFinancialQuiz = shouldOpenFinancialQuiz && !drawerDebtFormOpen && !claveUnicaDebtFormOpen;

  const debtFlow = useDebtFlow();

  const { isLoading: isDebtsLoading, isFetching: isDebtsFetching, refetch: refetchDebtData } = useListDebtsQuery(null);

  const { isFetching: isLast30DayCMFRequestLoading, refetch: refetchGetCMFRequestLast30Days } =
    useGetCMFRequestLast30DaysQuery(null);

  const {
    isLoading: isLastCMFRequestLoading,
    isFetching: isLastCMFRequestFetching,
    refetch: refetchLastCMFRequest,
  } = useGetLastCMFRequestQuery(null);

  const { isFetching: isDebtProfileLoading, refetch: refetchUserDebtProfile } = useGetUserDebtProfileQuery(null);

  const [refinanceDebts, { data: refinanceDebtsData, isLoading: isRefinanceLoading }] = useRefinanceDebtsMutation();

  const { data: refinanceStatus, refetch: refetchRefinanceStatus } = useGetRefinanceStatusQuery(null);

  const { data: financialQuiz } = useGetQuizResultByNameQuery('financialQuiz');

  const showDebtList = debtFlow.isDebtAssignmentComplete && !claveUnicaDebtFormOpen;

  const showInitialPaper = !showDebtList && !claveUnicaDebtFormOpen && !drawerDebtFormOpen;

  const refetchCMFData = async () => {
    await Promise.all([refetchGetCMFRequestLast30Days(), refetchLastCMFRequest()]);
  };

  const handleRefinanceDebts = async () => {
    const debtsToRefinanceIds = debtFlow?.debts?.filter((debt) => !debt.refinance).map((debt) => debt.id) || [];
    await refinanceDebts({
      debtIds: debtsToRefinanceIds,
    });
    await refetchDebtData();
  };

  const handleOpenEditModal = (debt: DebtInterface) => {
    setDebtToEdit(debt);
    setDrawerDebtFormOpen(true);
  };

  const handleOpenDebtTracesDrawer = (debt: DebtInterface) => {
    setDebtToViewTraces(debt);
    setDebtTracesDrawerOpen(true);
  };

  const handleCloseDebtTracesDrawer = () => {
    setDebtToViewTraces(null);
    setDebtTracesDrawerOpen(false);
  };

  const handleAddDebts = () => {
    if (!debtFlow.isDebtAssignmentComplete) {
      setIsCMFUpdate(false);
      setClaveUnicaDebtFormOpen(true);
    } else {
      setDrawerDebtFormOpen(true);
    }
  };

  const handleDebtFlowSuccess = () => {
    console.log('handleDebtFlowSuccess'); // eslint-disable-line no-console
    refetchRefinanceStatus().catch((e) => console.error(e)); // eslint-disable-line no-console
    refetchDebtData().catch((e) => console.error(e)); // eslint-disable-line no-console
    setOpenClaveUnicaSuccessMessage(true);
    setClaveUnicaDebtFormOpen(false);
    refetchUserDebtProfile().catch((e) => console.error(e)); // eslint-disable-line no-console
    refetchCMFData().catch((e) => console.error(e)); // eslint-disable-line no-console
  };

  const handleRefreshDebts = () => {
    setIsCMFUpdate(true);
    setClaveUnicaDebtFormOpen(true);
  };

  useEffect(() => {
    if (searchParams.get('openClaveUnica') === 'true') {
      setClaveUnicaDebtFormOpen(true);
      searchParams.delete('openClaveUnica');
      setSearchParams(searchParams);
    }
  }, []);

  useEffect(() => {
    if (refinanceDebtsData) {
      refetchDebtData().catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
    }
  }, [refinanceDebtsData]);

  useEffect(() => {
    if (debtFlow.shouldUpdateDebts && debtFlow.hasCMFResponseInLast30Days && !isDebtsLoading && debtFlow.debts) {
      setIsCMFUpdate(true);
      setClaveUnicaDebtFormOpen(true);
    }
    const hasPendingDebtAssignment =
      !debtFlow.isDebtAssignmentComplete &&
      debtFlow.CMFResponse &&
      !isDebtsLoading &&
      !isLastCMFRequestLoading &&
      debtFlow.hasCMFResponseInLast30Days;
    if (hasPendingDebtAssignment) {
      setIsCMFUpdate(false);
      setClaveUnicaDebtFormOpen(true);
    }
  }, [debtFlow, isDebtsLoading, isLastCMFRequestLoading]);

  // when debt drawer is closed, refetch debts
  useEffect(() => {
    if (!drawerDebtFormOpen) {
      refetchDebtData().catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [drawerDebtFormOpen]);

  // refetch CMF data constantly to check if user has updated it
  useEffect(() => {
    refetchLastCMFRequest().catch((e) => console.error(e)); // eslint-disable-line no-console
    refetchGetCMFRequestLast30Days().catch((e) => console.error(e)); // eslint-disable-line no-console
  }, []);

  useEffect(() => {
    if (financialQuiz === null || (financialQuiz && financialQuiz.status === 'started')) {
      setShouldOpenFinancialQuiz(true);
    } else {
      setShouldOpenFinancialQuiz(false);
    }
  }, [financialQuiz]);

  return (
    <Box>
      <LoadingOverlay
        visible={
          isDebtsFetching ||
          isLastCMFRequestFetching ||
          isDebtProfileLoading ||
          isLast30DayCMFRequestLoading ||
          isRefinanceLoading
        }
      />
      {claveUnicaDebtFormOpen && <ClaveUnicaDebtForm isCMFUpdate={isCMFUpdate} onSuccess={handleDebtFlowSuccess} />}
      <DebtSuccessDialog
        open={openClaveUnicaSuccessMessage}
        setOpen={setOpenClaveUnicaSuccessMessage}
        refinanceStatus={refinanceStatus || null}
      />
      <DrawerDebtForm
        debtToEdit={debtToEdit}
        setDebtToEdit={setDebtToEdit}
        isFormOpen={drawerDebtFormOpen}
        setFormOpen={setDrawerDebtFormOpen}
      />
      <DebtTracesDrawer
        isOpen={debtTracesDrawerOpen}
        onClose={() => {
          handleCloseDebtTracesDrawer();
        }}
        debt={debtToViewTraces}
        handleRefinanceDebts={() => {
          // eslint-disable-next-line no-console
          handleRefinanceDebts().catch((e) => console.error(e));
        }}
        handleEditDebt={handleOpenEditModal}
      />
      <RefinanceStatusDrawer
        open={openRefinanceStatusDrawer}
        onClose={() => {
          setOpenRefinanceStatusDrawer(false);
        }}
        refinanceStatus={refinanceStatus || null}
      />
      <FinancialQuizModal
        open={openFinancialQuiz}
        onClose={() => {
          setShouldOpenFinancialQuiz(false);
        }}
      />
      {showDebtList && (
        <>
          <DebtSectionHeader showRefreshBtn={debtFlow.shouldUpdateDebts} handleRefreshDebts={handleRefreshDebts} />
          <RefinanceStatusBox
            refinanceStatus={refinanceStatus || null}
            setOpenRefinanceStatusDrawer={setOpenRefinanceStatusDrawer}
            showRefreshBtn={debtFlow.shouldUpdateDebts}
            handleRefreshDebts={handleRefreshDebts}
          />
          <DebtsTable
            currentDebts={debtFlow?.debts || []}
            openEditDebtModal={handleOpenEditModal}
            getData={() => {
              // eslint-disable-next-line no-console
              refetchDebtData().catch((e) => console.error(e));
            }}
            handleOpenDebtTracesDrawer={handleOpenDebtTracesDrawer}
            handleAddDebts={handleAddDebts}
          />
        </>
      )}
      {showInitialPaper && (
        <InitialPaper
          button={
            <PrimaryBtn variant="contained" onClick={handleAddDebts}>
              Agregar Deudas
            </PrimaryBtn>
          }
          width={500}
          height={300}
          title="Aún no has agregado deudas"
          isLoading={isDebtsLoading}
          paragraph="Agrega tus deudas para ver tu salud financiera y cómo te ven los bancos."
        />
      )}
    </Box>
  );
};

export default DebtCreation;
