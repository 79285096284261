import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Team from './Team';

const BlueSeparator = () => (
  <svg
    width="30%"
    preserveAspectRatio="none"
    height="2"
    viewBox="0 0 180 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 1.00003L180 1.00005" stroke="#15183A" strokeWidth="2" />
  </svg>
);

const BenefitPoint: FC<{ text: string }> = ({ text }) => (
  <Box display="flex" alignItems="center" mb={2}>
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        width: '8px',
        height: '8px',
        borderRadius: '50%',
        backgroundColor: '#2C5889',
        mr: 2,
      }}
    />
    <Typography
      fontFamily="Roboto"
      fontWeight="500"
      fontSize={{ xs: '18px', sm: '20px' }}
      lineHeight={{ xs: '24px', sm: '28px' }}
      color="#15183A"
    >
      {text}
    </Typography>
  </Box>
);

const WhoWeAre: FC = () => {
  return (
    <Grid pt={24}>
      <Box px={2}>
        <Typography
          fontFamily="Roboto"
          fontWeight="800"
          fontSize={{ xs: '27px', sm: '45px' }}
          lineHeight={{ xs: '34px', sm: '50px' }}
          color="#2C5889"
          textAlign="center"
        >
          Planer es una plataforma que ayuda a sus usuarios
          <br />
          <span style={{ color: '#15183A' }}> a gestionar procesos de manera 100% digital,</span>
          <br />
          mejorando sus finanzas personales.
        </Typography>
      </Box>

      <Box display="flex" justifyContent="center" width="100%" py={6}>
        <BlueSeparator />
      </Box>

      <Box px={{ xs: 4, sm: 0 }} py={2}>
        <Box maxWidth="900px" mx="auto">
          <BenefitPoint text="Tasas exclusivas para usuarios Planer." />
          <BenefitPoint text="Paga menos por tus créditos y ajústalos a tus plazos." />
          <BenefitPoint text="Lo gestionamos por ti, podremos simular tu crédito o refinanciamiento ideal e ir a buscar mejores opciones para ti." />
          <BenefitPoint text="Te armamos tu mejor plan financiero." />
        </Box>
      </Box>

      <Box sx={{ background: 'linear-gradient(to left, #15183A, #638DB8)', mb: { xs: 4, sm: 8 } }}>
        <Typography
          fontFamily="Roboto"
          fontSize="22px"
          lineHeight="25px"
          fontWeight="500"
          textAlign="center"
          color="white"
          p={{ xs: 2, sm: 4 }}
        >
          Estamos construyendo el futuro, súmate!
        </Typography>
      </Box>

      <Team />
    </Grid>
  );
};

export default WhoWeAre;
