import React from 'react';

import Fab from '@mui/material/Fab';
import { styled } from '@mui/material/styles';

import { WhatsAppIcon } from 'assets/icons';

const CustomFab = styled(Fab)(({ theme }) => ({
  backgroundColor: '#25d366',
  position: 'fixed',
  bottom: '10px',
  right: '10px',
  height: '45px',
  width: '45px',
  '&:hover': {
    backgroundColor: '#65df92',
  },
  [theme.breakpoints.up('sm')]: {
    bottom: '20px',
    right: '20px',
    height: '58px',
    width: '58px',
  },
}));

const ChatButton = () => {
  return (
    <a
      href="https://wa.me/%2B56948829975?text=Hola%21+Me+gustaría+saber+más+sobre+Planer"
      target="_blank"
      rel="noreferrer"
    >
      <CustomFab>
        <WhatsAppIcon sx={{ color: 'white', fontSize: { xs: '25px', xl: '30px' } }} />
      </CustomFab>
    </a>
  );
};

export default ChatButton;
