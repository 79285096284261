import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import { CloseIcon } from 'assets/icons';

const PrimaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.info.main,
  backgroundColor: theme.palette.info.light,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  paddingRight: '12px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.info.main,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

const SecondaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  // TODO: check if this is necessary
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.contrastText,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

interface BtnProp extends ButtonProps {
  icon: React.ReactNode;
  text: string;
  behaviour?: 'fijo' | 'adaptable';
}

const SectionHeaderBtn: React.FC<BtnProp> = ({ icon, text, behaviour, ...props }) => {
  return (
    <PrimaryBtn {...props}>
      {icon}
      <Typography variant="inherit" sx={{ display: { xs: 'none', lg: 'block' }, ml: 0.8 }}>
        {text}
      </Typography>
    </PrimaryBtn>
  );
};

SectionHeaderBtn.defaultProps = {
  behaviour: 'adaptable',
};

const EditBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  // TODO: check if this is necessary
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
}));

const SmallIconBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.info.main,
  backgroundColor: theme.palette.info.light,
  '&:hover': {
    backgroundColor: theme.palette.info.main,
    color: '#fff',
  },
  // TODO: check if this is necessary
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  borderRadius: '50%',
  width: '43px',
  height: '43px',
  // display the icon in the center
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const EditBtnLarge = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  borderRadius: '50px',
  paddingLeft: '16px',
  paddingRight: '16px',
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  [theme.breakpoints.up('lg')]: {
    borderRadius: '20px',
  },
}));

const DeleteBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
}));

const DeleteBtnLarge = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

const CancelBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '20px',
  padding: '0px 27px',
  lineHeight: '22px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.error.main}`,
  color: theme.palette.error.main,
  height: '43px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: theme.palette.error.light,
  },
}));

const BlackBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: '20px',
  padding: '0px 27px',
  lineHeight: '22px',
  backgroundColor: 'transparent',
  border: `1px solid ${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  height: '43px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#EDF1FA',
  },
}));

interface CajaStatusButtonProps {
  text: string;
  onClick: () => void;
  value: number;
}

const CajaStatusButton: React.FC<CajaStatusButtonProps> = ({ text, onClick, value }) => {
  return (
    <Button
      variant="contained"
      color={value === 100 ? 'warning' : 'info'}
      onClick={onClick}
      startIcon={
        value < 100 ? (
          <CircularProgress thickness={7} sx={{ color: '#3E4545' }} size={18} value={value} variant="determinate" />
        ) : (
          <Box
            sx={{
              display: 'flex',
              borderRadius: '50%',
              border: '2px solid',
              borderColor: 'black',
              width: '18px',
              height: '18px',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CheckIcon sx={{ fontSize: '14px', color: 'black' }} />
          </Box>
        )
      }
    >
      {text}
    </Button>
  );
};

const PrimaryBtnWithIcon: React.FC<BtnProp> = ({ icon, text, behaviour, ...props }) => {
  return (
    <PrimaryBtn {...props}>
      {icon}
      <Typography variant="inherit" sx={{ display: { xs: 'none', lg: 'block' }, ml: 0.8 }}>
        {text}
      </Typography>
    </PrimaryBtn>
  );
};

PrimaryBtnWithIcon.defaultProps = {
  behaviour: 'adaptable',
};

const CloseButton: React.FC<IconButtonProps> = ({ ...props }) => {
  return (
    <IconButton {...props}>
      <CloseIcon />
    </IconButton>
  );
};

export default PrimaryBtn;
export {
  PrimaryBtn,
  SecondaryBtn,
  EditBtn,
  DeleteBtn,
  CancelBtn,
  BlackBtn,
  EditBtnLarge,
  DeleteBtnLarge,
  SectionHeaderBtn,
  PrimaryBtnWithIcon,
  CloseButton,
  CajaStatusButton,
  SmallIconBtn,
};
