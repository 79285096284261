/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search';
import Send from '@mui/icons-material/Send';
import ThumbDown from '@mui/icons-material/ThumbDown';
import ThumbUp from '@mui/icons-material/ThumbUp';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useIsAdmin from 'hooks/useIsAdmin';
import {
  useCreateQuestionMutation,
  useGetLastQuestionQuery,
  useLikeRequestMutation,
} from 'services/api/openAIRequests';

const MAX_LENGTH = 120;

const PersonalFinanceTips = [
  'Establece un presupuesto: El primer paso para mejorar tus finanzas es saber cuánto dinero estás ganando y gastando. Crea un presupuesto realista y trata de seguirlo lo mejor que puedas.',
  'Reduce tus gastos innecesarios: Revisa tus gastos mensuales y busca áreas donde puedas reducir, como comer fuera de casa, comprar ropa o entretenimiento',
  'Ahorra para emergencias: Ahorra para emergencias y gastos inesperados, como reparaciones del hogar, gastos médicos, etc. Siempre es mejor estar preparado para cualquier eventualidad.',
  'Paga tus deudas: Si tienes deudas, trata de pagarlas lo antes posible. Paga la cantidad mínima mensual y, si puedes, más. Reducir tus deudas te permitirá ahorrar dinero a largo plazo.',
  'Invierte en ti mismo: Asegúrate de que estás invirtiendo en ti mismo, ya sea en educación, desarrollo profesional o salud. Estas inversiones pueden tener un impacto positivo en tu situación financiera a largo plazo',
  'Busca oportunidades de ingresos adicionales: Busca oportunidades de ingresos adicionales, como trabajos temporales, trabajos de medio tiempo o ventas de productos.',
];

const AIAssistant = () => {
  const [question, setQuestion] = useState('');
  const [askAsAdmin, setAskAsAdmin] = useState(false);
  const [answer, setAnswer] = useState('');
  const [maxLengthError, setMaxLengthError] = useState(false);
  const [likedByUser, setLikedByUser] = useState<boolean | null>(null);
  const [showAnswer, setShowAnswer] = useState(false);
  const [createQuestion, { data: questionResponse, isLoading: isQuestionLoading, isError: isQuestionError }] =
    useCreateQuestionMutation();
  const [likeRequest, { data: likeResponse }] = useLikeRequestMutation();
  const [currentTip, setCurrentTip] = useState(0);

  const isAdmin = useIsAdmin();

  const {
    data: lastQuestionData,
    refetch: refetchLastQuestion,
    isLoading: isLastQuestionLoading,
  } = useGetLastQuestionQuery(null);

  const handleQuestion = () => {
    createQuestion({ question, askAsAdmin }).catch((err) => {
      // eslint-disable-next-line no-console
      console.log(err);
    });
    setShowAnswer(true);
  };

  const handleLike = (like: boolean) => {
    if (questionResponse) {
      likeRequest({
        id: questionResponse.id,
        body: { like },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    } else if (lastQuestionData) {
      likeRequest({
        id: lastQuestionData.id,
        body: { like },
      }).catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    }
  };

  const navigate = useNavigate();

  const getQuestionAnswer = () => {
    if (answer) {
      const newAnswer = answer;
      // get links from answer (they come between ())
      const links = newAnswer.match(/\(([^)]+)\)/g);
      // replace links with anchor tags
      let newAnswerWithLinks = newAnswer;
      if (links) {
        links.forEach((link) => {
          const linkText = link.replace('(', '').replace(')', '');
          const redirectLink = linkText.slice(linkText.indexOf('/') + 1);
          newAnswerWithLinks = newAnswerWithLinks.replace(
            link,
            `<a href="/${redirectLink}" style="color: #3f51b5; text-decoration: none;"> (Ir a esta sección) </a>`
          );
        });
      }
      // eslint-disable-next-line react/no-danger
      return <Typography variant="body1" fontWeight={400} dangerouslySetInnerHTML={{ __html: newAnswerWithLinks }} />;
    }
    return '';
  };

  const contentClickHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // get closest anchor tag in tsx
    const closestAnchor = (e.target as HTMLElement).closest('a');
    e.preventDefault();
    if (closestAnchor) {
      // get href from anchor tag
      const href = closestAnchor.getAttribute('href');
      if (href) {
        // navigate to href
        navigate(href);
      }
    }
  };

  const handleMaxLength = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (e.target.value.length > MAX_LENGTH) {
      setMaxLengthError(true);
    } else {
      setMaxLengthError(false);
    }
  };

  const handleQuestionInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuestion(e.target.value);
    handleMaxLength(e);
  };

  useEffect(() => {
    if (questionResponse) {
      setAnswer(questionResponse.answer);
      setLikedByUser(null);
    } else if (lastQuestionData && !isQuestionLoading) {
      setQuestion(lastQuestionData.question);
      setAnswer(lastQuestionData.answer);
      setLikedByUser(lastQuestionData.likedByUser);
    }
  }, [questionResponse, lastQuestionData]);

  useEffect(() => {
    if (likeResponse) {
      setLikedByUser(likeResponse.likedByUser);
    }
  }, [likeResponse]);

  useEffect(() => {
    refetchLastQuestion().catch((e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    });
  }, []);

  useEffect(() => {
    if (isQuestionError) {
      setAnswer('Tuve un problema para procesar tu pregunta 😵‍💫, intenta de nuevo.');
    }
  }, [isQuestionError]);

  // change tip every 10 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTip((currentTip + 1) % PersonalFinanceTips.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [currentTip]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item md={12} lg={9} xl={10} py={1} display="flex" alignItems="end">
          <Box width="100%">
            <TextField
              label="Escribe tu pregunta: Por ejemplo, ¿Cómo ahorro?, ¿Como salgo de DICOM?, ¿Que hago con mi deuda de la CMR?"
              placeholder="Escribe tu pregunta"
              value={question}
              onChange={handleQuestionInput}
              onKeyDown={(e) => e.key === 'Enter' && handleQuestion()}
              error={maxLengthError}
              helperText={maxLengthError ? 'La pregunta debe ser más corta' : ''}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Grid>
        <Grid item md={12} lg={3} xl={2} py={1} display="flex" alignItems="end">
          {isQuestionLoading ? (
            <Box display="flex" px={{ md: 0, lg: 2 }}>
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Box display="flex" px={{ md: 0, lg: 2 }}>
              <Button
                variant="contained"
                onClick={handleQuestion}
                disabled={question.length === 0 || isQuestionLoading || maxLengthError}
                startIcon={<Send />}
              >
                Preguntar
              </Button>
            </Box>
          )}
          <IconButton
            onClick={() => setShowAnswer(!showAnswer)}
            disabled={isQuestionLoading || isLastQuestionLoading}
            sx={{
              backgroundColor: 'lightgray',
              '&:hover': {
                backgroundColor: 'lightgray',
              },
              borderRadius: '100%',
              height: '48px',
              width: '48px',
              ml: { xs: 1, lg: 0 },
            }}
          >
            {showAnswer ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Grid>
        {isAdmin && (
          <FormControlLabel
            control={
              <Checkbox
                checked={askAsAdmin}
                onChange={() => setAskAsAdmin(!askAsAdmin)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            }
            label="Preguntar como administrador"
          />
        )}
        {showAnswer && (
          <Grid item xs={12} p={1}>
            <>
              <Box
                sx={{
                  // chat bubble
                  position: 'relative',
                  padding: 3,
                  marginRight: '20px',
                  borderRadius: '10px 10px 0px 10px',
                  borderColor: 'info.main',
                  backgroundColor: 'info.light',
                }}
              >
                {isQuestionLoading || isLastQuestionLoading ? (
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        mt: 1,
                      }}
                    >
                      <Typography variant="body1">Estoy pensando...</Typography>
                      <Box sx={{ ml: 1 }}>
                        <CircularProgress size={10} />
                      </Box>
                    </Box>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      Esto puede tardar un poco, mientras tanto te dejo algunas recomendaciones:
                    </Typography>
                    <Typography variant="body1" sx={{ mt: 1 }}>
                      {PersonalFinanceTips[currentTip]}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: '100%',
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'left',
                      justifyContent: 'flex-start',
                    }}
                    onClick={contentClickHandler}
                  >
                    {getQuestionAnswer()}
                    <Typography variant="caption" sx={{ mt: 1 }} fontSize="16px">
                      Toma en consideración que soy un robot y entrego recomendaciones generales. Si quieres hablar con
                      una persona real puedes ocupar nuestro chat o agendar una hora con nosotros.
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  fontSize: '1.5rem',
                  position: 'relative',
                  top: '-1.5rem',
                  right: '-10px',
                }}
              >
                🤖
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  px: 2,
                }}
              >
                <Typography variant="caption" fontSize="16px">
                  ¿Te pareció útil esta respuesta?
                </Typography>
                <Box sx={{ ml: 1 }}>
                  <IconButton sx={{ color: likedByUser ? 'info.main' : 'inherit' }} onClick={() => handleLike(true)}>
                    <ThumbUp />
                  </IconButton>
                </Box>
                <Box sx={{ ml: 1 }}>
                  <IconButton
                    sx={{ color: likedByUser === false ? 'error.main' : 'inherit' }}
                    onClick={() => handleLike(false)}
                  >
                    <ThumbDown />
                  </IconButton>
                </Box>
              </Box>
            </>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AIAssistant;
