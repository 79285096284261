import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import AttachMoney from '@mui/icons-material/AttachMoney';
import CalendarToday from '@mui/icons-material/CalendarToday';
import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import useDebtFlow from 'hooks/useDebtFlow';
import useIsMobile from 'hooks/useIsMobile';

import styles from '../CreditSimulator.module.scss';
import PreApprovalButton from './PreApprovalButton';

interface Props {
  onPreApprovalClick?: () => void;
}

const CreditSimulator: React.FC<Props> = ({ onPreApprovalClick }) => {
  const { isMobile } = useIsMobile();
  const location = useLocation();
  const initialCAE = location.pathname.includes('/buk/dashboard') ? 13.1 : 14.3;
  const [loanAmount, setLoanAmount] = useState(1000000);
  const [loanTerm, setLoanTerm] = useState(12);
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [monthlyPaymentOld, setMonthlyPaymentOld] = useState(0);
  const [currentCAE, setCurrentCAE] = useState(initialCAE);

  const debtFlow = useDebtFlow();
  const redirectUrl = debtFlow.CMFResponse ? '/chat' : '/onboarding/buk';

  const convertCAEtoMonthlyRate = (cae: number) => {
    return cae / 100 / 12;
  };

  const OLD_CAE = 26; // Base comparison rate stays the same
  const effectiveMonthlyRate = convertCAEtoMonthlyRate(currentCAE);
  const oldEffectiveMonthlyRate = convertCAEtoMonthlyRate(OLD_CAE);

  const calculateMonthlyPayment = (rate: number, amount: number, term: number) => {
    if (amount > 0 && term > 0) {
      return (amount * rate) / (1 - (1 + rate) ** -term);
    }
    return 0;
  };

  useEffect(() => {
    setMonthlyPayment(calculateMonthlyPayment(effectiveMonthlyRate, loanAmount, loanTerm));
    setMonthlyPaymentOld(calculateMonthlyPayment(oldEffectiveMonthlyRate, loanAmount, loanTerm));
  }, [loanAmount, loanTerm, currentCAE, effectiveMonthlyRate, oldEffectiveMonthlyRate]);

  const handleLoanAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseFloat(event.target.value.replace(/,/g, ''));
    if (!Number.isNaN(value)) {
      setLoanAmount(value);
    } else {
      setLoanAmount(0);
    }
  };

  const handleLoanTermChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target.value, 10);
    if (!Number.isNaN(value)) {
      setLoanTerm(value);
    } else {
      setLoanTerm(0);
    }
  };

  const handleRateChange = (_: Event, value: number | number[]) => {
    setCurrentCAE(value as number);
  };

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('es-CL', {
      style: 'currency',
      currency: 'CLP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const totalPayment = monthlyPayment * loanTerm;
  const totalInterest = totalPayment - loanAmount;

  return (
    <>
      <Box className={styles.header} sx={{ p: isMobile ? 2 : 3 }}>
        <Typography variant="h5" component="h1" className={styles.sectionTitle}>
          <AttachMoney sx={{ fontSize: 24 }} />
          Calcula tu cuota mensual ideal
        </Typography>
      </Box>
      <CardContent sx={{ p: isMobile ? 1.5 : 3 }}>
        <Grid container spacing={isMobile ? 1.5 : 3}>
          <Grid item xs={12} md={6}>
            <Box className={styles.inputContainer}>
              <Box className={styles.inputLabelContainer}>
                <Typography className={styles.inputLabel}>Monto a solicitar</Typography>
              </Box>
              <Box className={styles.inputWrapper}>
                <AttachMoney sx={{ color: 'text.secondary' }} />
                <TextField
                  fullWidth
                  value={loanAmount.toLocaleString()}
                  onChange={handleLoanAmountChange}
                  variant="outlined"
                  size={isMobile ? 'small' : 'medium'}
                  className={styles.styledTextField}
                  inputProps={{ maxLength: 9, readOnly: true }}
                />
              </Box>
              <Slider
                value={loanAmount}
                min={1000000}
                max={50000000}
                step={1000000}
                onChange={(_, value) => setLoanAmount(value as number)}
                className={styles.sliderContainer}
              />
              <Box className={styles.sliderLabels}>
                <Typography variant="caption">$1.000.000</Typography>
                <Typography variant="caption">$50.000.000</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box className={styles.inputContainer}>
              <Box className={styles.inputLabelContainer}>
                <Typography className={styles.inputLabel}>Plazo en meses</Typography>
              </Box>
              <Box className={styles.inputWrapper}>
                <CalendarToday sx={{ color: 'text.secondary' }} />
                <TextField
                  fullWidth
                  type="number"
                  value={loanTerm}
                  onChange={handleLoanTermChange}
                  variant="outlined"
                  size={isMobile ? 'small' : 'medium'}
                  className={styles.styledTextField}
                  inputProps={{ maxLength: 2, readOnly: true }}
                />
              </Box>
              <Slider
                value={loanTerm}
                min={1}
                max={48}
                step={1}
                onChange={(_, value) => setLoanTerm(value as number)}
                className={styles.sliderContainer}
              />
              <Box className={styles.sliderLabels}>
                <Typography variant="caption">1 mes</Typography>
                <Typography variant="caption">48 meses</Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ pt: 2, borderTop: 1, borderColor: 'divider' }}>
              <Box
                className={styles.inputLabelContainer}
                sx={{
                  display: 'flex',
                  alignItems: isMobile ? 'flex-start' : 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 1,
                    width: isMobile ? '100%' : 'auto',
                    mb: isMobile ? 2 : 0,
                  }}
                >
                  <Typography className={styles.inputLabel} sx={{ whiteSpace: 'nowrap' }}>
                    Tasa de interés:
                  </Typography>
                  <Box className={styles.resultValue} sx={{ display: 'inline-flex', alignItems: 'center', ml: 1 }}>
                    <Typography className={styles.oldValue}>{(oldEffectiveMonthlyRate * 100).toFixed(1)}%</Typography>
                    <Typography className={styles.newValue}>{(effectiveMonthlyRate * 100).toFixed(2)}%</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: isMobile ? '100%' : '300px',
                    ml: isMobile ? 0 : 4,
                    flex: isMobile ? 'none' : 1,
                  }}
                >
                  <Slider
                    value={currentCAE}
                    min={11.64}
                    max={30}
                    step={0.01}
                    onChange={handleRateChange}
                    className={styles.interestSliderContainer}
                  />
                  <Box className={styles.sliderLabels}>
                    <Typography variant="caption">0,97%</Typography>
                    <Typography variant="caption">2,5%</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className={styles.resultCard}>
                <Box sx={{ textAlign: 'center', mb: 3 }}>
                  <Typography variant="h6" sx={{ mb: 1 }}>
                    Tu pago mensual estimado
                  </Typography>
                  <Typography className={styles.largeNewValue}>{formatCurrency(monthlyPayment)}</Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box className={styles.resultItem}>
                      <Typography className={styles.resultLabel}>Total a pagar</Typography>
                      <Box className={styles.resultValue}>
                        <Typography className={styles.oldValue}>
                          {formatCurrency(monthlyPaymentOld * loanTerm)}
                        </Typography>
                        <Typography className={styles.newValue}>{formatCurrency(totalPayment)}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Box className={styles.resultItem}>
                      <Typography className={styles.resultLabel}>Total intereses</Typography>
                      <Box className={styles.resultValue}>
                        <Typography className={styles.oldValue}>
                          {formatCurrency(monthlyPaymentOld * loanTerm - loanAmount)}
                        </Typography>
                        <Typography className={styles.newValue}>{formatCurrency(totalInterest)}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box className={styles.buttonContainer}>
              <Box className={styles.ButtonWrapper}>
                <PreApprovalButton
                  text="Revisa si estas pre-aprobado"
                  route={redirectUrl}
                  onPreApprovalClick={onPreApprovalClick}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
};

CreditSimulator.defaultProps = {
  onPreApprovalClick: undefined,
};

export default CreditSimulator;
