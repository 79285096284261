import React, { useEffect, useState } from 'react';

import { Box, Typography, Stack, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { QuizResultAttributes } from '@relif/backend';
import PrimaryBtn, { CloseButton } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import { useGetOrCreateQuizResultMutation, useSaveQuizResultAnswerMutation } from 'services/api/quizResult.api';

interface Props {
  open: boolean;
  onClose: () => void;
}

const FinancialQuizModal = ({ open, onClose }: Props) => {
  const [quiz, setQuiz] = useState<QuizResultAttributes | null>(null);
  const [questions, setQuestions] = useState<QuizResultAttributes['questionsAndAnswers'][0][] | null>(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number | null>(null);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [getOrCreateQuizResult, { isLoading: isCreateQuizLoading, data: createdQuiz }] =
    useGetOrCreateQuizResultMutation();
  const [saveQuizAnswer, { isLoading: isSaveQuizAnswerLoading }] = useSaveQuizResultAnswerMutation();
  const currentQuestion = questions && currentQuestionIndex !== null ? questions[currentQuestionIndex] : null;
  const handleGetOrCreateQuizResult = () => {
    getOrCreateQuizResult({ quizName: 'financialQuiz' }).catch((e) => console.error(e)); // eslint-disable-line no-console
  };
  const handleSaveAnswer = (answer: string) => {
    if (quiz && currentQuestion && !isFinished) {
      saveQuizAnswer({ quizName: 'financialQuiz', body: { questionId: currentQuestion?.question.id, answer } })
        .then(() => {
          if (currentQuestion && questions) {
            const nextQuestionIndex = currentQuestionIndex !== null ? currentQuestionIndex + 1 : 0;
            if (nextQuestionIndex < questions.length) {
              setCurrentQuestionIndex(nextQuestionIndex);
            } else {
              setCurrentQuestionIndex(null);
              handleGetOrCreateQuizResult();
              setIsFinished(true);
            }
          }
        })
        .catch(
          (e) => console.error(e) // eslint-disable-line no-console
        );
    }
  };

  useEffect(() => {
    if (createdQuiz) {
      setQuiz(createdQuiz);
    }
    if (createdQuiz && !isFinished) {
      setQuestions(Object.values(createdQuiz.questionsAndAnswers));
      setCurrentQuestionIndex(0);
    }
  }, [createdQuiz]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ '& .MuiDialog-paper': { borderRadius: '25px' } }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack
        spacing={2}
        display="inline-grid"
        padding="20px"
        minHeight={isFinished ? 'auto' : '500px'}
        width={{ xs: '100%', lg: '500px' }}
      >
        <LoadingOverlay visible={isCreateQuizLoading || isSaveQuizAnswerLoading} />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle id="alert-dialog-title">Evaluación Financiera</DialogTitle>
          <CloseButton onClick={onClose} />
        </Box>
        <DialogContent>
          {!quiz ? (
            <>
              <DialogContentText id="alert-dialog-description">
                En Relif, nos preocupamos por tu bienestar financiero. Por eso, te invitamos a realizar una evaluación
                para conocer tu nivel de conocimiento en finanzas personales.
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">
                Son solo un par de preguntas, no te tomará más de 5 minutos.
              </DialogContentText>
              {/* spacer */}
              <Box height="20px" />
              <DialogActions>
                <PrimaryBtn onClick={handleGetOrCreateQuizResult} disabled={isCreateQuizLoading}>
                  Comenzar
                </PrimaryBtn>
              </DialogActions>
            </>
          ) : null}
          {currentQuestion ? (
            <>
              <Typography variant="h4" fontSize="20px" mb={2} textAlign="center">
                {currentQuestion.question.question}
              </Typography>
              <RadioGroup>
                {currentQuestion.question.options.map((option) => (
                  <FormControlLabel
                    key={option.label}
                    value={option.label}
                    control={<Radio />}
                    label={option.label}
                    onClick={() => handleSaveAnswer(option.label)}
                  />
                ))}
              </RadioGroup>
            </>
          ) : null}
          {isFinished ? (
            <>
              <DialogContentText id="alert-dialog-description">
                Obtuviste {quiz?.score} de {questions?.length} respuestas correctas.
              </DialogContentText>
              <DialogContentText id="alert-dialog-description">Estas eran las respuestas correctas:</DialogContentText>
              {questions?.map((question) => (
                <Box key={question.question.id}>
                  <Typography variant="h4" fontSize="16px" fontWeight={600} lineHeight={1.5}>
                    {question.question.question}
                  </Typography>
                  <Typography variant="h4" fontSize="14px" lineHeight={1.5}>
                    {question.question.options.find((option) => option.points > 0)?.label}
                  </Typography>
                </Box>
              ))}
              {/* spacer */}
              <Box height="20px" />
              <DialogActions>
                <PrimaryBtn onClick={onClose}>Terminar</PrimaryBtn>
              </DialogActions>
            </>
          ) : null}
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default FinancialQuizModal;
