/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { FC } from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';
import CLALink from 'components/UI/CLALink';

import BodyTypography from './components/BodyTypography';
import { DS19Definition, DS19WithSubsidy } from './components/DS19';
import Dropdown from './components/Dropdown';
import NewHomeBreadCrumbs from './components/NewHomeBreadCrumbs';
import NewHomePaper from './components/NewHomePaper';

const DS1: FC = () => (
  <Box mt={4}>
    <Dropdown title="Subsidio Clase Media para Compra de Viviendas (DS1)" titleSize="24px">
      <BodyTypography>
        El Subsidio Clase Media para Compra de Viviendas (DS1) es un aporte económico para familias de sectores medios
        que les permite adquirir una vivienda nueva o usada, ya sea en sectores urbanos o rurales.
        <Box mt={2}>
          El subsidio está destinado a familias que no son propietarias de una vivienda, que tienen capacidad de ahorro
          y pueden complementar el valor de la vivienda con recursos propios o con un{' '}
          <CLALink href="/new-home/mortgages">crédito hipotecario.</CLALink>
        </Box>
      </BodyTypography>
      <Typography mt={2} textAlign="start" fontSize="24px" fontWeight={500}>
        Requisitos:
      </Typography>
      <BodyTypography>
        Los requisitos para postular a este subsidio son los siguientes:
        <ul>
          <li>
            Tener 18 años cumplidos y contar con cédula nacional de identidad vigente.
            <ul>
              <li>
                Las personas extranjeras deben presentar, además, cédula de identidad para extranjeros/as con
                permanencia definitiva y certificado de Permanencia Definitiva (emitido por el Departamento de
                Extranjería del Ministerio del Interior o por Policía de Investigaciones de Chile).
              </li>
            </ul>
          </li>
          <li>No ser dueño de una vivienda</li>
          <li>
            Acreditar una <CLALink href="/new-home/saving-accounts">cuenta de ahorro</CLALink> para la vivienda con una
            antigüedad mínima de un año.
          </li>
          <li>
            Acreditar que el ahorro exigido esté depositado en la cuenta para la vivienda, antes del último día del mes
            anterior a la postulación. A partir de esa fecha no deberás efectuar giros en la cuenta.
          </li>
          <li>
            Pertenecer al{' '}
            <CLALink target="_blank" rel="noreferrer" href="https://registrosocial.gob.cl/inicio">
              Registro Social de Hogares (RSH)
            </CLALink>{' '}
            y no superar el tramo de calificación socioeconómica según el tramo del subsidio al que desea postular.
          </li>
          <li>
            Para el tramo II y III también deberás contar con una preaprobación de{' '}
            <CLALink href="/new-home/mortgages">crédito hipotecario</CLALink>, emitida por una entidad crediticia
            (bancos, cooperativas, etc.).
          </li>
          <li>En caso de postular colectivamente el grupo debe:</li>
          <ul>
            <li>Tener un mínimo de diez integrantes.</li>
            <li>Postular a través de una Entidad Patrocinante.</li>
            <li>Contar con un proyecto habitacional aprobado por el Servicio de Vivienda y Urbanización (Serviu).</li>
          </ul>
        </ul>
      </BodyTypography>
      <Typography my={2} textAlign="start" fontSize="24px" fontWeight={500}>
        Este apoyo del Estado se divide en tres tramos:
      </Typography>
      <Paper
        elevation={4}
        sx={{
          borderRadius: '10px',
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell aria-label="empty header" />
                <TableCell align="center">Tramo I</TableCell>
                <TableCell align="center">Tramo II</TableCell>
                <TableCell align="center">Tramo III</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="h3" fontSize="20px">
                    Requisito
                  </Typography>
                </TableCell>
                <TableCell align="left">
                  <Typography variant="body1" fontSize="20px">
                    Hogares hasta el 60% del RSH y hasta el 90% para adultos mayores
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    Hogares hasta el 80% del RSH y hasta el 90% para adultos mayores
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    Hogares hasta el 90% del RSH
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h3" fontSize="20px">
                    Ahorro mínimo
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    30 UF
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    40 UF
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    80 UF
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="h3" fontSize="20px">
                    Monto del subsidio
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    De 570 a 750 UF para viviendas de hasta 1.100 UF o 1.200 UF, dependiendo de la zona
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    De 250 a 700 UF para viviendas de hasta 1.600 UF o 1.800 UF, dependiendo de la zona
                  </Typography>
                </TableCell>
                <TableCell align="center">
                  <Typography variant="body1" fontSize="20px">
                    De 250 a 550 UF para viviendas de hasta 2.200 UF o 2.600 UF, dependiendo de la zona
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <BodyTypography>
        Si te interesa este subsidio te recomendamos revisar todos los detalles en la{' '}
        <CLALink
          href="https://www.reddeproteccion.cl/fichas/subsidio_clase_media_para_compra_de_viviendas"
          target="_blank"
          rel="noreferrer"
        >
          página oficial (presiona acá para ir).
        </CLALink>
      </BodyTypography>
    </Dropdown>
  </Box>
);

const DS49: FC = () => {
  return (
    <Box mt={4}>
      <Dropdown title="Fondo Solidario de Elección de Vivienda (DS49)" titleSize="24px">
        <BodyTypography>
          El Programa Habitacional Fondo Solidario de Elección de Vivienda promueve el acceso de las familias en
          situación de vulnerabilidad a una solución habitacional, otorgando un subsidio estatal, sin la necesidad de
          que accedas a deuda hipotecaria.
          <Box mt={2}>
            El subsidio otorgado va destinado a financiar una vivienda. Esto puedes hacerlo adquiriendo una vivienda
            construida, o bien por medio de su construcción de una. Según cuál sea el caso, existen consideraciones
            específicas.
          </Box>
          <Box mt={2}>
            Si buscas información para la compra de una vivienda, aquí encontrarás lo necesario. Si buscas información
            para la construcción de una vivienda, revisa la ficha del{' '}
            <CLALink
              href="https://www.reddeproteccion.cl/fichas/subsidio_clase_media_para_construccion_de_viviendas"
              target="_blank"
              rel="noreferrer"
            >
              Fondo Solidario de Elección de Vivienda para la Construcción.
            </CLALink>
          </Box>
          <Box mt={2}>
            La modalidad del subsidio para la adquisición de una vivienda te permite comprar de manera individual con tu
            grupo familiar, o bien, integrarte a una de las iniciativas de la nómina de proyectos habitacionales del
            SERVIU de la región.
          </Box>
        </BodyTypography>
        <Typography mt={2} textAlign="start" fontSize="24px" fontWeight={500}>
          Requisitos
        </Typography>
        <BodyTypography>
          Los requisitos para postular a este subsidio son los siguientes:
          <ul>
            <li>
              Tener 18 años cumplidos y contar con cédula nacional de identidad vigente.
              <ul>
                <li>
                  Las personas extranjeras deben presentar, además, cédula de identidad para extranjeros/as con
                  permanencia definitiva y certificado de Permanencia Definitiva (emitido por el Departamento de
                  Extranjería del Ministerio del Interior o por Policía de Investigaciones de Chile).
                </li>
              </ul>
            </li>
            <li>
              Debes estar inscrito en el{' '}
              <CLALink target="_blank" rel="noreferrer" href="https://registrosocial.gob.cl/inicio">
                Registro Social de Hogares (RSH)
              </CLALink>{' '}
              y formar parte del <b>40%</b> más vulnerable del registro.
            </li>
            <li>
              Debes acreditar un grupo familiar (no puedes postular con un hogar unipersonal). Tu RSH debe estar
              compuesto por más de una persona.
            </li>
            <li>
              De manera excepcional, podrás postular con un hogar unipersonal si cumples con alguna de las siguientes
              condiciones:
              <ul>
                <li>Eres Adulto Mayor.</li>
                <li>Eres viudo(a).</li>
                <li>Tienes un grado de discapacidad (acreditada ante el COMPIN).</li>
                <li>Tienes calidad de indígena.</li>
                <li>Eres reconocido en el Informe Valech.</li>
              </ul>
            </li>
            <li>
              Debes tener una <CLALink href="/new-home/saving-accounts">cuenta de ahorro</CLALink> para la vivienda con
              un ahorro mínimo de 10 UF, a más tardar el último día hábil del mes anterior a la postulación.
            </li>
          </ul>
        </BodyTypography>
        <Typography mt={2} textAlign="start" fontSize="24px" fontWeight={500}>
          Monto
        </Typography>
        <BodyTypography>
          Debes considerar que los beneficiarios de este programa NO podrán optar a un crédito para complementar el
          financiamiento del precio de la vivienda que será adquirida o construida. El precio de la vivienda no podrá
          superar las <b>950 UF</b>. De manera excepcional, para algunas zonas el valor máximo será de 1.050 UF.
          <Box mt={2}>
            Si te interesa este subsidio te recomendamos revisar todos los detalles en la{' '}
            <CLALink
              href="https://www.reddeproteccion.cl/fichas/fondo_solidario_de_eleccion_de_vivienda_para_compra_ds49"
              target="_blank"
              rel="noreferrer"
            >
              página oficial (presiona acá para ir).
            </CLALink>
          </Box>
        </BodyTypography>
      </Dropdown>
    </Box>
  );
};

const WithSubsidy: FC = () => {
  return (
    <NewHomePaper>
      <NewHomeBreadCrumbs title="Comprar tu casa con subsidio" isHome={false} />
      <Typography my={2} fontSize="36px" lineHeight="44px" fontWeight="500">
        Comprar tu casa con subsidio
      </Typography>
      <BodyTypography>
        Hay 2 principales subsidios para la compra de una casa, el <b>Subsidio Clase Media (DS1)</b> y el{' '}
        <b>Fondo Solidario de Elección de Vivienda (DS49)</b>.
      </BodyTypography>
      <DS1 />
      <Divider />
      <DS49 />
      <Divider />
      <Typography mt={4} fontSize="36px" lineHeight="44px" fontWeight={500}>
        Calendario de postulaciones
      </Typography>
      <BodyTypography>
        <CLALink href="https://www.minvu.gob.cl/postulaciones/" target="_blank" rel="noreferrer">
          Acá puedes revisar el calendario de postulaciones para estos subsidios.
        </CLALink>
        <Box mt={4}>
          Puedes utilizar tu subsidio para comprar una vivienda del programa de integración social y territorial:
        </Box>
      </BodyTypography>
      <Dropdown title="Programa de Integración Social y Territorial (DS19)" titleSize="30px">
        <DS19Definition />
        <DS19WithSubsidy />
      </Dropdown>
      <Typography mt={4} fontSize="36px" lineHeight="44px" fontWeight={500}>
        Ayuda
      </Typography>
      <BodyTypography>
        Si te quedó alguna duda, agenda una asesoría con nosotros y te ayudaremos a resolverla:
      </BodyTypography>
      <Box mt={2}>
        <CLALink href="/book-meeting" target="_blank" rel="noreferrer">
          <PrimaryBtn>Agendar asesoría</PrimaryBtn>
        </CLALink>
      </Box>
    </NewHomePaper>
  );
};

export default WithSubsidy;
