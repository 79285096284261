import React, { FC } from 'react';

import { Box } from '@mui/material';

import LogoBancame from 'assets/images/logos/logo_bancame.svg';
import SignMandate from 'components/common/SignMandate';

const SignMandateAnyUser: FC = () => {
  return (
    <SignMandate
      header={
        <Box flexDirection="row" alignItems="flex-end" display="flex" mt={1} mb={2}>
          <img alt="Bancame" src={LogoBancame} height="85px" />
          <img alt="Relif" src="/logo_white.webp" height="100px" />
        </Box>
      }
      description="Necesitamos algunos datos para intentar conseguirte descuentos en tus deudas morosas. Nos estás dando permiso para ir a negociar las deudas con tus acreedores (tú tomarás la decisión final)."
    />
  );
};

export default SignMandateAnyUser;
